import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators, AbstractControl } from '@angular/forms';
import { GlobalConstants } from 'src/app/common/global-constants';

@Component({
    selector: 'int-input',
    templateUrl: './int-input.component.html',
    styleUrls: ['./int-input.component.scss']
})
export class IntInputComponent implements OnInit, OnChanges {
    _isInValid: boolean = false;

    @Output() changeEvent = new EventEmitter<any>();

    @Input() faIcon: string;
    @Input() faPack: string = 'fas';
    @Input() invalidMessage: string;
    @Input() name: string;
    @Input() required: boolean;
    @Input() extraSpacing: boolean = false;
    @Input() placeholder: string;
    @Input() requiredMessage: string;
    @Input() label: string;
    @Input() value: any = '';
    @Input() minLength: any;
    @Input() maxLength: any;
    @Input() isInValid: any;
	@Input() pattern: any;
    @Input() isCreditCard: boolean;
    @Input() isRoutingNumber: boolean;
    @Input() tabindex: number = -1;

    maxLengthMessage = '';
    minLengthMessage = '';

    form: UntypedFormGroup = new UntypedFormGroup({
        formInput: new UntypedFormControl(''),
    });

    constructor() { }
        
    ngOnChanges(changes: SimpleChanges) {
        if ( changes.value ) {
            const formInput = this.form.controls['formInput'];
            formInput.setValue(changes.value.currentValue);
            formInput.updateValueAndValidity();
        }

        if ( changes.minLength ) {
            this.minLength = changes.minLength.currentValue;
        }

        if ( changes.maxLength ) {
            this.maxLength = changes.maxLength.currentValue;
        }
    }

    isRequired(): boolean {
        const formInput = this.form.get('formInput');
        const validator = formInput.validator({} as AbstractControl);
        return (validator && validator.required);
    }

    inputErrorMessage() {
        const formInput = this.form.get('formInput');
        
        if ( this.isRequired() && formInput.value === '' && (formInput.dirty || formInput.touched)) {
            return this.requiredMessageOutput();
        }

        return (!formInput.valid || this.isInValid) ? this.invalidMessageOutput() : '';
    }

    invalidMessageOutput() {
        const label = this.label.replace('*', '');
        const text = `Not a valid ${label}`;
        const message = this.invalidMessage || text;

        return message;
    }

    isDirtyandInvalid() {
        const formInput = this.form.get('formInput');
        
        return (
            (
                formInput.invalid && 
                ( formInput.dirty || formInput.touched ) || 
                this.notMaxLength() || 
                this.notMinLength() 
            ) ||
            this.isInValid
        );
    }

    getMaxLength() {
        return this.maxLength || 999999999;
    }

    notMinLength(): boolean {
        const formInput = this.form.controls['formInput'];
        const length = (formInput.value) ? formInput.value.toString().length : 0;

        if ( !this.isRequired() && (formInput.value === '' || formInput.value === null) ) {
            return false;
        }

        return ( !formInput.pristine && length < this.minLength);
    }

    notMaxLength(): boolean {
        const formInput = this.form.controls['formInput'];
        const length = (formInput.value) ? formInput.value.toString().length : 0;
        
        return ( !formInput.pristine && length > this.maxLength);
    }

    onChange() {
        const formInput = this.form.get('formInput');
        formInput['name'] = this.name;
        this.changeEvent.emit(formInput);
    }

    requiredMessageOutput() {
        const label = this.label.replace('*', '');
        const text = `${label} is a required field`;
        const message = this.requiredMessage || text;

        return message;
    }

    ngOnInit(): void {
        this.form.controls['formInput'].setValue(this.value);
        const validators = [];
        
        if (this.isCreditCard) {
            validators.push(Validators.pattern(GlobalConstants.regexCCNumber));
        } else if (this.isRoutingNumber) {
            validators.push(Validators.pattern(GlobalConstants.regexRoutingNumber));
        } else if (this.pattern) {
			validators.push(Validators.pattern(this.pattern));
		}
		else {
			validators.push(Validators.pattern(/^\d+$/));
		}
		
        if (this.required) {
            validators.push(Validators.required);
        }
        
        if (this.minLength) {
            validators.push(Validators.minLength(this.minLength));
            this.minLengthMessage = 'Must be at least ' + this.minLength + ' digits long';
        }

        if (this.maxLength) {
            validators.push(Validators.maxLength(this.maxLength));
            this.maxLengthMessage = 'Can not be greater than ' + this.minLength + ' digits long';
        }
        
        this.form.controls['formInput'].setValidators(validators);
    }
}
