<app-navbar title="{{'privacy-policy.title' | translate}}"></app-navbar>
<div class="container">
    <div class="content-presentation">
        <section>
            <h3 class="thin">{{'privacy-policy.summary' | translate}}</h3>
            <p>{{'privacy-policy.summary-text' | translate}}</p>

            <h3 class="thin">{{'privacy-policy.information-we-collect' | translate}}</h3>
            <p>{{'privacy-policy.information-we-collect-text-1' | translate}}</p>
            <p>{{'privacy-policy.information-we-collect-text-2' | translate}}</p>
            <p>{{'privacy-policy.information-we-collect-text-3' | translate}}</p>
            <ul>
                <li>{{'privacy-policy.information-we-collect-list-1' | translate}}</li>
                <li>{{'global.postal-code' | translate}}</li>
                <li>{{'privacy-policy.information-we-collect-list-3' | translate}}</li>
                <li>{{'privacy-policy.information-we-collect-list-4' | translate}}</li>
                <li>{{'privacy-policy.information-we-collect-list-5' | translate}}</li>
                <li>{{'privacy-policy.information-we-collect-list-6' | translate}}</li>
                <li>{{'privacy-policy.information-we-collect-list-7' | translate}}</li>
            </ul>

            <h3 class="thin">{{'privacy-policy.sharing-of-personal-info' | translate}}</h3>
            <p>{{'privacy-policy.sharing-of-personal-info-text' | translate}}</p>
            
            <h3 class="thin">{{'privacy-policy.how-we-protect-personal-info' | translate}}</h3>
            <p>{{'privacy-policy.how-we-protect-personal-info-text' | translate}}</p>

            <h3 class="thin">{{'privacy-policy.how-long-we-keep-your-info' | translate}}</h3>
            <p>{{'privacy-policy.how-long-we-keep-your-info-text' | translate}}</p>

            <h3 class="thin">{{'privacy-policy.right-to-be-forgotten' | translate}}</h3>
            <p>{{'privacy-policy.right-to-be-forgotten-text-1' | translate}}</p>
            <p>{{'privacy-policy.right-to-be-forgotten-text-2' | translate}}</p>
            <p>{{'privacy-policy.right-to-be-forgotten-text-3' | translate}}</p>
            <p>
                {{'privacy-policy.address-1' | translate}}<br/>
                {{'privacy-policy.address-2' | translate}}<br/>
                {{'privacy-policy.address-3' | translate}}<br/>
                {{'privacy-policy.address-4' | translate}}<br/>
            </p>
            <p>{{'privacy-policy.right-to-be-forgotten-text-4' | translate}}</p>

            <h3 class="thin">{{'privacy-policy.changes-to-our-privacy-notice' | translate}}</h3>
            <p>{{'privacy-policy.changes-to-our-privacy-notice-text' | translate}}</p>
            
            <h3 class="thin">{{'global.contact-us' | translate}}</h3>
            <p>{{'privacy-policy.contact-us-text' | translate}}</p>
            <p>
                {{'privacy-policy.contact-us-in-writing' | translate}}:<br/>
                {{'privacy-policy.address-1' | translate}}<br/>
                {{'privacy-policy.address-2' | translate}}<br/>
                {{'privacy-policy.address-3' | translate}}<br/>
                {{'privacy-policy.address-4' | translate}}<br/>
            </p>
            <p>
                {{'global.email' | translate}}: <a href="mailto:privacy@uspayments.com">{{'privacy-policy.email' | translate}}</a>
            </p>
        </section>
    </div>
</div>