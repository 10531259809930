import { AfterViewInit, Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators, UntypedFormBuilder } from '@angular/forms';

import { UserService } from '../../../services/user/user-service.service';
import { MessageReporter } from '../../../services/messages/message-reporter.service';

import { UserInterface } from '../../../interfaces/user.interface';

@Component({
    selector: 'app-pin',
    templateUrl: './pin.component.html',
    styleUrls: ['./pin.component.scss']
})
export class PINComponent implements OnInit, AfterViewInit {
    user: UserInterface;
    form: UntypedFormGroup;

    constructor(
        private fb: UntypedFormBuilder,
        private reporter: MessageReporter,
        private userService: UserService,
    ) { }

    async getPINInfo(): Promise<any> {
        try {
            this.user = await this.userService.getPersonalInfo();
            this.form.controls['fastPassPIN'].setValue(this.user.pin);
        } catch (error) {
            console.log(error);
            this.form.get('fastPassPIN').setValue('');
        }
    }

    async savePINInfo(): Promise<any> {
        try {
            const values = JSON.parse(JSON.stringify(this.form.value));
            const pin = this.form.get('fastPassPIN');
            const email = sessionStorage.getItem('email');
            const user = JSON.parse(JSON.stringify(this.user));
            
            if ( (pin.dirty || pin.touched) && values.fastPassPIN !== null && values.fastPassPIN !== '' ) {
                user.fastPassPIN = values.fastPassPIN;
            } else {
                user.fastPassPIN = '0';
            }

            user.email = email;
            user.aspNetUserId = this.userService.userId();

            // Deletes from the User object before sending
            delete user.listOfBillerAccountInfo;
            delete user.languageId;
            delete user.customerId;

            // Send the User object
            const response = await this.userService.savePersonalInfo(user);

            if ( response ) {
                this.reporter.showSuccessMessage('PIN was successfully saved');
                this.getPINInfo();
            }

        } catch (error) {
            console.log(error);
        }
    }

    getFormValue(field: string) {
        return this.form.get(field).value;
    }

    updateFormField($event: any) {
        this.form.controls[$event.name] = $event;
        if (!/^\d{4}$/.test(this.getFormValue('fastPassPIN')))
            this.form.controls['fastPassPIN'].setErrors({'incorrect': true});
        this.form.updateValueAndValidity();
    }

    ngAfterViewInit(): void {
        this.getPINInfo();
    }

    ngOnInit(): void {
        this.form = this.fb.group({
            fastPassPIN: new UntypedFormControl('', [Validators.minLength(4), Validators.maxLength(4)]),
        });
    }

    checkKey($event: any) {
        return /\d/.test($event.key);
    }
}