export const PaymentTypes: any[] = [
    {
        'id': 1,
        'name': 'Cash',
    },
    {
        'id': 2,
        'name': 'Check',
    },
    {
        'id': 3,
        'name': 'ACH-Checking',
    },
    {
        'id': 4,
        'name': 'ACH-Savings'
    },
    {
        'id': 5,
        'name': 'Credit',
    },
    {
        'id': 6,
        'name': 'Debit',
    },
    {
        'id': 12,
        'name': 'Apple Pay'
    }
];