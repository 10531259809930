<h2 class="thin">{{'change-pin.change-pin' | translate}}</h2>
<form>
    <div class="row">
        <div class="col-12">
            <div class="form-group">
                <password-input
                    name="fastPassPIN"
                    minLength="4"
                    maxLength="4"
                    required="true"
                    invalidMessage="You have entered an invalid PIN"
                    [label]="'global.pin' | translate"
                    [value]="getFormValue('fastPassPIN')"
                    (changeEvent)="updateFormField($event)"
                    ngbAutofocus
                    (keypress)="checkKey($event)"
                    pattern="^\d{4}$"
                ></password-input>
            </div>
        </div>
        <div class="d-sm-block d-md-none col-12 requiredLabel">
            <div class="notice">* {{'global.required' | translate}}</div>
        </div>
    </div>
    <div class="button-row">
        <div class="row">
            <div class="d-none d-md-block col-6 align-left align-self-center">
                <div class="notice">* {{'global.required' | translate}}</div>
            </div>
            <div class="d-none d-md-block col-6 align-right">
                <button class="btn btn-primary" [disabled]="form.invalid" (click)="savePINInfo()">{{'change-pin.save-pin-change' | translate}}</button>
            </div>
            <div class="d-sm-block d-md-none col-12 align-enter">
                <button class="btn btn-primary btn-block" [disabled]="form.invalid" (click)="savePINInfo()">{{'change-pin.save-pin-change' | translate}}</button>
            </div>
        </div>
    </div>
</form>