<div class="modal-header">
    <h4 class="modal-title">{{'upgrade-modal.old-account-found' | translate}}</h4>
</div>
<div class="modal-body">
    <div class="form-group">
        <p class="alignTextCenter">{{'upgrade-modal.old-platform' | translate}}
        </p>
    </div>
</div>
<div class="modal-footer">
    <div class="button-row row w-100">
        <div class="col-12 align-right pe-0">
            <button 
                type="button" 
                class="btn btn-light" 
                (click)="activeModal.close()"
            >{{'global.no-thanks' | translate}}</button>
            <button 
                type="submit"
                ngbAutofocus
                class="btn btn-primary"
                (click)="onSubmit()"
            >{{'global.upgrade' | translate}}</button>
        </div>
    </div>
</div>