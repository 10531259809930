import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { filter } from 'rxjs/operators';

import { AppService } from './services/app/app.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit {
    url: any;
    themeColor: string = '#1465C6';
    title = 'usp-boostrap';
    closeResult = '';
    loadSite: boolean = false;
    isMobile: boolean;

    constructor(
        private app: AppService,
        private router: Router,
        private route: ActivatedRoute,
        private translate: TranslateService
    ) {
        translate.setDefaultLang('en');
        
        router.events.pipe(
                filter(event => event instanceof NavigationEnd)
            )
            .subscribe((event: any) => {
                const url = event.url;
                const splitArr = url.split('?');
                
                this.url = splitArr[0];
            });
    }

    appendThemeColor() {
        const metaThemeColor = document.querySelector("meta[name=theme-color]");
        metaThemeColor.setAttribute("content", this.themeColor);
    }

    ngAfterViewInit() {
        window.setTimeout(() => {
            const url = this.router.parseUrl(this.router.url);
            const { queryParams }: any = url;
            
            this.isMobile = this.route.snapshot.queryParamMap.get('mobile') != null;

            this.appendThemeColor();
            this.loadSite = true;
        });
    }

    ngOnInit() { }
}
