<span class="textLabel">{{label}}</span><span *ngIf="required" class="textLabel"> *</span>
<div class="input-group" [formGroup]="form">
    <div class="input-group-prepend">
        <span class="input-group-text" id="basic-addon1"><fa-icon [icon]="faAt"></fa-icon></span>
    </div>
    <input 
        #inputRef
        type="text" 
        formControlName="formInput"
        value={{value}}
        [email]="true"
        [className]="isDirtyandInvalid() ? 'form-control is-invalid' : 'form-control'"
        (ngModelChange)="onChange()"
        tabindex="{{tabindex}}"
    />
    <div class="invalid-feedback" *ngIf="isDirtyandInvalid()">
        {{inputErrorMessage()}}
    </div>
</div>