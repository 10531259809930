<app-navbar title="{{'upgrade-legacy-account.upgrade-account' | translate}}"></app-navbar>

<div class="container">
    <paper header="{{headerText}}">
        <form [formGroup]="form" class="p-3">
            <ngb-alert 
                type="danger" 
                *ngIf="loginError" 
                (closed)="closeAltert()"
            >{{loginErrorText}}
            </ngb-alert>
            <ngb-alert 
                type="danger" 
                *ngIf="mismatchError" 
                (closed)="closeAltert()"
            >There was a problem matching data entered to what is stored for your account on the legacy system. Please ensure all info is correct and try again to transfer your existing biller accounts. If you are unable to match the data, you may 
                <a routerLink="/signup" routerLinkActive="active">create a new account</a>.
            </ngb-alert>
            <div *ngIf="isSearch">
                <div class="form-group">
                    <email-input 
                        name="email" 
                        required=true
                        [label]="'Email *'"
                        [value]="getFormValue('email')"
                        (changeEvent)="updateFormField($event)"
                    ></email-input>
                </div>
                <div class="form-group">
                    <phone-input 
                        name="phoneNumber" 
                        required=true
                        [label]="'Phone Number *'"
                        [value]="getFormValue('phoneNumber')"
                        (changeEvent)="updateFormField($event)"
                        [autoFocus]="true"
                    ></phone-input>
                </div>
                <div class="form-group">
                    <text-input 
                        name="lastName" 
                        faIcon="user"
                        required=true
                        [label]="'Last Name *'"
                        [validator]="nameValidator"
                        [value]="getFormValue('lastName')"
                        (changeEvent)="updateFormField($event)"
                    ></text-input>
                </div>
                <div class="form-group">
                    <int-input 
                        faIcon="map-marker-alt"
                        name="postalCode"
                        required="true"
                        pattern="^\d{5}$"
                        [label]="'Postal Code *'"
                        [maxLength]="5"
                        [value]="getFormValue('postalCode')"
                        (changeEvent)="updateFormField($event)"
                    ></int-input>
                </div>
                <div class="form-group">
                    <password-input 
                        name="last4ssn" 
                        required=true
                        [label]="'Last 4 of SSN *'"
                        [value]="getFormValue('last4ssn')"
                        (changeEvent)="updateFormField($event)"
                    ></password-input>
                </div>
            </div>
            <div *ngIf="isEnterNewCredentials">
                <p class="padRight">{{'upgrade-legacy-account.verified-old-account' | translate}}</p>
                <p class="padRight footnote">{{'upgrade-legacy-account.note' | translate}}</p>
                <div class="form-group">
                    <password-input 
                        name="newPassword" 
                        required=true
                        invalidMessage="{{invalidPasswordError}}"
                        [label]="'Password *'"
                        [validator]="validator"
                        [value]="getFormValue('newPassword')"
                        (changeEvent)="updateFormField($event)"
                    ></password-input>
                </div>
                <div class="form-group">
                    <password-input 
                        name="confirmPassword" 
                        required=true
                        [label]="'Confirm Password *'"
                        [value]="getFormValue('confirmPassword')"
                        [validator]="confirmPasswordValidator"
                        (changeEvent)="updateFormField($event)"
                    ></password-input>
                </div>
                <div class="form-group">
                    <password-input 
                        name="newPin" 
                        faIcon="lock"
                        required=true
                        [label]="'PIN *'"
                        [value]="getFormValue('newPin')"
                        (changeEvent)="updateFormField($event)"
                    ></password-input>
                </div>
                <div class="form-group">
                    <password-input 
                        name="confirmPin"
                        faIcon="lock"
                        required=true
                        [label]="'Confirm PIN *'"
                        [value]="getFormValue('confirmPin')"
                        [validator]="confirmPinValidator"
                        (changeEvent)="updateFormField($event)"
                    ></password-input>
                </div>
            </div>
            <div *ngIf="isValidateBillerAccounts">
                <p class="padRight">{{'upgrade-legacy-account.billers-associated' | translate}}</p>
                <div *ngFor="let billerAccount of billerAccounts">
                    <div class="paper-tile">
                        <div class="delete-icon" (click)="deleteBillerAccount(billerAccount)">
                            <fa-icon [icon]="['fas', 'trash']"></fa-icon>
                        </div>
                        <div class="row col-12">
                            <div class="logo-image col-6">
                                <img src="{{billerImageUrl}}?billerId={{billerAccount.billerId}}" />
                            </div>
                            <div class="col-6">
                                <p class="title">{{ billerAccount.legacyBillerName }}</p>
                                <p class="balance-details">{{'upgrade-legacy-account.account' | translate}} #: {{ billerAccount.accountNumber }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="isCompleted">
                <p class="padRight">{{'upgrade-legacy-account.new-paysite-account' | translate}}</p>
            </div>
            <div class="d-lg-none d-md-block">
                <p *ngIf="isSearch || isEnterNewCredentials">* {{'global.required' | translate}}</p>
            </div>
            <div class="d-none d-lg-block">
                <div class="button-row">
                    <div class="row">
                        <div class="col-4 col-md-6 align-left">
                            <div class="notice" *ngIf="isSearch || isEnterNewCredentials">* {{'global.required' | translate}}</div>
                        </div>
                        <div class="col-8 col-md-6 align-right">
                            <button *ngIf="isSearch || isEnterNewCredentials"
                                    class="btn btn-light"
                                    (click)="goHome()">{{'global.cancel' | translate}}</button>
                            <button class="btn btn-primary" (click)="next()">{{submitButtonText}}</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="d-md-block d-lg-none">
                <div class="button-row">
                    <div class="row">
                        <div class="col-6 align-left">
                            <button *ngIf="isSearch || isEnterNewCredentials"
                                    class="btn btn-light"
                                    (click)="goHome()">{{'global.cancel' | translate}}</button>
                        </div>
                        <div class="col-6 align-right">
                            <button class="btn btn-primary" (click)="next()">{{submitButtonText}}</button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </paper>
</div>
