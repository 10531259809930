import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators, AbstractControl } from '@angular/forms';
import { faDollarSign } from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'currency-input',
    templateUrl: './currency-input.component.html',
    styleUrls: ['./currency-input.component.scss']
})
export class CurrencyInputComponent implements OnInit, OnChanges {
    @Output() changeEvent = new EventEmitter<any>();

    faDollarSign = faDollarSign;
    windowTimeout: any;

    @Input() invalidMessage: string;
    @Input() name: string;
    @Input() required: boolean;
    @Input() placeholder: string;
    @Input() requiredMessage: string;
    @Input() label: string;
    @Input() value: any = '';
    @Input() tabindex: string;
    
    form: UntypedFormGroup = new UntypedFormGroup({
        formInput: new UntypedFormControl('')
    });

    constructor() { }
        
    ngOnChanges(changes: SimpleChanges) {
        if ( changes.value ) {
            const formInput = this.form.controls['formInput'];
            formInput.setValue(changes.value.currentValue);
            formInput.updateValueAndValidity();
        }
    }

    isRequired(): boolean {
        const formInput = this.form.get('formInput');
        const validator = formInput.validator({} as AbstractControl);
        return (validator && validator.required);
    }

    inputErrorMessage(): string {
        const formInput = this.form.get('formInput');
        if (this.isRequired() && formInput.value === '' && (formInput.dirty || formInput.touched)) {
            return this.requiredMessageOutput();
        }

        return !formInput.valid ? this.invalidMessageOutput() : '';
    }

    invalidMessageOutput(): string {
        const label = this.label.replace('*', '');
        const text = `Not a valid ${label}`;
        const message = this.invalidMessage || text;

        return message;
    }

    isDirtyandInvalid(): boolean {
        const formInput = this.form.get('formInput');
        return ( formInput.invalid && ( formInput.dirty || formInput.touched ) );
    }

    onChange(): void {
        const formInput = this.form.get('formInput');
        
        //console.log('onChange:');
        //console.log(formInput.value);

        formInput['name'] = this.name;
        this.changeEvent.emit(formInput);
    }

    requiredMessageOutput(): string {
        const label = this.label.replace('*', '');
        const text = `${label} is a required field`;
        const message = this.requiredMessage || text;

        return message;
    }

    ngOnInit(): void {
        this.form.controls['formInput'].setValue(this.value);
        const validators = [
            Validators.pattern(/^\$?([0-9]{1,3},([0-9]{3},)*[0-9]{3}|[0-9]+)(\.[0-9][0-9])?/)
        ];

        if (this.required) {
            validators.push(Validators.required);
        }

        this.form.controls['formInput'].setValidators(validators);}

}
