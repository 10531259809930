import { HostListener, Injectable } from '@angular/core';
import { GlobalConstants } from 'src/app/common/global-constants';

@Injectable({
    providedIn: 'root'
})
export class AppService {
    innerWidth: any;
    windowSize: string = 'large';

    @HostListener('window:resize', ['$event'])
        onResize(event: any) {
            this.innerWidth = window.innerWidth;
            this.setWindowSize();
        }

    constructor() { }

    setWindowSize(): void {
        this.innerWidth = window.innerWidth;
        if ( this.innerWidth > 1400) {
            this.windowSize = 'xxl';
        } else if (this.innerWidth <= 1400 && this.innerWidth > 1200) {
            this.windowSize = 'xl';
        } else if (this.innerWidth <= 1200 && this.innerWidth > 992) {
            this.windowSize = 'large';
        } else if (this.innerWidth <= 992 && this.innerWidth > 768) {
            this.windowSize = 'medium';
        } else if (this.innerWidth <= 768 && this.innerWidth > 576) {
            this.windowSize = 'small';
        } else if (this.innerWidth <= 576) {
            this.windowSize = 'xs';
        }
    }

    public getWindowSize(): string {
        return this.windowSize;
    }

    isPayTypeCheckingSavings(payTypeId: any) : boolean {
        return Number(payTypeId) === GlobalConstants.payType.Checking || Number(payTypeId) === GlobalConstants.payType.Savings;
    }

    isPayTypeCreditDebit(payTypeId: any) : boolean {
        return Number(payTypeId) === GlobalConstants.payType.Credit || Number(payTypeId) === GlobalConstants.payType.Debit;
    }

    isPayTypeCredit(payTypeId: any) : boolean {
        return Number(payTypeId) === GlobalConstants.payType.Credit;
    }

    isPayTypeDebit(payTypeId: any) : boolean {
        return Number(payTypeId) === GlobalConstants.payType.Debit;
    }

    isPayTypeWallet(payTypeId: any) : boolean {
        return Number(payTypeId) === GlobalConstants.payType.ApplePay; //|| Number(payTypeId) === GlobalConstants.payType.GooglePay
    }

    isPayTypeApplePay(payTypeId: any) : boolean {
        return Number(payTypeId) === GlobalConstants.payType.ApplePay;
    }

    //isPayTypeGooglePay(payTypeId: any) : boolean {
    //    return Number(payTypeId) === GlobalConstants.payType.GooglePay;
    //}
}