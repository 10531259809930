import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { UserService } from '../../services/user/user-service.service';
import { BillerAccountsInterface } from '../../interfaces/Biller.interface';
import { MessageReporter } from '../../services/messages/message-reporter.service';

@Component({
    selector: 'app-fast-pass-link',
    templateUrl: './fast-pass-link.component.html',
    styleUrls: ['./fast-pass-link.component.scss']
})
export class FastPassLinkComponent implements OnInit {

    user: BillerAccountsInterface;
    @Input() set billerAccounts(value: BillerAccountsInterface) {
        this.user = value;

        this.buildUserForm();
    };
    @Input() parent: any;

    isLinking: boolean = false;
    linkError: boolean = false;
    linkErrorText: string = '';
    linkButtonText: string = 'Link Fast Pass';
    form: UntypedFormGroup = new UntypedFormGroup({
        aspNetUserId: new UntypedFormControl(''),
        fastPassPin: new UntypedFormControl('', Validators.required),
        languageId: new UntypedFormControl(1, Validators.required),
        lastName: new UntypedFormControl('', Validators.required),
        phoneNumber: new UntypedFormControl('', Validators.required),
        zip: new UntypedFormControl('', Validators.required),
        preExistingAccount: new UntypedFormControl(false),
    });

    constructor(
        public activeModal: NgbActiveModal,
        private reporter: MessageReporter,
        private userService: UserService,
    ) { }


    updateFormField($event: any): void {
        this.form.controls[$event.name] = $event;
        this.form.updateValueAndValidity();
    }

    closeAltert(): void {
        this.linkError = false;
    }

    getFormValue(field: string): string|number {
        return this.form.get(field).value;
    }

    buildUserForm(): void {
        const userId = sessionStorage.getItem('userId');
        this.form.get('aspNetUserId').setValue(userId);
        this.form.get('lastName').setValue(this.user.lastName);
        this.form.get('phoneNumber').setValue(this.user.phoneNumber);
    }

    updateParent() {
        this.parent.accountLinked = true;
    }

    async updateProfile(): Promise<any> {
        try {
            const body = JSON.parse(JSON.stringify(this.form.value));
            const phoneNumber = this.form.get('phoneNumber').value;

            body.email = sessionStorage.getItem('email');
            body.lastName = this.form.get('lastName').value;
            body.phoneNumber = Number(phoneNumber.replace(/-/g,''));
            body.zip = this.form.get('zip').value;
            body.aspNetUserId = this.form.get('aspNetUserId').value;
            body.fastPassPin = Number(this.form.get('fastPassPin').value);

            delete body.languageId;
            delete body.preExistingAccount;

            console.log(body);
            
            const response = await this.userService.savePersonalInfo(body);

            if ( response ) {
                this.parent.accountLinked = true;
                this.isLinking = false;

                this.parent.showWalkThrough();
                this.activeModal.close();

            } else {
                this.reporter.showErrorMessage('There was a problem updating your profile');
            }

        } catch (error) {
            console.log(error);
        }
    }

    async onSubmit(): Promise<any> {
        try {
            this.isLinking = true;
            this.linkError = false;
            const response = await this.userService.linkFastPass(this.form.value);
            
            this.reporter.showSuccessMessage('Thank you. Your My Paysite account and Fast Pass have now been linked.');

            this.updateProfile();

        } catch (error) {
            if ( error.status === 400 ) {
                if ( error.error && error.error === 'The link was not successfull, the fast pass account may already be linked to a user' ) {
                    this.linkErrorText = error.error;
                    this.linkButtonText = 'Retry the Fast Pass Link';
                    this.linkError = true;

                    this.form.get('preExistingAccount').setValue(true);
                }
            }

            this.isLinking = false;
        }
    }

    ngOnInit(): void {     
    }

}
