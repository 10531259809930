<span class="textLabel">{{label}}</span><span *ngIf="required" class="textLabel"> *</span>
<div class="input-group" [formGroup]="form">
    <div class="input-group-prepend">
        <span class="input-group-text" id="basic-addon1"><fa-icon [icon]="faDollarSign"></fa-icon></span>
    </div>
    <input 
        type="text"
        formControlName="formInput"
        name={{name}}
        placeholder={{placeholder}} 
        mask="separator.2" 
        thousandSeparator=","
        [className]="isDirtyandInvalid() ? 'form-control is-invalid' : 'form-control'"
        (ngModelChange)="onChange()"
		[maxLength]="14"
        tabindex="{{tabindex}}"
    />
    <div class="invalid-feedback" *ngIf="isDirtyandInvalid()">
        {{inputErrorMessage()}}
    </div>