<form class="form-width">
    <div class="row">
        <div class="col">
            <input type="text" class="form-control" />
        </div>
        <div class="col">
            <select class="form-control" name="select">
                <option value=""></option>
                <option value="Option 1">{{'form-fields.option-one' | translate}}</option>
                <option value="Option 2">{{'form-fields.option-two' | translate}}</option>
            </select>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <email-input 
                name="email" 
                [label]="'Email'"
                (changeEvent)="updateFormField($event)"
            ></email-input>
        </div>
        <div class="col">
            <div class="form-group">
                <label for="inputPassword2" class="sr-only">{{'global.password' | translate}}</label>
                <input type="password" class="form-control" id="inputPassword2">
            </div>
        </div>
    </div>
</form>