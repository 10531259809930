import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { UserService } from '../../services/user/user-service.service';
import { MessageReporter } from '../../services/messages/message-reporter.service';

@Component({
    selector: 'app-forgot-password',
    templateUrl: './forgot-password.component.html',
    styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

    form = new UntypedFormGroup({});
    formSubmitting: boolean = false;

    constructor(
        public activeModal: NgbActiveModal,
        private fb: UntypedFormBuilder,
        private reporter: MessageReporter,
        private router: Router,
        private userService: UserService,
    ) { }

    getFormValue(field: string): string | number {
        return this.form.get(field).value;
    }

    updateFormField($event: any): void {
        this.form.controls[$event.name] = $event;
        this.form.updateValueAndValidity();
    }
    async onSubmit(): Promise<any> {
        const values = this.form.value;
        this.formSubmitting = true;

        try {
            const response = await this.userService.sendForgotPassword(values);

            if ( response || response === null ) {
                this.reporter.showInfoMessage('An email has been sent to ' + values.emailAddress + '. Follow the link in the email to reset your password.');
                this.activeModal.close();
            }

            this.formSubmitting = false;

        } catch (error) {
            this.formSubmitting = false;
            console.log(error);

        }
    }

    ngOnInit(): void {
        this.form = this.fb.group({
            emailAddress: new UntypedFormControl('', [Validators.required]),
        });
    }

}
