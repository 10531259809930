import { AfterViewInit, Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { BillersService } from '../../services/billers/billers.service';

@Component({
    selector: 'app-barcode',
    templateUrl: './barcode.component.html',
    styleUrls: ['./barcode.component.scss']
})
export class BarcodeComponent implements OnInit, AfterViewInit {
    customerId: any;
    barCodeId: any;

    constructor(
        public activeModal: NgbActiveModal,
        private billersService: BillersService,
    ) { }

    async getBillerAccounts(): Promise<any> {
        try {
            const response = await this.billersService.getBillerAccounts(false, false);
            this.customerId = response.customerId;

            const idLength = this.customerId.length;
            const zerosNeeded = 8 - idLength;

            let zeroes = '';

            for ( let i=0; i<zerosNeeded; i++) {
                zeroes += '0';
            }

            this.barCodeId = '%' + zeroes + '' + this.customerId + '%';

        } catch (error ) {
            console.log(error);
        }
    }

    ngAfterViewInit(): void {
        window.setTimeout(() => {
            window.scrollTo(0, 0);
        }, 0);
    }

    ngOnInit(): void {
        this.getBillerAccounts();
    }

}
