import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators, UntypedFormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MessageReporter } from '../../services/messages/message-reporter.service';
import { BillersService } from '../../services/billers/billers.service';
import { PaymentTypesService } from '../../services/payment-types/payment-types.service';
import { WheresRoutingNumberComponent } from '../wheres-routing-number/wheres-routing-number.component';
import { BillerInterface } from '../../interfaces/Biller.interface';
import { billerImageUrl, GetCardType, GetTransactionFees, CardLuhnCheck, ABACheckDigitCheck, applePayMerchantId } from '../../../config';
import { GlobalConstants } from '../../common/global-constants';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from 'src/app/services/app/app.service';
import { MerchantValidationEvent } from 'src/app/interfaces/pay-method.interface';

const makeMonthArray = () => {
    const months: object[] = [];
    for ( let i = 1; i <= 12; i++ ) {
        const m = (i < 10 ) ? '0' + i : i;
        months.push( { id: m.toString(), name: m.toString() });
    }

    return months;
}

const makeYearArray = () => {
    const d = new Date();
    const n = d.getFullYear();

    const years: object[] = [];

    for ( let i = 0; i < 11; i++ ) {
        const y = n + i;
        years.push( { id: y.toString(), name: y.toString() });
    }

    return years;
}

@Component({
    selector: 'app-one-time-payment',
    templateUrl: './one-time-payment.component.html',
    styleUrls: ['./one-time-payment.component.scss'],
})
export class OneTimePaymentComponent implements OnInit {
    keyupTimeout: any;

    // Transaction Details
    authCode: string|number;
    creditCardDeclined: boolean = false;
    creditCardDeclinedMessage: string = 'The credit card payment was declined. Please try again, or submit a different payment method.';
    paymentFailed: boolean = false;
    paymentFailedMessage: string = 'There was a problem with the payment. Please try again, or submit a different payment method.';
    paymentDate: string|number;
    paymentDateFormatted: string|number;
    paymentId: string|number;
    paymentMethod: string;
    paymentTime: string|number;
    referenceNumber: string|number;

    acceptedPaymentTypes: any[] = [];
    balanceTypes: any = {};
    billerBalance: string = '';
    billerDetails: BillerInterface;
    billerError: boolean = false;
    billerErrorText: string = '';
    billerValidated: boolean = false;
    billerFees: any;
    billerImageUrl: string = billerImageUrl;
    billers: BillerInterface[];
    expireMonths: object[] = makeMonthArray();
    expireYears: object[] = makeYearArray();
    gettingBalance: boolean = false;
    isInValidCC: boolean = false;
    inValidCCMessage: string = 'The Credit Card number you have entered is not valid';
    isInValidABA: boolean = false;
    inValidABAMessage: string = 'The Routing Number you have entered is not valid';
    isInValidPayment: boolean = false;
    inValidPaymentMessage: string = 'Payment must be greater than $0.00';
    paymentTypes: any[];
    paymentTypesAll: any[];
    paymentTypesSet: boolean = false;
    acceptCc: boolean = false;
    acceptDc: boolean = false;
    stepIndex = 1;
    subPayTypeId: 0;
    timeout: any;
    paymentProcessing: boolean = false;
    fullTransaction: any;
    paymentAttempted: boolean = false;
    billerImageHidden: boolean;
    badAmount: boolean = true;
    applePayMethod: PaymentMethodData;

    form: UntypedFormGroup = new UntypedFormGroup({
        accountNumber: new UntypedFormControl('', [ Validators.required ]),
        bankAccount: new UntypedFormControl(''),
        billerId: new UntypedFormControl('', [ Validators.required ]),
        customerZip: new UntypedFormControl(''),
        creditCardNumber: new UntypedFormControl(''),
        customerPhone: new UntypedFormControl('', [ Validators.required ]),
        cvv: new UntypedFormControl(''),
        emailAddress: new UntypedFormControl(''),
        expireMonth: new UntypedFormControl(''),
        expireYear: new UntypedFormControl(''),
        customerLastName: new UntypedFormControl('', [ Validators.required ]),
        payAmount: new UntypedFormControl('', [ Validators.required ]),
        payTypeId: new UntypedFormControl(''),
        routingNumber: new UntypedFormControl(''),
        walletData: new UntypedFormControl('')
    });
    model: any = {};

    constructor(
        private billersService: BillersService,
        private fb: UntypedFormBuilder,
        private modal: NgbModal,
        private reporter: MessageReporter,
        private router: Router,
        private paymentTypesService: PaymentTypesService,
        private appService: AppService,
        private translate: TranslateService
    ) { }

    getFormValue(field: string) {
        return this.form.get(field).value;
    }

    updateFormField($event: any): void {
        //console.log($event);
        this.form.controls[$event.name] = $event;
        this.form.updateValueAndValidity();
        
        switch($event.name) {
            case 'creditCardNumber':
                window.clearTimeout(this.timeout);

                this.timeout = window.setTimeout(() => {
                    this.validateTheCCNumber();
                }, 0);
                break;
            case 'routingNumber':
                window.clearTimeout(this.timeout);
			
                this.timeout = window.setTimeout(() => {
                    this.validateTheABANumber();
                }, 0);
                break;
            case 'payAmount':
                const billerFee = this.billerFees.filter((row: any) => {
                    const payTypeId = this.form.get('payTypeId').value;
                    return (Number(row.paymentTypeId) === Number(payTypeId));
                });
    
                this.fullTransaction = GetTransactionFees(billerFee[0], Number(this.form.get('payAmount').value));
    
                this.badAmount = Number(this.form.get('payAmount').value) > Number(this.fullTransaction.maxAmount);
                break;
        }
    }

    accountNumberBlur() {
        this.form.patchValue({
            bankAccount: '',
            routingNumber: '',
            creditCardNumber: '',
            expirationDate: '',
            expirationMonth: '',
            expirationYear: '',
            payTypeId: '',
            payAmount: ''
        });
        this.form.updateValueAndValidity();

        if (this.form.controls['billerId'].value) {
            this.getBillerBalance();   
        }
    }

    onBillerImageLoad() {
        this.billerImageHidden = false;
    }

    onBillerImageError() {
        this.billerImageHidden = true;
    }

    /* ****
    *
    * For adding and removing validation requirements based on the fields that will be displayed
    * CC fields should not be required if the user selected a Checking/Savings payment type
    * 
    * */
    selectPaymentType($event: any): void {
        this.form.controls[$event.name] = $event;
        this.form.updateValueAndValidity();
        
        const payTypeId = $event.value;
        const bankAccount = this.form.get('bankAccount');
        const routingNumber = this.form.get('routingNumber');
        const creditCardNumber = this.form.get('creditCardNumber');
        const expirationDate = this.form.get('expirationDate');
        const expireMonth = this.form.get('expireMonth');
        const expireYear = this.form.get('expireYear');
        const customerZip = this.form.get('customerZip');
        const cvv = this.form.get('cvv');
        const paymentMethod = this.paymentTypesAll.filter((row: any) => {
            return ((Number(row.id) === Number(payTypeId) ));
        });

        this.paymentMethod = paymentMethod[0].paymentTypeNameInLanguage;
        bankAccount.clearValidators();
        routingNumber.clearValidators();
        creditCardNumber.clearValidators();
        expireMonth.clearValidators();
        expireYear.clearValidators();
        customerZip.clearValidators();
        cvv.clearValidators();

        if (this.appService.isPayTypeCheckingSavings(payTypeId)) {
            bankAccount.setValidators([Validators.required])
        } else if (this.appService.isPayTypeCreditDebit(payTypeId)) {
            expireMonth.setValidators([Validators.required]);
            expireYear.setValidators([Validators.required]);
            if (this.appService.isPayTypeCredit(payTypeId)) {
                customerZip.setValidators([Validators.required, Validators.pattern(/^\d{5}$/)]);
                cvv.setValidators([Validators.required, Validators.pattern(/^\d{3}$/)]);
            }
        }
        
        bankAccount.updateValueAndValidity();
        routingNumber.updateValueAndValidity();
        creditCardNumber.updateValueAndValidity();
        expireMonth.updateValueAndValidity();
        expireYear.updateValueAndValidity();
        customerZip.updateValueAndValidity();
        cvv.updateValueAndValidity();
        this.form.updateValueAndValidity();

        const billerFee = this.billerFees.filter((row: any) => {
            return (Number(row.paymentTypeId) === Number(payTypeId));
        });

        this.fullTransaction = GetTransactionFees(billerFee[0], Number(this.form.get('payAmount').value));
    }

    lastFourOfCC(): number|string {
        const cardNumber = this.form.get('creditCardNumber').value;
        const _length: number = cardNumber.length;
        const lastFour = cardNumber.substring((_length-4));
        return lastFour;
    }

    displayCCPaymentDetails(): boolean {
        const cardNumber = this.form.get('creditCardNumber').value;
        const payTypeId = this.form.get('payTypeId').value;
        
        return (
            (cardNumber && cardNumber !== '') && 
            (this.appService.isPayTypeCreditDebit(payTypeId))
        )
    }

    lastFourOfAccount(): number|string {
        const cardNumber = this.form.get('bankAccount').value;
        const _length: number = cardNumber.length;
        const lastFour = cardNumber.substring((_length-4));
        return lastFour;
    }

    displayBankPaymentDetails(): boolean {
        const backAcount = this.form.get('bankAccount').value;
        const payTypeId = this.form.get('payTypeId').value;

        return (
            (backAcount && backAcount !== '') && 
            (this.appService.isPayTypeCheckingSavings(payTypeId))
        )
    }

    todaysDate() {
        return moment().format('MM/DD/YYYY');
    }

    toCurrency(i: number|string): string {
        return '$' + Number(i).toFixed(2);
    }

    closeAlert(): void {
        this.billerError = false;
    }

    /* ****
    *
    * Get the list of billers
    * 
    * */
    async getBillers(): Promise<any> {
        try {
            const data = await this.billersService.getBillers('3');
            this.billers = data;
        } catch (error) {
            console.error(error);
            this.billers = [];
        }
    }

    /* ****
    *
    * When a biller is selected, get the specific details of that biller
    * Calls to get the biller accepted pay types and fees
    * Gets the account balance for biller if account number is not empty
    * 
    * */
    getBillerDetails() {
        var val = this.form.controls['billerId'].value;
        document.getElementsByName('billerId').forEach(ctrl => {
            (ctrl as HTMLSelectElement).value = val;
        });

        this.form.patchValue({
            bankAccount: '',
            routingNumber: '',
            creditCardNumber: '',
            expirationDate: '',
            expirationMonth: '',
            expirationYear: '',
            payTypeId: '',
            payAmount: ''
        });
        this.form.updateValueAndValidity();

        const billerId = Number(this.form.controls['billerId'].value);
        const details = this.billers.filter((biller) => {
            return (biller.billerId === billerId);
        });

        if ( details.length > 0) {
            this.billerDetails = details[0];
            this.getBillerPayTypesWithFees(billerId);

            if ( this.form.get('accountNumber').value !== '' ) {
                this.getBillerBalance();
            }
        }
    }

    async getPaymentTypes(): Promise<any> {
        try {
            const data = await this.paymentTypesService.getPaymentTypes();

            this.paymentTypesAll = data;
        } catch (error) {
            this.reporter.showErrorMessage(error);
        }
    }

    /* ****
    *
    * Gets the fees associated with the selected biller
    * This also triggers the method to biller accepted payment types
    * 
    * */
    getBillerPayTypesWithFees = async (billerId: any) => {
        const response = await this.billersService.getBillerInformation(billerId);
        this.billerFees = response;
        
        this.getAcceptedPayTypes();
    }

    /* ****
    *
    * Now that we have the biller accepted pay types, we need to filter the list of all pay types
    * 
    * */
    getAcceptedPayTypes(): void {
        const types: any[] = [];
        this.billerFees.map((fee: any) => {
            types.push(fee.paymentTypeId);
        });
        
        this.acceptedPaymentTypes = types;
        
        if ( this.acceptedPaymentTypes && this.acceptedPaymentTypes.length > 0 ) {
            const methods = this.paymentTypesAll;
            
            this.paymentTypes = methods.filter((method: any) => {
                return ( this.acceptedPaymentTypes.includes( method.id ) );
            });
        }
    }

    isCardPayment(): boolean {
        return (this.form.controls['payTypeId'].value && (this.form.controls['payTypeId'].value === '5' || this.form.controls['payTypeId'].value === '6'))
    }

    isCreditCardPayment(): boolean {
        return (this.form.controls['payTypeId'].value && this.form.controls['payTypeId'].value === '5')
    }

    isDebitCardPayment(): boolean {
        return (this.form.controls['payTypeId'].value && this.form.controls['payTypeId'].value === '6')
    }

    isBankAccountPayment(): boolean {
        return (this.form.controls['payTypeId'].value && (this.form.controls['payTypeId'].value === '3' || this.form.controls['payTypeId'].value === '4'))
    }

    isApplePayPayment(): boolean {
        return (this.form.controls['payTypeId'].value && this.form.controls['payTypeId'].value === '12')
    }

    cardPlaceholderText(): string {
        switch(this.form.controls['payTypeId'].value) {
            case '5':
                return 'Credit Card Number';
            case '6':
                return 'Debit Card Number';
            default:
                return '';
        }
    }

	validateTheABANumber(): void {
		const formInput = this.form.controls['routingNumber'].value;
		const checksum: boolean = ABACheckDigitCheck(formInput);
		if (!checksum) {
			this.isInValidABA = true;
			this.inValidABAMessage = 'The routing number you have entered is not valid';
		}
		else
			this.isInValidABA = false;
	}
	
    validateTheCCNumber(): void {
        const formInput = this.form.controls['creditCardNumber'].value;
        const cardType: any[] = GetCardType(formInput);
        const luhnCheck: boolean = CardLuhnCheck(formInput);
		
		if ( cardType.length === 0 || !luhnCheck ) {
			this.isInValidCC = true;
			this.inValidCCMessage = 'The card number you have entered does not appear to be valid';

			return;
		}

        const accepted = this.billerFees;
        const ccRow = accepted.filter((row: any) => {
            return (row.paymentTypeId === 5);
        });

        if ( ccRow.length > 0 ) {
            const cardList: any[] = ccRow[0]['creditCardsList'];
            const ccDetails = cardList.filter((row: any) => {
                const { creditCardTypeId } = row;
                return ( creditCardTypeId === cardType[0] );
            });

            if ( ccDetails.length > 0 ) {
                this.isInValidCC = false;
                this.inValidCCMessage = '';
                this.subPayTypeId = ccDetails[0]['creditCardTypeId'];

            } else {
                this.isInValidCC = true;
                this.inValidCCMessage = 'The credit card type (' + cardType[1] + ') you have entered is not accepted by the biller';

            }

        } else {
            this.isInValidCC = true;
        }
    }

    async getBillerBalance(): Promise<any> {
        this.gettingBalance = true;
        this.paymentTypesSet = false;

        const accountNumber = this.form.get('accountNumber').value;
        const hasSelectedBiller = (this.billerDetails.billerId && this.billerDetails.billerId !== '' );

        if ( hasSelectedBiller && (accountNumber && accountNumber.replace(/-/g, '') !== '') ) {
            
            const response = await this.billersService.getBillerAccount(this.billerDetails.billerId, accountNumber.replace(/-/g, ''));
            
            if ( !response || (response.result && response.result.toLowerCase() === 'invalid') ) {
                this.billerError = true;
                this.billerValidated = false;
                this.billerErrorText = 'The account number supplied for the selected biller could not be found.';
                this.billerBalance = '$0.00';
                this.gettingBalance = false;
            } else {
                if (response.msgList && response.msgList.length > 0) {
                    var billerId = this.form.controls['billerId'].value;
                    var billerIdForFees;
                    response.msgList.forEach(msg => {
                        if (msg.name.toLowerCase() === 'billerid') {
                            billerIdForFees = msg.value; 
                        }
                    });
                    if (billerId && billerIdForFees && billerIdForFees !== billerId) {
                        this.getBillerPayTypesWithFees(billerIdForFees);
                    }
                }
                
                if (response.noPayments) {
                    this.billerError = true;
                    this.billerValidated = false;
                    this.billerErrorText = 'The ' + this.billerDetails.billerName + ' account ' + accountNumber + ' is not currently accepting payments. Please contact U.S. Payments at 877-876-7076 for further details.';
                    this.billerBalance = '$0.00';
                    this.gettingBalance = false;
                    return;
                }

                this.billerError = false;
                this.billerValidated = true;

                this.form.get('accountNumber').setValue(response.strAccount);

                this.adjustAvailablePaymentTypes(response);

                const balanceTypes = this.getBalance(response);
                let balance = '0';

                if ( balanceTypes['BALANCEDUE'] ) {
                    balance = balanceTypes['BALANCEDUE']['balance'];

                } else if ( balanceTypes['PosBDVerbiage'] ) {
                    balance = balanceTypes['PosBDVerbiage']['balance'];
                }

                this.billerBalance = this.currencyFormat(Number(balance));
                this.gettingBalance = false;
            }
        } else {
            this.gettingBalance = false;
        }
    }

    adjustAvailablePaymentTypes(accountLookupResponse: any) {
        if ( this.acceptedPaymentTypes && this.acceptedPaymentTypes.length > 0 ) {
            if (accountLookupResponse.noPayments) {
                //remove all payment types (and probably throw an error)
                this.paymentTypes = [];
            }
            else {
                const methods = this.paymentTypesAll;
                const types: any[] = [];
                this.paymentTypesSet = false;
                
                this.billerFees.map((fee: any) => {
                    types.push(fee.paymentTypeId);
                });
                var newAcceptedPaymentTypes = types;
                
                this.acceptCc = accountLookupResponse.acceptCc;
                this.acceptDc = accountLookupResponse.acceptDc;
                if (!accountLookupResponse.acceptCc) {
                    //remove CC from available payment types
                    const ccIndex = newAcceptedPaymentTypes.indexOf(5);
                    if (ccIndex !== -1) {
                        newAcceptedPaymentTypes.splice(ccIndex, 1);
                    }
                }
                if (!accountLookupResponse.acceptDc) {
                    //remove DC from available payment types
                    const debitIndex = newAcceptedPaymentTypes.indexOf(6);
                    if (debitIndex !== -1) {
                        newAcceptedPaymentTypes.splice(debitIndex, 1);
                    }
                }
                if (!accountLookupResponse.acceptChecks) {
                    //remove ACH from available payment types
                    const checkingIndex = newAcceptedPaymentTypes.indexOf(3);
                    if (checkingIndex !== -1) {
                        newAcceptedPaymentTypes.splice(checkingIndex, 1);
                    }
    
                    const savingsIndex = newAcceptedPaymentTypes.indexOf(4);
                    if (savingsIndex !== -1) {
                        newAcceptedPaymentTypes.splice(savingsIndex, 1);
                    }
                }

                this.buildApplePayMethod(newAcceptedPaymentTypes);
                
                if (!this.checkApplePaySupport()) {
                    const appleIndex = newAcceptedPaymentTypes.indexOf(GlobalConstants.payType.ApplePay);
                    if (appleIndex !== -1)
                        newAcceptedPaymentTypes.splice(appleIndex, 1);
                }

                this.paymentTypes = methods.filter((method: any) => {
                    return ( newAcceptedPaymentTypes.includes( method.id ) );
                });

                this.paymentTypesSet = true;
            }
        }
    }

    getBalance(response: any) {

        const { msgList }: any = response;

        this.balanceTypes = {};

        for ( let i = 0; i < msgList.length; i++ ) {
            const msg = msgList[i];
            const lstVerbiage: any[] = msg['lstVerbiage'];

            if ( lstVerbiage.length > 0 ) {
            
                for ( let o = 0; o < lstVerbiage.length; o++ ) {
                    this.balanceTypes[lstVerbiage[o]['verbiageName']] = msg;
                }
            }
        }
        
        return this.balanceTypes;
    }

    currencyFormat(amount: any) {
        return '$' + Number(amount).toFixed(2);
    }

    convenienceFee() {
        const fee = this.fullTransaction.fee || 0;
        return this.currencyFormat(fee);
    }

    totalPaymentAmount() {
        // const fee = this.fullTransaction.fee || 0;
        // const paymentAmount = this.form.controls['payAmount'].value;
        // const total = Number(fee) + Number(paymentAmount);
        return this.currencyFormat(this.fullTransaction.total);
    }

    maxAmount() {
        return this.currencyFormat(this.fullTransaction.maxAmount);
    }

    previous() {
        if ( this.stepIndex <= 1 ) {
            this.router.navigate(['/']);;
        }
        
        this.stepIndex--;
    }

    next() {
        if (this.getFormValue('payAmount') <= 0) {
            this.isInValidPayment = true;
        } else {
            this.isInValidPayment = false;

            this.stepIndex++;
            this.paymentProcessing = false;
            this.paymentAttempted = false;
			this.creditCardDeclined = false;
        }
    }

    async makeOneTimePayment(): Promise<any> {
        this.paymentProcessing = true;

        const form = JSON.parse(JSON.stringify(this.form.value));

        form.accountNumber = form.accountNumber.replace(/-/g,'');
        form.billerId = Number(form.billerId);
        form.payTypeId = Number(form.payTypeId);
        form.terminalId = GlobalConstants.mpoTerminalId;
        form.customerPhone = form.customerPhone.replace(/-/g,'');
        form.payAmount = Number(form.payAmount);
        form.customerZip = form.customerZip;
        form.customerFee = Number(this.fullTransaction.fee || 0);

        // Credit and Debit Cards
        if (this.appService.isPayTypeCreditDebit(form.payTypeId)) {
            const expirationDate = form.expireMonth + '/' + form.expireYear;
            form.expirationDate = expirationDate;

            form.subPayTypeId = this.subPayTypeId;
            form.languageId = 1;
            form.serviceId = 3;


            form.isDebitCard = ( form.payTypeId === 6 );
            
            delete form.expireMonth;
            delete form.expireYear;
            delete form.bankAccount;
            delete form.routingNumber;

            if (this.appService.isPayTypeDebit(form.payTypeId)) {
                delete form.cvv;
                delete form.billingZip;
            }

        } else if (this.appService.isPayTypeCheckingSavings(form.payTypeId)) {
            delete form.creditCardNumber;
            delete form.cvv;
            delete form.billingZip;
            delete form.expirationDate;
            delete form.expireMonth;
            delete form.expireYear;
        }
        else if (this.appService.isPayTypeApplePay(form.payTypeId)) {
            delete form.expireMonth;
            delete form.expireYear;
            delete form.bankAccount;
            delete form.routingNumber;
            delete form.creditCardNumber;
            delete form.cvv;
            delete form.billingZip;
            delete form.expirationDate;
            delete form.expireMonth;
            delete form.expireYear;
        }

        const paymentMethod = this.billerFees.filter((row: any) => {
            return ((row.paymentTypeId === form.payTypeId ));
        });

        this.paymentMethod = paymentMethod[0].paymentTypeNameInLanguage;

        if (!this.appService.isPayTypeApplePay(form.payTypeId))
            await this.submitPayment(form);
        else
            await this.makeWalletPayment(form);
    }
    
    async submitPayment(form: any): Promise<any> {
        try {
            this.paymentAttempted = true;
            this.creditCardDeclined = false;
            this.paymentFailed = false;

            const response = await this.billersService.makeOneTimePayment(form);

            if (this.appService.isPayTypeCreditDebit(form.payTypeId)) {
                if ( response.creditCardDeclined ) {
                    this.creditCardDeclined = true;
                    this.paymentFailed = false;

                }
                else if ( !response.paymentSuccessful ) {
                    this.creditCardDeclined = false;
                    this.paymentFailed = true;

                }
                else {
                    this.next();

                    this.paymentId = response.paymentId;
                    this.authCode = response.authCode;
                    this.referenceNumber = response.referenceNumber;
                    this.paymentDate = response.paymentDate;

                    const dt = this.paymentDate.toString().split(' ');

                    this.paymentTime = dt[1];
                    this.paymentDateFormatted = dt[0];

                    this.reporter.showSuccessMessage('A Payment of ' + this.toCurrency(form.payAmount) + ' to ' + this.billerDetails.billerName + ' was successful.');
                }
            }
            else if (this.appService.isPayTypeCheckingSavings(form.payTypeId)) {
                if ( !response.paymentSuccessful )
                    this.paymentFailed = true;
                else {
                    this.next();

                    this.paymentId = response.paymentId;
                    this.authCode = response.authCode;
                    this.referenceNumber = response.referenceNumber;
                    this.paymentDate = response.paymentDate;

                    const dt = this.paymentDate.toString().split(' ');

                    this.paymentTime = dt[1];
                    this.paymentDateFormatted = dt[0];

                    this.reporter.showSuccessMessage('A Payment of ' + this.toCurrency(form.payAmount) + ' to ' + this.billerDetails.billerName + ' was successful.');
                }
            }
            else if (this.appService.isPayTypeApplePay(form.payTypeId)) {
                if ( response.paymentSuccessful ) {
                    this.reporter.showSuccessMessage('A Payment of ' + this.toCurrency(form.payAmount) + ' to ' + this.billerDetails.billerName + ' was successful.');
                
                    this.paymentId = response.paymentId;
                    this.referenceNumber = response.referenceNumber;
                    this.authCode = response.authCode;
                    this.paymentProcessing = false;
    
                    const paymentDate = response.paymentDate;
                    const dt = paymentDate.toString().split(' ');
    
                    this.paymentTime = dt[1];
                    this.paymentDateFormatted = dt[0];
    
                    this.next();
                }
                else {
                    this.paymentFailed = true;
                    this.paymentAttempted = false;
                }
            }

            this.paymentProcessing = false;

        }
        catch (error) {
            this.paymentFailed = true;
            console.error(error);
            this.reporter.showErrorMessage('An error has occurred during payment processing. To try again or change payment types, please go back and ensure all entered data is accurate. You may also call 877-876-7076.');
            this.paymentProcessing = false;
        }
    }

    async makeWalletPayment(form: any): Promise<void> {
        var walletMethods = [] as PaymentMethodData[];
        var paymentRequest: PaymentRequest;

        this.paymentProcessing = true;
        this.paymentFailed = false;

        const paymentDetails = this.buildWalletDetails(form);

        try {
            walletMethods.push(this.applePayMethod);
            paymentRequest = new PaymentRequest(walletMethods, paymentDetails);
            paymentRequest.addEventListener('merchantvalidation', async (event) => {
                const evt = (event as MerchantValidationEvent);
                const merchantSessionPromise = await this.billersService.verifyMerchant(evt.validationURL, this.billerDetails.billerName);
                evt.complete(merchantSessionPromise);
            })
    
            paymentRequest.onpaymentmethodchange = event => {
                const evt = (event as PaymentMethodChangeEvent);
                if (evt.methodDetails.type !== undefined) {
                    var paymentDetailsUpdate = {} as PaymentDetailsUpdate;
                    paymentDetailsUpdate.total = paymentDetails.total;
                    evt.updateWith(paymentDetailsUpdate);
                }
            };
            
            const response = await paymentRequest.show();
            form.walletData = JSON.stringify(response);
            await this.submitPayment(form);
            while (this.paymentFailed) {
                await response.retry();
                await this.submitPayment(form);
            }
            response.complete('success');
        }
        catch (e) {
            if (e.name !== 'AbortError') {
                console.error(e);
                this.paymentFailed = true;

                try {
                    if (paymentRequest)
                        paymentRequest.abort();
                }
                catch {}
            }
        }

        this.paymentProcessing = false;
    }

    buildApplePayMethod(acceptedPaymentTypes: any[]) {
        var applePay = {} as PaymentMethodData;
        var supportedNetworks = [];
        var capabilities = [];

        if (acceptedPaymentTypes.indexOf(GlobalConstants.payType.ApplePay) > -1) {
            capabilities.push('supports3DS'); //Required
            if (this.acceptCc)
                capabilities.push('supportsCredit');
            if (this.acceptDc)
                capabilities.push('supportsDebit');

            const cardType = this.billerFees.filter((row: any) => {
                return (Number(row.paymentTypeId) == GlobalConstants.payType.Credit);
            });

            if (cardType.length > 0) {
                for (var i = 0; i < cardType[0].creditCardsList.length; i++) {
                    switch (cardType[0].creditCardsList[i].creditCardName) {
                        case 'Visa':
                            supportedNetworks.push('visa');
                            break;
                        case 'MasterCard':
                            supportedNetworks.push('masterCard');
                            break;
                        case 'Discover':
                            supportedNetworks.push('discover');
                            break;
                        case 'American Express':
                            supportedNetworks.push('amex');
                            break;
                    }
                }
            }
            
            if (supportedNetworks.length > 0 && capabilities.length > 1) {
                applePay.supportedMethods = "https://apple.com/apple-pay";
                applePay.data = {
                    version: 12,
                    merchantIdentifier: applePayMerchantId,
                    merchantCapabilities: capabilities,
                    supportedNetworks: supportedNetworks,
                    countryCode: 'US',
                    requiredBillingContactFields: ["name", "postalAddress"]
                };
            }
        }
        this.applePayMethod = applePay;
    }

    buildWalletDetails(form: any) {
        var paymentDetails = {} as PaymentDetailsInit;
        var paymentItem = {} as PaymentItem;
        var paymentCurrency = {} as PaymentCurrencyAmount;
        const displayItems = [] as PaymentItem[];

        paymentCurrency.value = this.fullTransaction.total.toFixed(2);
        paymentCurrency.currency = 'USD';

        paymentItem.label = this.billerDetails.billerName;
        paymentItem.amount = paymentCurrency;

        if ( form.customerFee > 0.00 ) {
            displayItems.push(this.createWalletDisplayItem(this.translate.instant('biller-payment.amount-to-apply'), form.payAmount));
            displayItems.push(this.createWalletDisplayItem(this.translate.instant('biller-payment.convenience-fee-wallet'), form.customerFee));
        }

        paymentDetails.total = paymentItem;
        paymentDetails.displayItems = displayItems;

        return paymentDetails;
    }

    createWalletDisplayItem(label: string, value: number) {
        var displayItem = {} as PaymentItem;
        var amountItem = {} as PaymentCurrencyAmount;

        amountItem.value = value.toFixed(2);
        amountItem.currency = 'USD';
        displayItem.label = label;
        displayItem.amount = amountItem;

        return displayItem;
    }

    checkApplePaySupport(): boolean {
        var supports: boolean = false;

        if (window.PaymentRequest && (window as any).ApplePaySession && (window as any).ApplePaySession.canMakePayments() && this.applePayMethod.data)
            supports = true;

        return supports;
    }

    openRoutingExample() {
        const modalRef = this.modal.open(WheresRoutingNumberComponent, { size: 'md', centered: true });
        modalRef.componentInstance.name = 'WheresRoutingNumber';
    }

    print($event: any): void {
        const html = document.getElementById('receiptContent').innerHTML;
        document.getElementById('printReceipt').innerHTML = '<div id="receiptForPrint">' + html + '</div>';
        
        window.onafterprint = function() {
            document.getElementById('printReceipt').innerHTML = '';
        }

        window.print();
        
    }

    ngOnInit(): void {
        this.getBillers();
        this.getPaymentTypes();
    }
}