import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { BillersService } from '../../services/billers/billers.service';

import { BillerAddComponent } from '../../pages/biller-add/biller-add.component';
import { billerImageUrl } from '../../../config';
import { BillerDeleteComponent } from 'src/app/pages/biller-delete/biller-delete.component';
import { MessageReporter } from 'src/app/services/messages/message-reporter.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-my-billers',
    templateUrl: './my-billers.component.html',
    styleUrls: ['./my-billers.component.scss']
})
export class MyBillersComponent implements OnInit {
    billerImageUrl: string = billerImageUrl;
    billers: any[];
    gettingMyBillers: boolean = true;

    constructor(
        private billersService: BillersService,
        private modal: NgbModal,
        private router: Router,
        private reporter: MessageReporter,
        private translate: TranslateService
    ) { }

    async getMyBillers(): Promise<any> {
        try {
            const data = await this.billersService.getMyBillers(true, false, "3");
            this.billers = data;
            this.gettingMyBillers = false;
        } catch (error) {
            this.gettingMyBillers = false;
            console.log(error);
        }
    }

    goToBiller(biller: any) {
        if (biller.billerEnabled === undefined || biller.billerEnabled)
            this.router.navigate(['/biller-payment', { account: biller.accountNumber }]);
        else
            this.reporter.showInfoMessage(this.translate.instant("biller-payment.biller-disabled"));
    }

    openAddBiller() {
        const modalRef = this.modal.open(BillerAddComponent, { size: 'md', centered: true });
        modalRef.componentInstance.name = 'AddBiller';
        modalRef.componentInstance.parent = this;
    }

    deleteBillerAccount(biller: any): void {
        window.event.cancelBubble = true;
        const modalRef = this.modal.open(BillerDeleteComponent, { size: 'md', centered: true });
        modalRef.componentInstance.name = 'DeleteBillerAccount';
        modalRef.componentInstance.biller = biller;
        modalRef.componentInstance.parent = this;
    }

    ngOnInit(): void {
        this.getMyBillers();
    }

}
