<form [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="modal-header">
        <h4 class="modal-title">{{'global.sign-in' | translate}}</h4>
    </div>
    <div class="modal-body">
        <ngb-alert 
            type="success" 
            *ngIf="fromSignUp" 
            (closed)="closeAltert()"
        >
            <div>{{'sign-in.thank-you-signing-up' | translate}}!</div>
            <div>{{'sign-in.login' | translate}}</div>
        </ngb-alert>
        <ngb-alert 
            type="danger" 
            *ngIf="loginError" 
            (closed)="closeAltert()"
        >
            {{loginErrorText}}
        </ngb-alert>
        <div class="form-group">
            <email-input 
                name="email" 
                required="true"
                [label]="'Email'"
                [value]="getFormValue('email')"
                (changeEvent)="updateFormField($event)"
            ></email-input>
        </div>
        <div class="form-group">
            <password-input 
                name="password" 
                required=true
                [label]="'Password'"
                (changeEvent)="updateFormField($event)"
            ></password-input>
        </div>
        <div class="forgotPasswordDiv col-12 d-lg-none d-sm-block d-md-none">
            <button type="button" class="btn btn-link" (click)="forgotPassword()">{{'sign-in.forgot-password' | translate}}</button>
        </div>
    </div>
    <div class="modal-footer">
        <div class="button-row row w-100">
            <div class="col-6 d-none d-md-block align-left">
                <button type="button" class="btn btn-link" (click)="forgotPassword()">{{'sign-in.forgot-password' | translate}}</button>
            </div>
            <div class="col-12 col-md-6 align-right pe-0">
                <button 
                    type="button" 
                    class="btn btn-light" 
                    (click)="activeModal.close()"
                >{{'global.cancel' | translate}}</button>
                <button 
                    type="submit" 
                    class="btn btn-primary"  
                    *ngIf="!loginActive"
                    [disabled]="!form.valid || loginActive"
                >{{'global.sign-in' | translate}}</button>
                <app-spinner *ngIf="loginActive"></app-spinner>
            </div>
        </div>
    </div>
</form>