<span class="textLabel">{{label}}</span><span *ngIf="required" class="textLabel"> *</span>
<div class="input-group" [formGroup]="form">
    <div class="input-group-prepend" *ngIf="faIcon && faIcon !== ''">
        <span class="input-group-text" id="basic-addon1"><fa-icon [icon]="[faPack, faIcon]"></fa-icon></span>
    </div>
    <input 
        type="text" 
        formControlName="formInput" 
        placeholder={{placeholder}} 
        value={{value}}
        [ngClass]="{ 'form-control': true, 'is-invalid' : isDirtyandInvalid(), 'extra-character-spacing' : extraSpacing }"
        (ngModelChange)="onChange()"
        minLength={{minLength}}
        maxLength={{getMaxLength()}}
        tabindex={{tabindex}}
    />
    <div class="invalid-feedback" *ngIf="isDirtyandInvalid()">
        {{inputErrorMessage()}}
    </div>
    <div class="invalid-feedback" *ngIf="notMinLength()">
        {{minLengthMessage}}
    </div>
    <div class="invalid-feedback" *ngIf="notMaxLength()">
        {{maxLengthMessage}}
    </div>
</div>