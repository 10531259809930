import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { MessageReporter } from '../../services/messages/message-reporter.service';
import { UserService } from '../../services/user/user-service.service';

@Component({
    selector: 'app-password-reset',
    templateUrl: './password-reset.component.html',
    styleUrls: ['./password-reset.component.scss']
})
export class PasswordResetComponent implements OnInit {

    form: UntypedFormGroup = new UntypedFormGroup({});
    validator = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{12,})/;

    invalidPasswordError: string = 'Passowrd must contain:<br /> - at least 12 characters<br /> - 1 uppercase character<br /> - 1 lowercase character<br /> - 1 special characte<br /> - 1 number';

    constructor(
        private fb: UntypedFormBuilder,
        private userService: UserService,
        private reporter: MessageReporter,
        private route: ActivatedRoute,
        private router: Router,
    ) { }

    confirmValidator = () => {
        const noMatch: boolean = ( this.form.get('newPassword').value !== this.form.get('confirmPassword').value );
        return noMatch ? { MustMatch: true } : null;
    }

    getFormValue(field: string): string|number {
        return this.form.get(field).value;
    }

    updateFormField($event: any): void {
        this.form.controls[$event.name] = $event;
        this.form.updateValueAndValidity();
    }

    goHome(): void {
        this.router.navigate(['/']);
    }

    async submitForgotPassword(): Promise<any> {
        try {
            const body = this.form.value;
            const response = await this.userService.submitForgotPassword(body);

            if ( response || response === null ) {
                this.reporter.showInfoMessage('You password has been reset successfully');
                this.router.navigate(['/login']);
            }
        } catch (error) {
            console.log(error);
        }
    }

    ngOnInit(): void {
        this.form = this.fb.group({
            email: new UntypedFormControl('', [Validators.required]),
            token: new UntypedFormControl('', [Validators.required]),
            newPassword: new UntypedFormControl('', [Validators.required]),
            confirmPassword: new UntypedFormControl('', [Validators.required]),
        });

        this.route.queryParams.subscribe(params => {
            if ( params.token ) {
                const token = params.token.replace(/ /ig, '+');
                this.form.get('token').setValue(token);
            }
        });
    }

}
