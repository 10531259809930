import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators, AbstractControl } from '@angular/forms';

@Component({
    selector: 'password-input',
    templateUrl: './password-input.component.html',
    styleUrls: ['./password-input.component.scss']
})
export class PasswordInputComponent implements OnInit, OnChanges {
    @Output() changeEvent = new EventEmitter<any>();

    @Input() faIcon: string = 'key';
    @Input() faPack: string = 'fas';
    @Input() invalidMessage: string;
    @Input() name: string;
    @Input() placeholder: string;
    @Input() required: boolean;
    @Input() requiredMessage: string;
    @Input() label: string;
    @Input() value: any = '';
    @Input() validator: any;
    @Input() minLength: any;
    @Input() maxLength: any;

    maxLengthMessage = '';
    minLengthMessage = '';
    confirmNotMatchMessage = 'Confirm Password does not match Password';

    form: UntypedFormGroup = new UntypedFormGroup({
        formInput: new UntypedFormControl(''),
    });

    constructor() { }
        
    ngOnChanges(changes: SimpleChanges) {
        if ( changes.value ) {
            const formInput = this.form.controls['formInput'];
            formInput.setValue(changes.value.currentValue);
            formInput.updateValueAndValidity();
        }

        if ( changes.minLength ) {
            this.minLength = changes.minLength.currentValue;
        }

        if ( changes.maxLength ) {
            this.maxLength = changes.maxLength.currentValue;
        }
    }

    isRequired(): boolean {
        const formInput = this.form.get('formInput');
        const validator = formInput.validator({} as AbstractControl);
        return (validator && validator.required);
    }

    inputErrorMessage() {
        const formInput = this.form.get('formInput');

        if (this.isRequired() && formInput.value === '' && (formInput.dirty || formInput.touched)) {
            return this.requiredMessageOutput();
        }

        return !formInput.valid ? this.invalidMessageOutput() : '';
    }

    invalidMessageOutput() {
        const formInput = this.form.get('formInput');
        const errors = formInput.errors;
        const MustMatch = ( errors !== null ) ? errors.MustMatch : undefined;

        if ( MustMatch ) {
            return this.confirmNotMatchMessage;
        }

        const label = this.label.replace('*', '');
        const text = `Not a valid ${label}`;
        const message = this.invalidMessage || text;

        return message;
    }

    isDirtyandInvalid() {
        const formInput = this.form.get('formInput');
        const errors = formInput.errors;

        return ( formInput.invalid && ( formInput.dirty || formInput.touched ) || this.notMaxLength() ||  this.notMinLength() );
    }

    getMaxLength() {
        return this.maxLength || 999999999;
    }

    notMinLength(): boolean {
        const formInput = this.form.controls['formInput'];
        const length = (formInput.value) ? formInput.value.toString().length : 0;

        if ( formInput.value === '' || formInput.value === null ) {
            return false;
        }

        return ( !formInput.pristine && length < this.minLength);
    }

    notMaxLength(): boolean {
        const formInput = this.form.controls['formInput'];
        const length = (formInput.value) ? formInput.value.toString().length : 0;
        
        return ( !formInput.pristine && length > this.maxLength);
    }

    onChange() {
        const formInput = this.form.get('formInput');
        formInput['name'] = this.name;
        this.changeEvent.emit(formInput);
    }

    requiredMessageOutput() {
        const label = this.label.replace('*', '');
        const text = `${label} is a required field`;
        const message = this.requiredMessage || text;

        return message;
    }

    ngOnInit(): void {
        this.form.controls['formInput'].setValue(this.value);
        const validators = [];

        if (this.required) {
            validators.push(Validators.required);
        }

        if ( this.validator && typeof(this.validator) === 'function') {
            validators.push(this.validator);
        } else if ( this.validator ) {
            validators.push(Validators.pattern(this.validator));
        }

        if (this.minLength) {
            validators.push(Validators.minLength(this.minLength));
            this.minLengthMessage = 'Must be at least ' + this.minLength + ' characters long';
        }

        if (this.maxLength) {
            validators.push(Validators.maxLength(this.maxLength));
            this.maxLengthMessage = 'Can not be greater than ' + this.minLength + ' characters long';
        }

        this.form.controls['formInput'].setValidators(validators);
    }

}
