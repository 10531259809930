<header id="navbar">
    <div class="row">
        <div class="navbar-logo-col col-lg-4 col-xl-3">
            <a routerLink="/" routerLinkActive="active" class="navbar-logo">
                <img class="d-lg-none d-xl-none d-xxl-none" src="assets/images/logo-sm.png" />
                <img class="d-none d-lg-block d-xl-block d-xxl-block" src="assets/images/logo-lrg.png" />
            </a>
        </div>
        <div class="navbar-title-col col-lg-8 col-xl-9">
            <div class="row navbar-title-row">
                <h1 class="navbar-title">{{title}}</h1>
            </div>
            <div id="topNavLinks" class="row nav-links" *ngIf="isLoggedIn">
                <ul class="navbar-menu">
                    <a class="barcode-icon"><li (click)="openBarcode()"><fa-icon [icon]="['fas', 'barcode']"></fa-icon></li></a>
                    <a routerLink="/kiosks" routerLinkActive="active"><li><fa-icon [icon]="['fas', 'store-alt']"></fa-icon> {{'global.find-kiosk' | translate}}</li></a>
                    <a routerLink="/dashboard" routerLinkActive="active"><li>{{'global.dashboard' | translate}}</li></a>
                    <a routerLink="/profile" [queryParams]="{tab: 0}" routerLinkActive="active"><li id="topNavProfileLink">{{'global.profile' | translate}}</li></a>
                    <a routerLink="/profile" [queryParams]="{tab: 3}" routerLinkActive="active"><li id="topNavProfileLink">{{'change-pin.change-pin' | translate}}</li></a>
                    <a href="javascript: void(0);" (click)="logout()" title="Logout"><li>{{'global.logout' | translate}}</li></a>
                </ul>
            </div>
        </div>
    </div>
    <!-- <div class="nav-title">
        <h1>{{title}}</h1>
    </div> -->
    <div class="mobile-barcode-icon" (click)="openBarcode()" *ngIf="isLoggedIn"><fa-icon [icon]="['fas', 'barcode']"></fa-icon></div>
    <div class="mobile-menu-icon" (click)="hideShowMenu()" *ngIf="isLoggedIn"><fa-icon [icon]="['fas', 'bars']"></fa-icon></div>
    <div id="topNavLinks-mobile" class="nav-links" *ngIf="isLoggedIn">
        <ul id="navLinks-menu" class="navbar-menu">
            <a routerLink="/welcome" routerLinkActive="active"><li><fa-icon [icon]="['fas', 'store-alt']"></fa-icon> {{'global.find-kiosk' | translate}}</li></a>
            <a routerLink="/dashboard" routerLinkActive="active"><li><fa-icon [icon]="['fas', 'columns']"></fa-icon> {{'global.dashboard' | translate}}</li></a>
            <a routerLink="/profile" [queryParams]="{tab: 0}" routerLinkActive="active"><li id="topNavProfileLink"><fa-icon [icon]="['fas', 'user-circle']"></fa-icon> {{'global.profile' | translate}}</li></a>
            <a routerLink="/profile" [queryParams]="{tab: 3}" routerLinkActive="active"><li id="topNavProfileLink"><fa-icon [icon]="['fas', 'hashtag']"></fa-icon> {{'change-pin.change-pin' | translate}}</li></a>
            <a href="javascript: void(0);" (click)="logout()"><li><fa-icon [icon]="['fas', 'sign-out-alt']"></fa-icon> {{'global.logout' | translate}}</li></a>
        </ul>
    </div>
    <div class="clearfix"></div>
    <!-- <h1 id="navbar-title" class="nav-title-mobile">{{title}}</h1> -->
</header>
