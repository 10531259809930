<form [formGroup]="form">
    <div class="modal-header">
        <h4 class="modal-title">{{'global.sign-up' | translate}}</h4>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-lg-5 left-column">
                <p>
                    <button class="btn bt-lg btn-kiosk" name="kiosk" (click)="selectType($event)">{{'sign-up.previous-account' | translate}}</button>
                    <button class="btn bt-lg btn-new" name="new" (click)="selectType($event)">{{'sign-up.create-new-account' | translate}}</button>
                </p>
            </div>
            <div class="col-lg-7 right-column">
                <div *ngIf="!accountType || accountType === ''">
                    <p>{{'sign-up.create-mypaysite-account' | translate}}</p>
                </div>
                <div *ngIf="accountType && accountType !== ''">
                    <ngb-alert 
                        type="danger" 
                        *ngIf="signUpError" 
                        (closed)="closeAlert()"
                    >
                        {{signUpErrorText}}
                    </ngb-alert>
                    <ngb-alert 
                        type="danger" 
                        *ngIf="signUpErrorComplex" 
                        (closed)="closeAlert()"
                    >
                        <div [innerHtml]="signUpErrorText"></div>
                    </ngb-alert>
                    <!-- Kiosk Mode Form -->
                    <div *ngIf="accountType === 'kiosk'">
                        <h3>{{'sign-up.kiosk-account' | translate}}</h3>
                        <p>{{'sign-up.set-up-pin' | translate}}</p>
                        <div class="form-group">
                            <phone-input 
                                name="phone" 
                                required=true
                                [label]="'global.phone-number' | translate"
                                [value]="getFormValue('phone')"
                                (changeEvent)="updateFormField($event)"
                            ></phone-input>
                        </div>
                        <div class="form-group">
                            <text-input 
                                name="pin" 
                                faIcon="lock"
                                required=true
                                [label]="'global.pin' | translate"
                                [value]="getFormValue('pin')"
                                (changeEvent)="updateFormField($event)"
                            ></text-input>
                        </div>
                        <hr />
                        <p>{{'sign-up.complete-following' | translate}}</p>
                        <div class="form-group">
                            <text-input 
                                name="lastName" 
                                faIcon="user"
                                required=true
                                [label]="'global.last-name' | translate"
                                [validator]="nameValidator"
                                [value]="getFormValue('lastName')"
                                (changeEvent)="updateFormField($event)"
                            ></text-input>
                        </div>
                        <div class="form-group">
                            <email-input 
                                name="email" 
                                required=true
                                [label]="'global.email' | translate"
                                [value]="getFormValue('email')"
                                (changeEvent)="updateFormField($event)"
                            ></email-input>
                        </div>
                        <div class="form-group">
                            <password-input 
                                name="password"
                                required=true
                                invalidMessage="12 characters long; 1 upper; 1 lower; 1 number; 1 special character"
                                [label]="'global.password' | translate"
                                [validator]="validator"
                                [value]="getFormValue('password')"
                                (changeEvent)="updateFormField($event)"
                            ></password-input>
                        </div>
                        <div class="form-group">
                            <password-input 
                                name="confirmPassword" 
                                required=true
                                [label]="'global.confirm-password' | translate"
                                [value]="getFormValue('confirmPassword')"
                                (changeEvent)="updateFormField($event)"
                            ></password-input>
                        </div>
                    </div>
                    <!-- New Account Mode Form -->
                    <div *ngIf="accountType === 'new'">
                        <h3>{{'sign-up.new-account' | translate}}</h3>
                        <div class="form-group">
                            <text-input 
                                name="lastName" 
                                faIcon="user"
                                required=true
                                [label]="'global.last-name' | translate"
                                [validator]="nameValidator"
                                [value]="getFormValue('lastName')"
                                (changeEvent)="updateFormField($event)"
                            ></text-input>
                        </div>
                        <div class="form-group">
                            <phone-input 
                                name="phone" 
                                required=true
                                [label]="'global.phone-number' | translate"
                                [value]="getFormValue('phone')"
                                (changeEvent)="updateFormField($event)"
                            ></phone-input>
                        </div>
                        <div class="form-group">
                            <email-input 
                                name="email" 
                                required=true
                                [label]="'global.email' | translate"
                                [value]="getFormValue('email')"
                                (changeEvent)="updateFormField($event)"
                            ></email-input>
                        </div>
                        <div class="form-group">
                            <text-input 
                                name="pin" 
                                faIcon="lock"
                                required=true
                                [label]="'global.pin' | translate"
                                [value]="getFormValue('pin')"
                                (changeEvent)="updateFormField($event)"
                            ></text-input>
                        </div>
                        <div class="form-group">
                            <password-input 
                                name="password" 
                                required=true
                                invalidMessage="12 characters long; 1 upper; 1 lower; 1 number; 1 special character"
                                [label]="'global.password' | translate"
                                [value]="getFormValue('password')"
                                [validator]="validator"
                                (changeEvent)="updateFormField($event)"
                            ></password-input>
                        </div>
                        <div class="form-group">
                            <password-input 
                                name="confirmPassword" 
                                required=true
                                [label]="'global.confirm-password' | translate"
                                [value]="getFormValue('confirmPassword')"
                                [validator]="confirmValidator"
                                (changeEvent)="updateFormField($event)"
                            ></password-input>
                        </div>
                    </div>
                    <div class="d-block">
                        <p *ngIf="accountType && accountType !== ''">* {{'global.required' | translate}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <div class="button-row row">
            <div class="col-lg-12 col-sm-12 align-right">
                <div *ngIf="!accountType || accountType === ''">
                    <button type="button" class="btn btn-light" (click)="activeModal.dismiss('Close click')">{{'global.close' | translate}}</button>
                </div>
                <div *ngIf="accountType && accountType !== ''">
                    <button type="button" class="btn btn-light" (click)="activeModal.dismiss('Close click')">{{'global.cancel' | translate}}</button>
                    <button 
                        type="submit" 
                        class="btn btn-primary" 
                        [disabled]="!form.valid" 
                        (click)="onSubmit()" 
                        *ngIf="!signUpActive"
                    >{{'global.sign-up' | translate}}</button>
                    <app-spinner *ngIf="signUpActive"></app-spinner>
                </div>
            </div>
        </div>
    </div>
</form>