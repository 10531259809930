import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';

import { PaymentTypes } from './payment-types.data';

@Injectable({
  providedIn: 'root'
})
export class PaymentTypesService {

  constructor(
      private api: ApiService,
  ) { }

  public async getPaymentTypes(): Promise<any> {
    return PaymentTypes;
  }
}
