<span class="textLabel">{{label}}</span><span *ngIf="required" class="textLabel"> *</span>
<div class="input-group" [formGroup]="form">
    <div class="input-group-prepend">
        <span class="input-group-text" id="basic-addon1"><fa-icon [icon]="faPhone"></fa-icon></span>
    </div>
    <input 
        #phoneInput
        type="text" 
        mask="000-000-0000"
        value={{value}}
        formControlName="formInput"
        placeholder={{placeholder}}
        [className]="isDirtyandInvalid() ? 'form-control is-invalid' : 'form-control'"
        (change)="onChange()"
        [dropSpecialCharacters]="false"
        tabindex="{{tabindex}}"
    />
    <div class="invalid-feedback" *ngIf="isDirtyandInvalid()">
        {{inputErrorMessage()}}
    </div>
</div>