<app-navbar title="Password Reset" ></app-navbar>

<div class="container">
    <paper header="Reset Your Password">
        <form [formGroup]="form" class="p-3">
            <input type="hidden" name="token" formControlName="token" />
            <div class="form-group">
                <email-input 
                    name="email" 
                    required=true
                    [label]="'Email'"
                    [value]="getFormValue('email')"
                    (changeEvent)="updateFormField($event)"
                    ngbAutofocus
                ></email-input>
            </div>
            <div class="form-group">
                <password-input 
                    name="newPassword" 
                    required=true
                    invalidMessage="{{invalidPasswordError}}"
                    [label]="'New Password'"
                    [validator]="validator"
                    [value]="getFormValue('newPassword')"
                    (changeEvent)="updateFormField($event)"
                ></password-input>
            </div>
            <div class="form-group">
                <password-input 
                    name="confirmPassword" 
                    required=true
                    [label]="'Confirm New Password'"
                    [value]="getFormValue('confirmPassword')"
                    [validator]="confirmValidator"
                    (changeEvent)="updateFormField($event)"
                ></password-input>
            </div>
            <div class="d-lg-none d-md-block d-lg-none">
                <p>* {{'global.required' | translate}}</p>
            </div>
            <div class="d-none d-lg-block">
                <div class="button-row">
                    <div class="row">
                        <div class="col-12 align-right">
                            <button class="btn btn-light" (click)="goHome()">{{'global.cancel' | translate}}</button>
                            <button class="btn btn-primary" [disabled]="form.invalid" (click)="submitForgotPassword()">{{'global.submit' | translate}}</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="d-md-block d-lg-none">
                <div class="button-row">
                    <div class="row">
                        <div class="col-6 align-left">
                            <button class="btn btn-light" (click)="goHome()">{{'global.cancel' | translate}}</button>
                        </div>
                        <div class="col-6 align-right">
                            <button class="btn btn-primary" [disabled]="form.invalid" (click)="submitForgotPassword()">{{'global.submit' | translate}}</button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </paper>
</div>
