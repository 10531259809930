<app-navbar title="Dashboard"></app-navbar>

<div class="flexDivLg">
    <div class="leftDivLg" *ngIf="accountLinked">
        <header>
            <h2 class="thin">{{'global.pay-now' | translate}}</h2>
            <p>{{'dashboard.select-bill' | translate}}</p>
        </header>
        <app-my-billers id="myBillers" class="content"></app-my-billers>
        <app-receipt-method [isDashboard]="true" *ngIf="displayReceiptMethods"></app-receipt-method>
    </div>
    <div class="rightDivLg p-3" *ngIf="accountLinked">
        <header>
            <h2 class="thin">{{'dashboard.pay-history' | translate}}</h2>
            <p>{{'dashboard.recent-transaction' | translate}}:</p>
        </header>
        <div class="content"><app-pay-history></app-pay-history></div>
    </div>
</div>