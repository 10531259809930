import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { PayMethodService } from '../../../services/pay-method/pay-method.service';
import { AddNewPayMethodComponent } from './add-new-pay-method/add-new-pay-method.component';
import { DeletePayMethodComponent } from './delete-pay-method/delete-pay-method.component';

import { PayMethod } from '../../../interfaces/pay-method.interface';
import { GlobalConstants } from 'src/app/common/global-constants';

@Component({
    selector: 'app-payment-methods',
    templateUrl: './payment-methods.component.html',
    styleUrls: ['./payment-methods.component.scss']
})
export class PaymentMethodsComponent implements OnInit {
    _accepted: any[] = [];
    _billerFees: any[] = [];
    _allowApplyPay: boolean = false;
    @Input() public color: string = 'dark';
    @Input() set accepted(value: any[]) {
        this._accepted = value;
        this.filterPaymentMethods();
    };
    @Input() set billerFees(value: any[]) {
        this._billerFees = value;
    };
    @Input() set showApplePay(value: boolean) {
        this._allowApplyPay = value;
    };
    
    @Output() onPayMethodSelected = new EventEmitter<any>();

    payMethods: any[] = [];

    constructor(
        private modal: NgbModal,
        private payMethod: PayMethodService,
    ) { }

    public showAddMethod(): boolean {
        var display: boolean = false;
        var isWallet = this._accepted.filter((row: any) => {
            return row.isWallet;
        });
        
        if (this._accepted.length - isWallet.length > 0)
            display = true;
        return display;
    }

    public async getPayMethods(): Promise<any> {
        try {
            const data: any[] = await this.payMethod.getPayMethods();

            this.payMethods = data;

            this.filterPaymentMethods();

        } catch (error) {
            console.error(error);
        }
    }

    filterPaymentMethods(): void {
        if ( this._accepted && this._accepted.length > 0 ) {
            const methods = this.payMethods;

            const accepted = this._accepted;
            const acceptedIds: any[] = [];
            accepted.map((typeId: any) => {
                acceptedIds.push(typeId.id);
            });
            
            this.payMethods = methods.filter((method: any) => {
                return ( acceptedIds.includes( method.payTypeId ) );
            });
        } else {
            this.payMethods = [];
        }

        if ( this._allowApplyPay ) {
            this.payMethods.push({
                accountNumber: null,
                bankAcctLast4: null,
                billerId: 0,
                ccExpDateMmyy: null,
                ccLast4: null,
                customerFee: null,
                customerZip: null,
                cvv: null,
                languageId: 0,
                lastName: null,
                payAmount: 0,
                payTypeId: GlobalConstants.payType.ApplePay,
                paymentMethodId: 0,
                paymentTypeNameInLanguage: 'Apple Pay',
                phoneNumber: null,
                routingNumber: null,
                serviceId: 0,
                subPayTypeId: 0,
                isWallet: true
            });
        }
    }

    payMethodSelected(payMethod: PayMethod): void {
        this.onPayMethodSelected.emit(payMethod);
    }

    addNewPayMethod(): void {
        const modalRef = this.modal.open(AddNewPayMethodComponent, { size: 'md', centered: true });
        modalRef.componentInstance.name = 'AddNewPayMethod';
        modalRef.componentInstance.parent = this;
    }

    deletePayMethod(payMethod: any): void {
        window.event.cancelBubble = true;
        const modalRef = this.modal.open(DeletePayMethodComponent, { size: 'md', centered: true });
        modalRef.componentInstance.name = 'DeletePayMethod';
        modalRef.componentInstance.payMethod = payMethod;
        modalRef.componentInstance.parent = this;
    }

    ngOnInit(): void {
        this.getPayMethods();
    }
}