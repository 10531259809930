import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';

@Component({
    selector: 'text-input',
    templateUrl: './text-input.component.html',
    styleUrls: ['./text-input.component.scss']
})
export class TextInputComponent implements OnInit, OnChanges {
    @Output() changeEvent = new EventEmitter<any>();
    @Output() blurEvent = new EventEmitter<any>();

    @Input() faIcon: string;
    @Input() faPack: string = 'fas';
    @Input() invalidMessage: string;
    @Input() name: any;
    @Input() required: boolean;
    @Input() extraSpacing: boolean = false;
    @Input() placeholder: string;
    @Input() requiredMessage: string;
    @Input() value: any = '';
    @Input() validator: any;
    @Input() label: string;
    @Input() tabindex: string;

    form: UntypedFormGroup = new UntypedFormGroup({
        formInput: new UntypedFormControl(''),
    });

    constructor() { }
        
    ngOnChanges(changes: SimpleChanges) {
        if ( changes.value ) {
            const formInput = this.form.controls['formInput'];
            formInput.setValue(changes.value.currentValue);
            formInput.updateValueAndValidity();
        }
    }

    isRequired(): boolean {
        const formInput = this.form.get('formInput');
        const validator = formInput.validator({} as AbstractControl);
        return (validator && validator.required);
    }

    inputErrorMessage() {
        const formInput = this.form.get('formInput');
                
        if (this.isRequired() && formInput.value === '' && (formInput.dirty || formInput.touched)) {
            return this.requiredMessageOutput();
        }

        return !formInput.valid ? this.invalidMessageOutput() : '';
    }

    invalidMessageOutput() {
        const label = this.label.replace('*', '');
        const text = `Not a valid ${label}`;
        const message = this.invalidMessage || text;

        return message;
    }

    isDirtyandInvalid() {
        const formInput = this.form.get('formInput');
        return ( formInput.invalid && ( formInput.dirty || formInput.touched ) );
    }

    onChange() {
        const formInput = this.form.get('formInput');
        formInput['name'] = this.name;
        this.changeEvent.emit(formInput);
    }

    onBlur() {
        this.blurEvent.emit();
    }

    requiredMessageOutput() {
        const label = this.label.replace('*', '');
        const text = `${label} is a required field`;
        const message = this.requiredMessage || text;

        return message;
    }

    ngOnInit(): void {
        this.form.controls['formInput'].setValue(this.value);
        const validators = [];

        if (this.required) {
            validators.push(Validators.required);
        }

        if ( this.validator ) {
            validators.push(Validators.pattern(this.validator));
        }

        this.form.controls['formInput'].setValidators(validators);
    }

}
