<span class="textLabel">{{label}}</span><span *ngIf="required" class="textLabel"> *</span>
<div class="input-group" [formGroup]="form">
    <div class="input-group-prepend" *ngIf="faIcon && faIcon !== ''">
        <span class="input-group-text" id="basic-addon1"><fa-icon [icon]="[faPack, faIcon]"></fa-icon></span>
    </div>
    <input 
        type="text" 
        value={{value}}
        formControlName="formInput"
        placeholder={{placeholder}} 
        [ngClass]="{ 'form-control': true, 'is-invalid' : isDirtyandInvalid(), 'extra-character-spacing' : extraSpacing }"
        (ngModelChange)="onChange()"
        (blur)="onBlur()"
        tabindex="{{tabindex}}"
    />
    <div class="invalid-feedback" *ngIf="isDirtyandInvalid()">
        {{inputErrorMessage()}}
    </div>
</div>