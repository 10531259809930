<form [formGroup]="form">
    <div class="modal-header">
        <h4 class="modal-title">{{'biller-add.add-biller' | translate}}</h4>
    </div>
    <div class="modal-body">
        <ngb-alert 
            type="danger" 
            *ngIf="billerError" 
            (closed)="closeAlert()"
        >
            <div [innerHtml]="billerErrorText"></div>
        </ngb-alert>
        <div class="form-group">
            <span class="textLabel">{{'global.biller' | translate}}</span>
            <select name="billerId" class="form-control" formControlName="billerId">
                <option *ngFor="let biller of billers" value={{biller.billerId}}>{{biller.billerName}}</option>
            </select>
        </div>
        <div class="form-group">
            <text-input
                name="accountNumber"
                required=true
                [label]="'Account Number'"
                [value]="getFormValue('accountNumber')"
                (changeEvent)="updateFormField($event)"
                [extraSpacing]="true"
            ></text-input>
        </div>
    </div>
    <div class="modal-footer">
        <div class="button-row">
            <button class="btn btn-danger" (click)="activeModal.dismiss('Cancelled')">{{'global.cancel' | translate}}</button>
            <app-spinner *ngIf="addingBiller"></app-spinner>
            <button 
                class="btn btn-primary" 
                [disabled]="form.invalid" 
                (click)="addBillerAccount()"
                *ngIf="!addingBiller"
            >
            {{'biller-add.add-biller' | translate}}
            </button>
        </div>
    </div>
</form>