import { Component, Input, OnInit } from '@angular/core';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { UserService } from '../../services/user/user-service.service';
import { IdleTimeoutService } from '../../services/idle-timeout/idle-timeout.service';

@Component({
    selector: 'app-timeout-modal',
    templateUrl: './timeout-modal.component.html',
    styleUrls: ['./timeout-modal.component.scss']
})
export class TimeoutModalComponent implements OnInit {
    @Input() idle: IdleTimeoutService;

    interval: any;
    counter: number = 60;

    idleState: string = '';
    timedOut = false;
    lastPing?: Date = null;

    constructor(
        public activeModal: NgbActiveModal,
        private userService: UserService,
    ) { }

    countdown(): void {
        this.counter = this.idle.idleValue/1000;
        window.clearInterval(this.interval);

        this.interval = window.setInterval(() => {
            this.counter--;

            if ( this.counter <= 0 ) {
                this.stop();
            } else {
                this.idleState = `Your session will timeout in ${this.counter} seconds!`;
            }
        }, 1000);
    }

    stop(): void {
        this.counter = 0;
        window.clearInterval(this.interval);

        this.idle.logout();
    }

    public extendSession(): void {
        this.counter = this.idle.idleValue/1000;
        window.clearInterval(this.interval);
        this.activeModal.close();

        this.idle.extendSession();
    }

    clear(): void {
        this.counter = this.idle.idleValue/1000;
        window.clearInterval(this.interval);
    }

    logout(): void {
        this.idle.modalRef.close();
        this.idle.logout();
    }

    ngOnInit(): void {
        const idleValue = this.idle.idleValue/1000;
        this.idleState = `Your session will timeout in ${idleValue} seconds!`;

        if ( this.idle.modalRef ) {
            this.idle.modalRef.result.then(
                () => this.clear(),
                (error: any) => { return; }
            );
        }

        this.countdown();
    }

}
