import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';
import { HttpClient } from '@angular/common/http';

import { MapsApiKey } from '../../../config';

@Injectable({
    providedIn: 'root'
})
export class KioskService {

    constructor(
        private http: HttpClient,
        private api: ApiService,
    ) { }

    public async getKiosks(lat: any, lng: any, radius?: any): Promise<any> {
        try {
            radius = radius || 100;

            let url = 'Kiosks?';

            url += 'latitude=' + lat;
            url += '&longitude=' + lng;
            url += '&radius=' + radius;
            //: 'Kiosks?latitude=33.37612&longitude=-88.87027&radius=100'
            const response = await this.api.get({
                url,
                requireAuth: false,
            });

            return response;
            
        } catch (error) {
            throw error;
        }
    }

    public async geoCodeZip(zip: string): Promise<any> {
        try {
            
            const params = 'key=' + MapsApiKey + '&components=postal_code:' + zip + '|country:us';
            const response = await this.http.get(
                'https://maps.googleapis.com/maps/api/geocode/json?' + params
            ).toPromise();
            
            return response;

        } catch (error) {
            throw error;
        }
    }

    public async latLngToZip(lat: number|string, lng: number|string): Promise<any> {
        try {
            const params = 'key=' + MapsApiKey + '&latlng=' + lat + ',' + lng;
            const response = await this.http.get(
                'https://maps.googleapis.com/maps/api/geocode/json?' + params
            ).toPromise();
            
            return response;

        } catch (error) {
            throw error;
        }
    }
}
