import { AfterViewInit, Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators, UntypedFormBuilder } from '@angular/forms';

import { UserService } from '../../../services/user/user-service.service';
import { MessageReporter } from '../../../services/messages/message-reporter.service';

import { UserInterface } from '../../../interfaces/user.interface';
import { TranslateService } from '@ngx-translate/core';
import { UpdatePhoneNumberComponent } from './update-phone-number/update-phone-number.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-personal-info',
    templateUrl: './personal-info.component.html',
    styleUrls: ['./personal-info.component.scss']
})
export class PersonalInfoComponent implements OnInit, AfterViewInit {
    user: UserInterface;
    form: UntypedFormGroup;

    phoneNumberChanged: boolean;

    constructor(
        private fb: UntypedFormBuilder,
        private reporter: MessageReporter,
        private userService: UserService,
        private translate: TranslateService,
        private modal: NgbModal,
    ) { }

    async getPersonalInfo(): Promise<any> {
        try {
            this.user = await this.userService.getPersonalInfo();
            const email = sessionStorage.getItem('email');

            this.form.controls['email'].setValue(email);
            this.form.controls['firstName'].setValue(this.user.firstName);
            this.form.controls['lastName'].setValue(this.user.lastName);
            this.form.controls['phoneNumber'].setValue(this.user.phoneNumber);
            this.form.controls['zip'].setValue(this.user.zip);

        } catch (error) {
            console.log(error);
        }
    }

    async savePersonalInfo(): Promise<any> {
        if (this.phoneNumberChanged) {
            //ask for last 4 of verified biller account
            const modalRef = this.modal.open(UpdatePhoneNumberComponent, { size: 'md', centered: true, scrollable: true });
            modalRef.componentInstance.phoneNumber = this.user.phoneNumber;
            modalRef.componentInstance.lastName = this.user.lastName;
            modalRef.result.then((data) => {
                if (data) {
                    this.continueSavePersonalInfo();  
                }
            });
        } else {
            this.continueSavePersonalInfo();
        }
    }

    async continueSavePersonalInfo(): Promise<any> {
        try {
            const values = JSON.parse(JSON.stringify(this.form.value));
            //const pin = this.form.get('fastPassPIN');
            const user = JSON.parse(JSON.stringify(this.user));
            
            // Updates and Adds to the User object before sending
            user.email = values.email;
            user.firstName = values.firstName;
            user.lastName = values.lastName;
            user.phoneNumber = values.phoneNumber.replace(/-/g,'');
            user.zip = values.zip.toString();
            user.fastPassPIN = '0';
            user.aspNetUserId = this.userService.userId();

            // Deletes from the User object before sending
            delete user.listOfBillerAccountInfo;
            delete user.languageId;
            delete user.customerId;

            // Send the User object
            const response = await this.userService.savePersonalInfo(user);

            if ( response ) {
                this.reporter.showSuccessMessage('Profile was successfully saved');
                this.form.get('fastPassPIN').setValue('');
                this.getPersonalInfo();
            }

        } catch (error) {
            console.log(error);
        }
    }

    async sendVerificationCode(accountLastFour: string) {
        const body = {
            PhoneNumber: this.user.phoneNumber,
            LastName: this.user.lastName,
            AccountLastFour: accountLastFour
        };
        const response = await this.userService.submitRequestPINReset(body);

        this.reporter.showInfoMessage(this.translate.instant("pin-reset.code-sent"));
    }

    getFormValue(field: string) {
        return this.form.get(field).value;
    }

    updateFormField($event: any) {
        if ($event.name == 'phoneNumber') {
            this.phoneNumberChanged = true;
        }

        this.form.controls[$event.name] = $event;
        this.form.updateValueAndValidity();
    }

    ngAfterViewInit(): void {
        this.getPersonalInfo();
    }

    ngOnInit(): void {
        this.form = this.fb.group({
            email: new UntypedFormControl('', [Validators.required]),
            firstName: new UntypedFormControl(''),
            lastName: new UntypedFormControl('', [Validators.required]),
            phoneNumber: new UntypedFormControl('', [Validators.required]),
            zip: new UntypedFormControl('', [Validators.required]),
        });
    }
}