<div class="modal-header">
    <h4 class="modal-title">{{'update-phone-number.title' | translate}}</h4>
</div>
<div class="modal-body">
    <ngb-alert 
        type="danger" 
        *ngIf="hasError" 
        (closed)="closeAlert()"
    >
        <div [innerHtml]="errorText"></div>
    </ngb-alert>
    <div *ngIf="!codeSent">
        <p>{{'update-phone-number.explaination' | translate}}</p>
        <div class="form-group">
            <int-input 
                name="lastFour"
                required="true"
                [maxLength]="4"
                [label]="'global.account-last-four' | translate"
                [value]="getFormValue('lastFour')"
                (changeEvent)="updateFormField($event)"
            ></int-input>
        </div>
    </div>
    <div *ngIf="codeSent">
        <p>{{'update-phone-number.code-sent' | translate}}</p>
        <div class="form-group">
            <int-input 
                name="code"
                required="true"
                [label]="'update-phone-number.verification-code' | translate"
                [value]="getFormValue('code')"
                (changeEvent)="updateFormField($event)"
            ></int-input>
        </div>
    </div>
</div>
<div class="modal-footer">
    <div class="button-row">
        <button class="btn btn-normal" (click)="activeModal.dismiss('canceled')">{{'global.cancel' | translate}}</button>
        <button class="btn btn-primary" (click)="verifyAccount()">{{'update-phone-number.verify' | translate}}</button>
    </div>
</div>