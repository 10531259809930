import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';

import { UserService } from '../user/user-service.service';

import { 
    GetBillerPayTypesWithFees, 
    GetBillersList, 
    GetMyBillersList, 
    GetMyBillersBalance,
} from './billers.data';

export interface Params {
    [key: string]: any;
}

export interface GetOptions {
    url: string;
    params?: Params;
}

@Injectable({
    providedIn: 'root'
})
export class BillersService {

    constructor(
        private api: ApiService,
        private userService: UserService,
    ) { }

    userId(): string {
        return sessionStorage.getItem('userId');
    }

    public async getBillers(billerIds: string): Promise<any> {
        return await this.api.get({
            url: 'Billers/PreAuth/' + billerIds,
            requireAuth: false,
        });
    }

    public async getSavedBillerAccounts(): Promise<any> {
        return await this.api.get({
            url: 'PaymentMethods/' + this.userId(),
        });
    }

    public async getBillerAccounts(includeBillerInformation: boolean = false, includeBalances: boolean = false, serviceIds: string = '3,7'): Promise<any> {
        let addUrl = '';
        
        if ( includeBillerInformation && includeBillerInformation == true ) {
            addUrl += '&includeBillerInformation=true';
        }

        if ( includeBalances && includeBalances == true ) {
            addUrl += '&includeBalances=true';
        }

        addUrl += '&serviceIds=' + serviceIds;

        return await this.api.get({
            url: 'BillerAccounts?userId=' + this.userId() + addUrl
        });
    }

    public async getBillerAccount(billerId: string|number, accountNumber: string|number ): Promise<any> {
        let url = 'BillerAccounts/Lookup';
        url += '?biller=' + billerId + '&accountNumber=' + accountNumber;
        
        const sendObj = {
            requireAuth: false,
            url
        };

        return await this.api.get(sendObj);
    }

    public async getMyBillers(includeBillerInformation: boolean = true, includeBalances: boolean = false, serviceIds: string = '3,7'): Promise<any> {
        const userId = this.userId();
        let addUrl = '';

        if ( includeBillerInformation ) {
            addUrl += '&includeBillerInformation=true';
        }

        if ( includeBalances ) {
            addUrl += '&includeBalances=true';
        }

        addUrl += '&serviceIds=' + serviceIds;

        if ( !userId || userId === null ) {
            this.userService.logout();
        } else {
            const data = await this.api.get({
                url: 'BillerAccounts?userId=' + this.userId() + addUrl
            });
            
            const list = ( !data || !data.listOfBillerAccountInfo || data.listOfBillerAccountInfo === null) ? [] : data.listOfBillerAccountInfo;

            return list || [];
        }
    }

    public async getBillerInformation(billerId: number|string): Promise<any> {
        return await this.api.get({
            url: 'Billers/Information/' + billerId + '/3',
            requireAuth: false,
        });
    }

    public async filterMyBillers(biller: string): Promise<any> {
        return GetMyBillersList;
    }

    public async getMyBillerBalance(biller: any, accountNumber: string): Promise<any> {
        return GetMyBillersBalance;
    }

    public async getBillerPayTypesWithFees(biller: string): Promise<any> {
        return GetBillerPayTypesWithFees;
    }

    public async addNewBillerAccount(body: any): Promise<any> {
        const response = await this.api.post({
            url: 'BillerAccounts',
            body,
        });

        return response;
    }

    public async makePaymentWithSavedOption(body: any): Promise<any> {
        const response = await this.api.post({
            url: 'Payments/WithSavedOption',
            body,
        });

        return response;
    }

    public async makePayment(body: any): Promise<any> {
        const response = await this.api.post({
            url: 'Payments/PostPayment',
            body,
        });

        return response;
    }

    public async makeOneTimePayment(body: any): Promise<any> {
        const response = await this.api.post({
            url: 'Payments/OneTimePayment',
            body,
            requireAuth: false,
        });

        return response;
    }

    public async deleteBillerAccount(accountId: any, userId: any): Promise<any> {
        const response = await this.api.delete({
            url: 'BillerAccounts?accountId=' + accountId + '&userId=' + userId,
        });

        return response;
    }

    public async verifyMerchant(appleServerURL: string, billerName: string): Promise<any> {
        const body = { AppleServerURL: appleServerURL, BillerName: billerName };
        const response = await this.api.post({
            url: 'Payments/VerifyMerchant',
            body,
            requireAuth: false,
        });

        return JSON.parse(response);
    }
}