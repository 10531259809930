import { Component, ElementRef, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { BillersService } from '../../services/billers/billers.service';
import { IdleTimeoutService } from '../../services/idle-timeout/idle-timeout.service';

import { BillerAccountsInterface } from '../../interfaces/Biller.interface';

import { ShepherdService } from 'angular-shepherd';
import { builtInButtons, defaultSteps, defaultStepOptions } from './shepherdsData';

import { FastPassLinkComponent } from '../fast-pass-link/fast-pass-link.component';
import { UserInterface } from 'src/app/interfaces/user.interface';
import { UserService } from 'src/app/services/user/user-service.service';
import { MessageReporter } from 'src/app/services/messages/message-reporter.service';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, AfterViewInit, OnDestroy {
    user: UserInterface;

    accountLinked: boolean = false;
    billerAccounts: any;
    displayReceiptMethods: boolean = false;
    receiptMethod: string = '';

    receiptForm: UntypedFormGroup = new UntypedFormGroup({
        receiptMethod: new UntypedFormControl('print'),
        email: new UntypedFormControl('', [Validators.required]),
        phone: new UntypedFormControl('', [Validators.required]),
    });

    constructor(
        private billerService: BillersService,
        private elem: ElementRef,
        private idle: IdleTimeoutService,
        private modal: NgbModal,
        private userService: UserService,
        private reporter: MessageReporter,
        public shepherdService: ShepherdService,
    ) {
        builtInButtons.finish['action'] = () => {
            sessionStorage.setItem('previousLogin', 'true');
            this.hideReceiptMethods();
            this.shepherdService.complete();
        }
    }

    getFormValue(field: string) {
        return this.receiptForm.get(field).value;
    }

    updateFormField($event: any) {
        this.receiptForm.controls[$event.name] = $event;
        this.receiptForm.updateValueAndValidity();
    }

    showWalkThrough() {
        const previousLogin = sessionStorage.getItem('previousLogin');

        if ( !previousLogin ) {
            window.setTimeout(() => {
                this.shepherdService.defaultStepOptions = defaultStepOptions;
                this.shepherdService.modal = true;
                this.shepherdService.addSteps(defaultSteps);
                this.shepherdService.start();
            }, 600);
        }
    }

    hideReceiptMethods() {
        this.displayReceiptMethods = false;
    }

    selectReceiptMethod($event: any): void {
        this.receiptMethod = $event.target.value;
    }

    ngOnDestroy(): void {
        this.idle.destroy();
        
        if ( this.shepherdService && this.shepherdService.isActive ) {
            this.shepherdService.hide();
        }
    }

    openFastPassLink() {
        const modalRef = this.modal.open(FastPassLinkComponent, { size: 'lg', centered: true });
        modalRef.componentInstance.billerAccounts = this.billerAccounts;
        modalRef.componentInstance.parent = this;
        modalRef.componentInstance.name = 'FastPassLink';

        // modalRef.result.then(
        //     () => this.openSignIn(true),
        //     (error) => { return; }
        // );
    }

    async getBillerAccounts(): Promise<any> {
        try {
            const response = await this.billerService.getBillerAccounts();
            this.billerAccounts = response;

            return response;
        } catch (error) {
            console.log(error);
        }
    }

    async accountIsLinked(): Promise<any> {
        const billerAccounts: BillerAccountsInterface = await this.getBillerAccounts();
        return ( billerAccounts.customerId && billerAccounts.customerId !== '' && billerAccounts.customerId !== '0' );
    }

    async validateAccount(): Promise<any> {
        this.accountLinked = await this.accountIsLinked();

        if ( !this.accountLinked ) {
            this.openFastPassLink();
        }
    }

    async getPersonalInfo(): Promise<any> {
        try {
            this.user = await this.userService.getPersonalInfo();
            const email = sessionStorage.getItem('email');

            this.receiptForm.get('email').setValue(email);
            this.receiptForm.get('phone').setValue(this.user.phoneNumber);

            this.whatIsChecked();

        } catch (error) {
            console.log(error);
        }
    }

    whatIsChecked(): void {
        let value = 'print';
        if ( this.user.smsReceipt ) {
            value = 'text';
        } else if ( this.user.emailReceipt ) {
            value = 'email';
        }
        this.receiptForm.get('receiptMethod').setValue(value);
    }

    async savePersonalInfo(): Promise<any> {
        try {
            const values = this.receiptForm.value;
            const user = JSON.parse(JSON.stringify(this.user));
            const receiptMethod = this.receiptForm.get('receiptMethod').value;
            console.log(user);
            // Updates and Adds to the User object before sending
            user.smsReceipt = ( receiptMethod === 'text' );
            user.emailReceipt = ( receiptMethod === 'email' );

            user.aspNetUserId = this.userService.userId();

            user.email = values.email;
            user.phoneNumber = values.phone;
            user.fastPassPIN = user.fastPassPIN;
            user.zip = (!user.zip || user.zip === null) ? '' : user.zip.toString();

            // Deletes from the User object before sending
            delete user.listOfBillerAccountInfo;
            delete user.languageId;
            delete user.customerId;

            // Send the User object
            const response = await this.userService.savePersonalInfo(user);

            if ( response ) {
                this.reporter.showSuccessMessage('Receipt method was successfully saved');
                this.getPersonalInfo();
            }

        } catch (error) {
            console.log(error);
        }
    }

    ngAfterViewInit(): void {
        this.idle.start();

        if ( this.accountLinked ) {
            this.showWalkThrough();
        }
    }

    ngOnInit(): void {
        const previousLogin = sessionStorage.getItem('previousLogin');
        if ( !previousLogin ) {
            this.displayReceiptMethods = true;
        }
        
        this.getPersonalInfo();
        this.validateAccount();
    }

}
