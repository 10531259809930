<h2 class="thin">Change Password</h2>
<form>
    <div class="row">
        <div class="col-12">
            <div class="form-group">
                <password-input
                    name="oldPassword"
                    required="true"
                    [label]="'Old Password'"
                    [value]="getFormValue('oldPassword')"
                    (changeEvent)="updateFormField($event)"
                    ngbAutofocus
                ></password-input>
            </div>
            <div class="form-group">
                <password-input
                    name="newPassword"
                    required="true"
                    invalidMessage="12 characters long; 1 upper; 1 lower; 1 number; 1 special character"
                    [label]="'New Password'"
                    [validator]="validator"
                    [value]="getFormValue('newPassword')"
                    (changeEvent)="updateFormField($event)"
                ></password-input>
            </div>
            <div class="form-group">
                <password-input
                    name="confirmPassword" 
                    required="true"
                    [label]="'Confirm New Password'"
                    [value]="getFormValue('confirmPassword')"
                    [validator]="confirmValidator"
                    (changeEvent)="updateFormField($event)"
                ></password-input>
            </div>
        </div>
    </div>
    <div class="d-sm-block d-md-none col-12 requiredLabel">
        <div class="notice">* {{'global.required' | translate}}</div>
    </div>
    <div class="button-row">
        <div class="row">
            <div class="d-none d-md-block col-6 align-left align-self-center">
                <div class="notice">* {{'global.required' | translate}}</div>
            </div>
            <div class="d-none d-md-block col-6 align-right">
                <button class="btn btn-primary" [disabled]="form.invalid" (click)="changePassword()">{{'global.change-password' | translate}}</button>
            </div>
            <div class="d-sm-block d-md-none col-12 align-enter">
                <button class="btn btn-primary btn-block" [disabled]="form.invalid" (click)="changePassword()">{{'global.change-password' | translate}}</button>
            </div>
        </div>
    </div>
</form>