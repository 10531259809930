import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-form-fields',
  templateUrl: './form-fields.component.html',
  styleUrls: ['./form-fields.component.scss']
})
export class FormFieldsComponent implements OnInit {

  constructor() { }

  email = new UntypedFormControl('email@domain.com', [
    Validators.required, 
    // Validators.email,
    Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")
  ]);

  updateFormField($event: any) {
      this[$event.name] = $event;
  }

  emailChange() {
    console.log(this.email.value);
  }

  ngOnInit(): void {
  }

}
