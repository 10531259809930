import { Component, OnInit } from '@angular/core';
import { DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'app-contest',
  templateUrl: './contest.component.html',
  styleUrls: ['./contest.component.scss']
})
export class ContestComponent implements OnInit {
  public url;
  
  constructor(private domSanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.url = this.domSanitizer.bypassSecurityTrustResourceUrl('https://www.paysitekiosks.com/2022-Giveaway');
  }

}
