import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-spanish',
  templateUrl: './spanish.component.html',
  styleUrls: ['./spanish.component.scss']
})
export class SpanishComponent implements OnInit {
  public url;
  
  constructor(private domSanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.url = this.domSanitizer.bypassSecurityTrustResourceUrl('https://www.paysitekiosks.com/2022-Giveaway-Spanish');
  }
}
