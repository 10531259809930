<div class="container">
    <form [formGroup]="form">
        <div class="form-group">
            <input type="text" class="form-control" formControlName="token" />
        </div>
        <div class="form-group" id="resetLinkHolder"></div>
        <div class="button-row">
            <button class="btn btn-primary" (click)="goToURL()">{{'global.go' | translate}}</button>
        </div>
    </form>
</div>
