import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';

@Component({
    selector: 'select-input',
    templateUrl: './select-input.component.html',
    styleUrls: ['./select-input.component.scss']
})
export class SelectInputComponent implements OnInit, OnChanges {

    @Output() changeEvent = new EventEmitter<any>();
    @Input() invalidMessage: string;
    @Input() name: string;
    @Input() label: string;
    @Input() emptyLabel: string;
    @Input() value: any = '';
    @Input() items: any = [];
    @Input() tabindex: number = -1;

    form: UntypedFormGroup = new UntypedFormGroup({
        formInput: new UntypedFormControl(''),
    });

    constructor() { }

    ngOnChanges(changes: SimpleChanges) {
        if ( changes.value ) {
            const formInput = this.form.controls['formInput'];
            formInput.setValue(changes.value.currentValue);
            formInput.updateValueAndValidity();
        }
    }

    ngOnInit(): void {
        this.form.controls['formInput'].setValue(this.value);
    }

    onChange() {
        const formInput = this.form.get('formInput');
        formInput['name'] = this.name;
        this.changeEvent.emit(formInput);
    }
}