import { AfterViewInit, Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[ngbAutofocus]'
})
export class AutoFocusDirective implements AfterViewInit {

  constructor(private el: ElementRef) {
  }
 
  ngAfterViewInit() {
    setTimeout(() => { this.el.nativeElement.querySelector('input').focus(); }, 100);
  }
}