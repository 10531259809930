<div id="home-wrapper">
    <div class="container">
        <div class="row announcement-banner">
            <div class="announcement-content-left">
                <img src="assets/images/Download-On-The-App-Store.png" class="announcement-download-app-image" /><br />
                <img src="assets/images/Get-It-On-Google-Play.png" class="announcement-download-app-image" />
            </div>
            <div class="announcement-content-center">
                <div class="announcement-header">
                    <span>{{'home.download-the-paysite-app' | translate}}</span>
                </div>
                <div class="announcement-text">
                    <span>{{'home.paysite-app-info-1' | translate}}</span><br />
                    <span>{{'home.paysite-app-info-2' | translate}}</span>
                </div>    
            </div>
            <div class="announcement-content-right">
                <img class="announcement-image" src="assets/images/app_store_qr.png" />
            </div>
        </div>
        <div class="row">
            <div id="page-title" class="col-sm-7 col-md-7 col-lg-8">
                <h1>{{'home.page-title' | translate}}</h1>
            </div>
            <div id="paySiteLogo" class="col-sm-5 col-md-5 col-lg-4 align-center">
                <img src="assets/images/logo-lrg.png" />
            </div>
        </div>
        
        <div class="mb-2 d-block d-md-none">
            <div class="mobileSubHeader mb-3">
                <span >{{'home.mobileSubHeader' | translate}}</span>
            </div>
            <div class="row mb-2">
                <div class="col-2">
                    <fa-icon class="paySiteUserIcon" [icon]="['fas', 'user']"></fa-icon>
                </div>
                <div class="col-10">
                    <span class="paySiteInfo">{{'home.paySiteInfo' | translate}}</span>
                </div>
            </div>
            <div class="row mb-2">
                <div class="col-2">
                    <img class="paySiteIconImage" src="assets/images/card-icon.png" />
                </div>
                <div class="col-10">
                    <span class="paySiteInfo">{{'home.paySiteInfo2' | translate}}</span>
                </div>
            </div>
            <div class="row">
                <div class="col-2">
                    <img class="paySiteIconImage" src="assets/images/barcode-icon.png" />
                </div>
                <div class="col-10">
                    <span class="paySiteInfo">{{'home.paySiteInfo3' | translate}}</span>
                </div>
            </div>
        </div>
        <div class="row d-sm-none d-md-block">
            <div class="col-md-12 align-center">
                <div class="row">
                    <div class="col-md-4 col-lg-2 p-3">
                        <button class="btn btn-lg btn-outline-primary w-100" (click)="openSignIn()">{{'global.sign-in' | translate}}</button>
                    </div>
                    <div class="col-md-4 col-lg-2 p-3">
                        <button class="btn btn-lg btn-outline-primary w-100" (click)="openSignUp()">{{'global.sign-up' | translate}}</button>
                    </div>
                    <div class="col-md-4 col-lg-2 p-3">
                        <a routerLink="/onetimepayment" routerLinkActive="active"><button class="btn btn-lg btn-outline-primary w-100">{{'global.one-time-pay' | translate}}</button></a>
                    </div>
                    <div class="col-md-4 col-lg-4 p-3">
                        <button class="btn btn-lg btn-outline-primary w-100" (click)="openBillerList()">{{'global.search-billers' | translate}}</button>
                    </div>
                    <div class="col-md-4 col-lg-2 p-3">
                        <a routerLink="/pin-reset-request" routerLinkActive="active"><button class="btn btn-lg btn-outline-primary w-100">{{'pin-reset.reset-pin' | translate}}</button></a>
                    </div>
                    <div class="col-md-4 col-lg-2 p-3">
                        <button class="btn btn-lg btn-outline-primary locateKioskBtn" style="width: 100%;" (click)="locateKiosks()">{{'global.find-kiosk' | translate}}</button>
                    </div>
                </div>
            </div>
        </div>
        <div>
            <div class="section-wrapper-row">
                <div class="section-wrapper d-none d-md-table-cell">
                    <div class="bottom-left-column">
                        <div class="mobileSubHeader mb-2">
                            <span >{{'home.mobileSubHeader' | translate}}</span>
                        </div>
                        <div class="row mb-2">
                            <div class="col-2">
                                <fa-icon class="paySiteUserIcon" [icon]="['fas', 'user']"></fa-icon>
                            </div>
                            <div class="col-10">
                                <span class="paySiteInfo">{{'home.paySiteInfo' | translate}}</span>
                            </div>
                        </div>
                        <div class="row mb-2">
                            <div class="col-2">
                                <img class="paySiteIconImage" src="assets/images/card-icon.png" />
                            </div>
                            <div class="col-10">
                                <span class="paySiteInfo">{{'home.paySiteInfo2' | translate}}</span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-2">
                                <img class="paySiteIconImage" src="assets/images/barcode-icon.png" />
                            </div>
                            <div class="col-10">
                                <span class="paySiteInfo">{{'home.paySiteInfo3' | translate}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="clearfix"></div>
                </div>
                <div class="d-none d-sm-table-cell d-md-none">
                    <button class="btn btn-lg btn-outline-primary btnFormatMed" (click)="openSignIn()">{{'global.sign-in' | translate}}</button>
                    <button class="btn btn-lg btn-outline-primary btnFormatMed" (click)="openSignUp()">{{'global.sign-up' | translate}}</button>
                    <a routerLink="/onetimepayment" routerLinkActive="active">
                        <button class="btn btn-lg btn-outline-primary btnFormatMed">{{'global.one-time-pay' | translate}}</button>
                    </a>
                    <button class="btn btn-lg btn-outline-primary btnFormatMed" (click)="locateKiosks()">{{'global.find-kiosk' | translate}}</button>
                    <button class="btn btn-lg btn-outline-primary btnFormatMed" (click)="openBillerList()">{{'global.search-billers' | translate}}</button>
                    <a routerLink="/pin-reset-request" routerLinkActive="active">
                        <button class="btn btn-lg btn-outline-primary btnFormatMed">{{'pin-reset.reset-pin' | translate}}</button>
                    </a>
                </div>
            </div>
            <div class="section-wrapper-row section-wrapper-row-bottom">
                <div class="section-wrapper d-none d-sm-table-cell">
                    <div class="bottom-left-column">
                        <form [formGroup]="form">
                            <div class="kiosk-zip-input-group">
                                <label>{{'global.find-kiosk' | translate}}</label>
                                <button class="btn btn-secondary" (click)="locateKiosks()">{{'global.go' | translate}}</button>
                                <input type="text" placeholder="{{'global.postal-code' | translate}}" formControlName="zip" />
                            </div>
                        </form>
                        <div class="kiosk-image">
                            <img src="assets/images/Touch 2020.png" />
                        </div>
                        <div class="clearfix"></div>
                    </div>
                    <div class="clearfix"></div>
                </div>
                <div class="section-wrapper-spacer d-none d-sm-table-cell">&nbsp;</div>
                <div class="section-wrapper d-none d-sm-table-cell">
                    <div class="bottom-right-column">
                        <div class="text-image-group">
                            <label>{{'home.download-the-paysite-app' | translate}}</label>
                            <a href="https://apps.apple.com/us/app/paysite/id1568523209">
                                <img src="assets/images/Download-On-The-App-Store.png" class="download-app-image" />
                            </a><br />
                            <a href="https://play.google.com/store/apps/details?id=com.uspayments.paysite.dev">
                                <img src="assets/images/Get-It-On-Google-Play.png" class="download-app-image" />
                            </a>
                        </div>
                        <div class="phone-image">
                            <img src="assets/images/phone-image.png" />
                        </div>
                        <div class="clearfix"></div>
                    </div>
                    <div class="clearfix"></div>
                </div>
            </div>
        </div>
    </div>
</div>