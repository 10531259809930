import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';

import { AppRoutingModule } from './app-routing.module';

import { NgxMaskModule } from 'ngx-mask'
import { ShepherdService } from 'angular-shepherd';
import { NgxBarcode6Module } from 'ngx-barcode6';
import { GoogleMapsModule } from '@angular/google-maps';

import { HttpRequestInterceptor } from './services/api/HttpRequestInterceptor';

/* Pages */
import { AlertComponent } from './pages/styleguide/alert/alert.component';
import { AppComponent } from './app.component';
import { ButtonsComponent } from './pages/styleguide/buttons/buttons.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { FormFieldsComponent } from './pages/styleguide/form-fields/form-fields.component';
import { HomeComponent } from './pages/home/home.component';
import { ModalComponent } from './pages/styleguide/modal/modal.component';
import { SignInComponent } from './pages/sign-in/sign-in.component';
import { SignUpComponent } from './pages/sign-up/sign-up.component';
import { SignUpSimpleComponent } from './pages/sign-up-simple/sign-up-simple.component';
import { StyleGuideComponent } from './pages/styleguide/styleguide.component';
import { TablesComponent } from './pages/styleguide/tables/tables.component';
import { OneTimePaymentComponent } from './pages/one-time-payment/one-time-payment.component';

/* Widgets */
import { CurrencyInputComponent } from './widgets/currency-input/currency-input.component';
import { EmailInputComponent } from './widgets/email-input/email-input.component';
import { IntInputComponent } from './widgets/int-input/int-input.component';
import { NavbarComponent } from './widgets/navbar/navbar.component';
import { PasswordInputComponent } from './widgets/password-input/password-input.component';
import { PhoneInputComponent } from './widgets/phone-input/phone-input.component';
import { SidenavComponent } from './widgets/sidenav/sidenav.component';
import { TextInputComponent } from './widgets/text-input/text-input.component';
import { FormInputComponent } from './widgets/form-input/form-input.component';
import { SelectInputComponent } from './widgets/select-input/select-input.component';

/* Directives */
import { RibbonComponent } from './directives/ribbon/ribbon.component';
import { PaperComponent } from './directives/paper/paper.component';
import { PayHistoryComponent } from './widgets/pay-history/pay-history.component';
import { MyBillersComponent } from './widgets/my-billers/my-billers.component';

import { TimeoutModalComponent } from './widgets/timeout-modal/timeout-modal.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { PersonalInfoComponent } from './pages/profile/personal-info/personal-info.component';
import { PINComponent } from './pages/profile/pin/pin.component';
import { PasswordComponent } from './pages/profile/password/password.component';
import { ReceiptMethodComponent } from './pages/profile/receipt-method/receipt-method.component';
import { PaymentMethodsComponent } from './pages/profile/payment-methods/payment-methods.component';
import { AddNewPayMethodComponent } from './pages/profile/payment-methods/add-new-pay-method/add-new-pay-method.component';
import { DeletePayMethodComponent } from './pages/profile/payment-methods/delete-pay-method/delete-pay-method.component';
import { BillerAddComponent } from './pages/biller-add/biller-add.component';
import { BillerPaymentComponent } from './pages/biller-payment/biller-payment.component';
import { FAQSComponent } from './pages/faqs/faqs.component';
import { ContactUsComponent } from './pages/contact-us/contact-us.component';
import { FooterComponent } from './pages/footer/footer.component';
import { TermsConditionsComponent } from './pages/terms-conditions/terms-conditions.component';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';
import { WheresRoutingNumberComponent } from './pages/wheres-routing-number/wheres-routing-number.component';
import { PasswordResetComponent } from './pages/password-reset/password-reset.component';
import { ResetLinkComponent } from './pages/reset-link/reset-link.component';
import { PaymentReceiptComponent } from './widgets/pay-history/payment-receipt/payment-receipt.component';
import { SpinnerComponent } from './widgets/spinner/spinner.component';
import { RollerComponent } from './widgets/roller/roller.component';
import { BarcodeComponent } from './widgets/barcode/barcode.component';
import { BillerSearchComponent } from './pages/biller-search/biller-search.component';
import { KioskLocatorComponent } from './pages/kiosk-locator/kiosk-locator.component';
import { FastPassLinkComponent } from './pages/fast-pass-link/fast-pass-link.component';
import { UpgradeLegacyAccountComponent } from './pages/upgrade-legacy-account/upgrade-legacy-account.component';
import { UpgradeModalComponent } from './pages/sign-in/upgrade-modal/upgrade-modal.component';
import { AutoFocusDirective } from './directives/auto-focus/auto-focus.directive';
import { ContestComponent } from './pages/contest/contest.component';
import { SpanishComponent } from './pages/contest/spanish/spanish.component';
import { BillerDeleteComponent } from './pages/biller-delete/biller-delete.component';
import { PINResetRequestComponent } from './pages/pin-reset/pin-reset-request.component';
import { PINResetComponent } from './pages/pin-reset/pin-reset.component';
import { UpdatePhoneNumberComponent } from './pages/profile/personal-info/update-phone-number/update-phone-number.component';

@NgModule({
  declarations: [
    AlertComponent,
    AppComponent,
    ButtonsComponent,
    CurrencyInputComponent,
    DashboardComponent,
    EmailInputComponent,
    FormFieldsComponent,
    HomeComponent,
    IntInputComponent,
    ModalComponent,
    NavbarComponent,
    OneTimePaymentComponent,
    PaperComponent,
    PasswordInputComponent,
    PhoneInputComponent,
    RibbonComponent,
    SidenavComponent,
    SignUpComponent,
    StyleGuideComponent,
    TablesComponent,
    TextInputComponent,
    SignInComponent,
    ForgotPasswordComponent,
    SignUpSimpleComponent,
    FormInputComponent,
    PayHistoryComponent,
    MyBillersComponent,
    TimeoutModalComponent,
    ProfileComponent,
    PersonalInfoComponent,
    PINComponent,
    PasswordComponent,
    ReceiptMethodComponent,
    PaymentMethodsComponent,
    AddNewPayMethodComponent,
    DeletePayMethodComponent,
    BillerAddComponent,
    BillerPaymentComponent,
    FAQSComponent,
    ContactUsComponent,
    FooterComponent,
    TermsConditionsComponent,
    PrivacyPolicyComponent,
    WheresRoutingNumberComponent,
    PasswordResetComponent,
    ResetLinkComponent,
    PaymentReceiptComponent,
    SpinnerComponent,
    RollerComponent,
    BarcodeComponent,
    BillerSearchComponent,
    KioskLocatorComponent,
    FastPassLinkComponent,
    UpgradeLegacyAccountComponent,
    UpgradeModalComponent,
    AutoFocusDirective,
    ContestComponent,
    SpanishComponent,
    BillerDeleteComponent,
    PINResetRequestComponent,
    PINResetComponent,
    SelectInputComponent,
    UpdatePhoneNumberComponent,
  ],
  imports: [
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    FontAwesomeModule,
    FormsModule,
    GoogleMapsModule,
    HttpClientModule,
    NgbModule,
    NgxBarcode6Module,
    NgxMaskModule.forRoot(),
    ReactiveFormsModule,
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      }
  })
  ],
  providers: [
    PaperComponent,
    RibbonComponent,
    ShepherdService,
    { provide: HTTP_INTERCEPTORS, useClass: HttpRequestInterceptor, multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(library: FaIconLibrary) {
    library.addIconPacks(fas, far, fab);
  }
}
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}
