import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { BillersService } from '../../services/billers/billers.service';
import { BillerInterface } from '../../interfaces/Biller.interface';

@Component({
    selector: 'app-biller-search',
    templateUrl: './biller-search.component.html',
    styleUrls: ['./biller-search.component.scss']
})
export class BillerSearchComponent implements OnInit {

    @Input() parent: any;
    @Input() name: string;

    completeBillers: BillerInterface[] = [];
    billers: BillerInterface[] = [];
    gettingBillers: boolean = true;
    searchTermPresent: boolean;
    timeout: any;

    constructor(
        public activeModal: NgbActiveModal,
        public billersService: BillersService
    ) { }

    async getBillers(): Promise<any> {
        try {
            
            const response = await this.billersService.getBillers('3');

            this.completeBillers = response;
            this.billers = JSON.parse(JSON.stringify(this.completeBillers));
            this.gettingBillers = false;

        } catch (error) {
            console.log(error);
            this.gettingBillers = false;
        }
    }

    searchBillers($event: any): void {
        const target = $event.target;
        const value = target.value;

        this.searchTermPresent = !!value;

        window.clearTimeout(this.timeout);
        
        this.timeout = window.setTimeout(() => {
            const list = JSON.parse(JSON.stringify(this.completeBillers));
            const searched = list.filter((row: any) => {
                const billerName = row.billerName.toLowerCase();
                const val = value.toLowerCase();
                return (billerName.indexOf(val) > -1);
            });

            this.billers = searched;
        }, 250);
        
    }

    openSignUp(): void {
        this.activeModal.close();
        this.parent.openSignUp();
    }

    ngOnInit(): void {
        this.getBillers();
    }

}
