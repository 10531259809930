import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { IdleTimeoutService } from '../../services/idle-timeout/idle-timeout.service';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalConstants } from 'src/app/common/global-constants';

@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit, AfterViewInit, OnDestroy {
    acceptedPaymentTypes: any[] = [
        { id: 3, name: 'ACH-Checking' },
        { id: 4, name: 'ACH-Savings' },
        { id: 5, name: 'Credit' },
        { id: 6, name: 'Debit' }
        // { id: 12, name: 'Apple Pay' } //don't think we save wallet payment types
    ];
    selectedContent: number = 0;
    showApplePay: boolean = false;

    constructor(
        private idle: IdleTimeoutService,
        private route: ActivatedRoute,
    ) { }
    
    whichChild(elem: any){
        var  i= 0;
        while( (elem = elem.previousSibling) != null ) ++i;
        return i;
    }

    selectContent(content: any): void {
        if (typeof content === 'number') {
            this.selectedContent = content;
        }
    }

    ngOnDestroy(): void {
        this.idle.destroy();
    }

    ngAfterViewInit(): void {
        this.idle.start();
    }

    ngOnInit(): void {
        this.route.queryParams.subscribe(params => {
            if (params.tab) {
                const id = parseInt(params.tab);
                if (!isNaN(id))
                    this.selectContent(id);
            }
        });

        const types: any[] = [];
        if (window.PaymentRequest && (window as any).ApplePaySession && (window as any).ApplePaySession.canMakePayments()) {
            const applePay: any = types.filter((pt: any) => {
                return (pt == GlobalConstants.payType.ApplePay);
            });

            this.showApplePay = applePay && applePay.length > 0;
        }
    }

}
