<footer id="footer">
    <div class="container">
        <div class="content">
            <ul>
                <a routerLink="/" routerLinkActive="active" *ngIf="currentUrl !== '/'"><li class="flink">{{'footer.home' | translate}}</li></a>
                <a routerLink="/contact-us" routerLinkActive="active"><li class="flink">{{'global.contact-us' | translate}}</li></a>
                <a routerLink="/faqs" routerLinkActive="active"><li class="flink">{{'footer.faqs' | translate}}</li></a>
                <a routerLink="/termsconditions" routerLinkActive="active"><li class="flink">{{'footer.terms-and-conditions' | translate}}</li></a>
                <a routerLink="/privacypolicy" routerLinkActive="active"><li class="flink">{{'footer.privacy-policy' | translate}}</li></a>
                <a href="https://www.uspayments.com" target="_new"><li class="flink">{{'footer.us-payments' | translate}}</li></a>
                <li>{{'footer.copyright' | translate}} &copy; {{fullYear}}</li>
            </ul>
        </div>
    </div>
</footer>