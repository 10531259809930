import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MessageReporter } from '../../services/messages/message-reporter.service';
import { KioskService } from '../../services/kiosk/kiosk-service.service';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { MapInfoWindow, MapMarker } from '@angular/google-maps';
import { AppService } from 'src/app/services/app/app.service';

// just an interface for type safety.
interface marker {
    address1?: string;
    address2?: string;
    city?: string;
    distance?: string|number;
    draggable?: boolean;
    info?: string;
    label?: any;
    lat: number;
    lng: number;
    locationName?: string;
    numberOfKiosks?: number | string;
    options?: any;
    position?: google.maps.LatLngLiteral;
    state?: string;
    title?: string;
    zip?: number | string;
}

@Component({
    selector: 'app-kiosk-locator',
    templateUrl: './kiosk-locator.component.html',
    styleUrls: [
        './kiosk-locator.component.scss'
    ]
})
export class KioskLocatorComponent implements OnInit, AfterViewInit {
    @ViewChild('mapSmall') mapSmall: google.maps.Map;
    @ViewChild('mapLarge') mapLarge: google.maps.Map;
    
    address: any;
    currentPos: google.maps.LatLngLiteral;
    lat: any;
    lng: any;
    markers: marker[] = [];
    timeout: any;
    dragTimeout: any;
    zip: any;
    isMobile: boolean;
    options: google.maps.MapOptions = {
        zoomControl: true,
        scrollwheel: false,
        disableDoubleClickZoom: true,
        mapTypeId: 'roadmap',
        mapTypeControl: false,
        fullscreenControl: false,
        zoom: 8
    }
    circleOptions: google.maps.CircleOptions = {
        editable: false,
        strokeWeight: 0,
        fillColor: 'grey',
        fillOpacity: 0.35,
    }

    form: UntypedFormGroup = new UntypedFormGroup({
        radius: new UntypedFormControl('50'),
        zip: new UntypedFormControl(''),
    });

    constructor(
        private kioskService: KioskService,
        private reporter: MessageReporter,
        private route: ActivatedRoute,
        private appService: AppService,
    ) {}

    getFormValue(field: string): string|number {
        return this.form.get(field).value;
    }

    updateFormField($event: any): void {
        this.form.controls[$event.name] = $event;
        this.form.updateValueAndValidity();
        
        if ( $event.name === 'zip' ) {
            const value = this.form.get('zip').value;

            if ( value.length < 5 ) {
                return;
            }
        }

        window.clearTimeout(this.timeout);

        this.timeout = window.setTimeout(() => {
            this.centerMap();
        }, 600);
    }

    getDirections(marker: any): void {
        let lat: any;
        let lng: any;
        let locationURL = '';

        if ( this.currentPos && this.currentPos.lat && this.currentPos.lng ) {
            lat = this.currentPos.lat;
            lng = this.currentPos.lng;

            locationURL = '&origin=' + lat + ',' + lng;
        }
        
        const _and = (locationURL !== '' ) ? '&' : '';
        const destinationURL = '?api=1&destination=' + marker.address1 + ', ' + marker.city + ', ' + marker.state + ' ' + marker.zip
        
        window.open(
            "https://google.com/maps/dir/" + destinationURL,
            "_blank"
        )
    }

    distanceTo(marker: any): string|number {
        const distance = Math.round(Number(marker.distance));
        return distance;
    }

    centerChanged(props: any) {
        const self = this;
        window.clearTimeout(this.dragTimeout);

        this.dragTimeout = window.setTimeout(function() {
            self.latLngToZip(props.lat, props.lng);

        }, 300);
    }

    async latLngToZip(lat: string|number, lng: string|number): Promise<any> {
        try {
            this.lat = lat;
            this.lng = lng;
            
            const response: any = await this.kioskService.latLngToZip(this.lat, this.lng);
            const { results } = response;

            if ( results.length > 0 ) {
                const result = results[0];
                const { address_components } = result;

                const zip = this.getZipFromAddressComponents(address_components);

                if ( zip ) {
                    this.form.get('zip').setValue(zip);
                }
            }
            
        } catch (error) {
            console.log(error);
        }
    }

    getZipFromAddressComponents(components: any): string|number {
        let zip: string|number;

        components.map((row: any) => {
            const types = row.types;
            
            if ( types.length > 0 && types[0] === 'postal_code' ) {
                zip = row.long_name;
            }

        });
        
        return zip;
    }

    async geoCodeZip(zip: string): Promise<any> {
        let latitude: number;
        let longitude: number;
        
        const response = await this.kioskService.geoCodeZip(zip);
        
        if ( response.status === "OK" ) {
            const results = response.results[0];

            latitude = results.geometry.location.lat;
            longitude = results.geometry.location.lng;

        } else {
            this.reporter.showErrorMessage('There was a problem geocoding the entered Postal Code. Ensure it is correct and try again.');
        }

        return [latitude, longitude];
    }

    async getKiosks(lat: number|string, lng: number|string): Promise<any> {
        try {
            const radius = this.form.get('radius').value;
            const response: any = await this.kioskService.getKiosks(lat, lng, radius);
            const markers: marker[] = [];

            response.map((row: any, index: any) => {
                markers.push({
                    address1: row.address1,
                    address2: row.address2,
                    city: row.city,
                    distance: row.distance,
                    lat: row.latitude,
                    lng: row.longitude,
                    locationName: row.locationName,
                    numberOfKiosks: row.numberOfKiosks,
                    state: row.state,
                    zip: row.zip,
                    position: {
                        lat: Number(row.latitude),
                        lng: Number(row.longitude),
                    },
                    label: {
                        text: row.locationName
                    },
                    title: row.locationName,
                });
            });

            markers.sort((a, b) => 
                a.distance < b.distance ? -1 : 1
            );

            this.markers = markers;

        } catch (error) {
            console.log(error);
        }
    }

    getRadius(): number {
        const radius: number = Number(this.form.get('radius').value)/0.00062137;
        return radius;
    }

    async centerMap(): Promise<any> {
        const geoCode = await this.geoCodeZip(this.form.get('zip').value);
        
        if ( geoCode.length > 0 && geoCode[0] && geoCode[1] ) {
            this.lat = parseFloat(geoCode[0]);
            this.lng = parseFloat(geoCode[1]);

            this.currentPos = {
                lat: this.lat,
                lng: this.lng,
            };

            this.getKiosks(this.lat, this.lng);
        }
    }

    async getQueryParams(): Promise<any> {
        let zip: any;
        this.route.queryParams.subscribe((params: any) => {
            zip = params['zip'];
        });

        return zip;
    }

    async getCurrentPos(): Promise<any> {
        let currentPos: any;
        if (navigator.geolocation) {
            return navigator.geolocation.getCurrentPosition(
                (position) => {
                    this.currentPos = {
                        lat: position.coords.latitude,
                        lng: position.coords.longitude,
                    };
                    
                    return this.currentPos;
                }
            );
        }

    }

    async initMap(): Promise<any> {
        const { queryParams } = this.route.snapshot;
        
        if ( queryParams && queryParams.zip ) {
            this.zip = await this.getQueryParams();
            this.form.get('zip').setValue(this.zip);

        } else if ( this.currentPos && this.currentPos.lat && this.currentPos.lng) {
            this.latLngToZip(this.currentPos.lat, this.currentPos.lng);

        } else if ( !this.currentPos) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    this.currentPos = {
                        lat: position.coords.latitude,
                        lng: position.coords.longitude,
                    };
                    this.latLngToZip(this.currentPos.lat, this.currentPos.lng);
                }
            );
        }
    }

    openInfo(marker: MapMarker, infoWindow: MapInfoWindow) {
        infoWindow.open(marker);
    }

    ngAfterViewInit(): void {
        this.isMobile = this.route.snapshot.queryParamMap.get('mobile') != null;
        navigator.geolocation.getCurrentPosition(
            (position) => {
                this.currentPos = {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude,
                };
            }
        );
        this.initMap();
    }

    ngOnInit(): void {
        this.appService.setWindowSize();
    }

}
