import { Component, OnInit } from '@angular/core';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-wheres-routing-number',
  templateUrl: './wheres-routing-number.component.html',
  styleUrls: ['./wheres-routing-number.component.scss']
})
export class WheresRoutingNumberComponent implements OnInit {

  constructor(
    public activeModal: NgbActiveModal,
  ) { }

  ngOnInit(): void {
  }

}
