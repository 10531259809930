import { 
    AfterViewInit, 
    Component, 
    ElementRef, 
    EventEmitter, 
    Input, 
    OnChanges, 
    OnInit, 
    Output, 
    SimpleChanges,
    ViewChild, 
} from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators, AbstractControl } from '@angular/forms';
import { faAt } from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'email-input',
    templateUrl: './email-input.component.html',
    styleUrls: ['./email-input.component.scss']
})
export class EmailInputComponent implements OnInit, AfterViewInit, OnChanges {
    faAt = faAt;
    @ViewChild('inputRef') inputRef: ElementRef;

    @Output() changeEvent = new EventEmitter<any>();

    @Input() disabled: boolean;
    @Input() invalidMessage: string;
    @Input() name: string;
    @Input() required: boolean;
    @Input() requiredMessage: string;
    @Input() label: string;
    @Input() value: any = '';
    @Input() tabindex: string;

    form: UntypedFormGroup = new UntypedFormGroup({
        formInput: new UntypedFormControl(''),
    });

    constructor() { }
        
    ngOnChanges(changes: SimpleChanges) {
        if ( changes.value ) {
            const formInput = this.form.controls['formInput'];
            formInput.setValue(changes.value.currentValue);
            formInput.updateValueAndValidity();
        }
    }

    isRequired(): boolean {
        const formInput = this.form.get('formInput');
        const validator = formInput.validator({} as AbstractControl);
        return (validator && validator.required);
    }
        
    inputErrorMessage() {
        const formInput = this.form.get('formInput');
        if (this.isRequired() && formInput.value === '' && (formInput.dirty || formInput.touched)) {
            return this.requiredMessageOutput();
        }

        return !formInput.valid ? this.invalidMessageOutput() : '';
    }

    invalidMessageOutput() {
        const label = this.label.replace('*', '');
        const text = `Not a valid ${label}`;
        const message = this.invalidMessage || text;

        return message;
    }

    isDisabled(): boolean {
        if ( !this.disabled ) {
            return false;
        } else {
            return true;
        }
    }

    isDirtyandInvalid() {
        const formInput = this.form.get('formInput');
        return ( formInput.invalid && ( formInput.dirty || formInput.touched ) );
    }

    onChange() {
        const formInput = this.form.get('formInput');
        formInput['name'] = this.name;
        this.changeEvent.emit(formInput);
    }

    requiredMessageOutput() {
        const label = this.label.replace('*', '');
        const text = `${label} is a required field`;
        const message = this.requiredMessage || text;

        return message;
    }

    ngAfterViewInit(): void {
        if ( this.disabled ) {
            this.inputRef.nativeElement.disabled = true;
        }
    }

    ngOnInit(): void {
        this.form.controls['formInput'].setValue(this.value);
        const validators = [];
        //     Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")
        // ];

        if (this.required) {
            validators.push(Validators.required);
        }

        this.form.controls['formInput'].setValidators(validators);
    }

}
