import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageReporter } from 'src/app/services/messages/message-reporter.service';
import { UserService } from 'src/app/services/user/user-service.service';
import { billerImageUrl } from 'src/config';

@Component({
  selector: 'app-upgrade-legacy-account',
  templateUrl: './upgrade-legacy-account.component.html',
  styleUrls: ['./upgrade-legacy-account.component.scss']
})
export class UpgradeLegacyAccountComponent implements OnInit {

    form: UntypedFormGroup = new UntypedFormGroup({});
    validator = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{6,})/;
    nameValidator = /^[a-zA-Z]+$/;
    loginError: boolean = false;
    mismatchError: boolean = false;
    loginErrorText: string = 'There was a problem matching your account on the legacy system. Please ensure all info is correct and try again.';
    invalidPasswordError: string = 'Password must contain:<br /> - at least 12 characters<br /> - 1 uppercase character<br /> - 1 lowercase character<br /> - 1 special characte<br /> - 1 number';
    billerImageUrl: string = billerImageUrl;

    isSearch: Boolean = true;
    isEnterNewCredentials: Boolean = false;
    isValidateBillerAccounts: Boolean = false;
    isCompleted: Boolean = false;

    headerText: string = 'Verify Your Info';
    submitButtonText: string = 'Next';

    billerAccounts: any[];

    constructor(
        private fb: UntypedFormBuilder,
        private userService: UserService,
        private reporter: MessageReporter,
        private route: ActivatedRoute,
        private router: Router,
    ) { }

    confirmPasswordValidator = () => {
        const noMatch: boolean = ( this.form.get('newPassword').value !== this.form.get('confirmPassword').value );
        return noMatch ? { MustMatch: true } : null;
    }

    confirmPinValidator = () => {
        const noMatch: boolean = ( this.form.get('newPin').value !== this.form.get('confirmPin').value );
        return noMatch ? { MustMatch: true } : null;
    }

    getFormValue(field: string): string|number {
        return this.form.get(field).value;
    }

    updateFormField($event: any): void {
        this.form.controls[$event.name] = $event;
        this.form.updateValueAndValidity();
    }

    goHome(): void {
        this.router.navigate(['/']);
    }

    goToCreate(): void {
        this.router.navigate(['/signup']);
    }

    closeAltert(): void {
        this.loginError = false;
    }

    next() {
        if (this.isSearch) {
            this.findLegacyAccount();
        } else if (this.isEnterNewCredentials) {
            this.isEnterNewCredentials = false;
            this.isValidateBillerAccounts = true;
            this.headerText = 'Select Biller Accounts';
            this.submitButtonText = 'Finish';
        } else if (this.isValidateBillerAccounts) {
            this.createRevampAccount();
        } else if (this.isCompleted) {
            this.login();
        }
    }

    async findLegacyAccount(): Promise<any> {
        try {
            var body = this.form.value;
            body.phoneNumber = body.phoneNumber.replace(/-/g, '');
            const response = await this.userService.findLegacyAccount(body);

            if (response) {
                this.form.get('accountToUpgradeFrom').setValue(response.customerID);
                this.billerAccounts = response.billerInfo;
                this.isSearch = false;
                this.isEnterNewCredentials = true;
                this.headerText = 'Secure Your New Account';
                // TODO: see if we can focus the password
            } else {
                this.mismatchError = true;
            }
        } catch (error) {
            if (error.error.error_Description) {
                this.loginErrorText = error.error.error_Description;   
            }
            this.loginError = true;
        }
    }

    public async createRevampAccount(): Promise<void> {
        const values = this.form.value;
        
        const register = {
            linkFastPassRequest: {
                preExistingAccount: false,
                phoneNumber: values.phoneNumber.replace(/-/g, ''),
                fastPassPin: values.newPin,
                lastName: values.lastName,
                languageId: 1
            },
            createUserRequest: {
                email: values.email,
                password: values.newPassword,
                confirmPassword: values.confirmPassword
            }
        };

        try {
            const response = await this.userService.signUp(register);

            if (response.accountCreated) {
                this.upgradeLegacyAccount();
            }
        } catch (error) {
            if ( error.error_description ) {
                this.loginErrorText = error.error_description;
            }
            this.loginError = true;
        }
    }

    async upgradeLegacyAccount(): Promise<any> {
        try {
            var body = this.form.value;
            body.phoneNumber = body.phoneNumber.replace(/-/g, '');
            body.languageId = 1;
            body.fastPassPIN = body.newPin;
            body.accountToUpgradeTo = body.email;
            body.billerAccounts = this.billerAccounts;
            const response = await this.userService.upgradeAccount(body);

            if (response && response.isUpgradeSuccess) {
                this.isValidateBillerAccounts = false;
                this.isCompleted = true;
                this.headerText = "You're all set!";
                this.submitButtonText = 'Go to Dashboard';
            } else {
                this.loginError = true;
            }
        } catch (error) {
            if (error.error.error_Description) {
                this.loginErrorText = error.error.error_Description;   
            }
            this.loginError = true;
        }
    }

    deleteBillerAccount(billerAccount: any): void {
        const index = this.billerAccounts.indexOf(billerAccount);
        if (index >= 0) {
            this.billerAccounts.splice(index, 1);
        }
    }

    async login(): Promise<any> {
        const values = this.form.value;

        try {
            const login = {
                email: values.email,
                password: values.newPassword,
            };

            const response = await this.userService.signIn(login);

            if (response) {
                this.router.navigate(['/dashboard']);

                sessionStorage.setItem('email', this.form.get('email').value);
            } else {
                this.loginError = true;
            }
        } catch (error) {
            if (error.error.error_Description) {
                this.loginErrorText = error.error.error_Description;   
            }
            this.loginError = true;
        }
    }

    ngOnInit(): void {
        this.form = this.fb.group({
            email: new UntypedFormControl('', [Validators.required]),
            phoneNumber: new UntypedFormControl('', [Validators.required]),
            lastName: new UntypedFormControl('', [Validators.required]),
            last4ssn: new UntypedFormControl('', [Validators.required]),
            postalCode: new UntypedFormControl('', [Validators.required]),
            accountToUpgradeFrom: new UntypedFormControl(''),
            accountToUpgradeTo: new UntypedFormControl(''),
            languageId: new UntypedFormControl(''),
            fastPassPIN: new UntypedFormControl(''),
            newPassword: new UntypedFormControl('', [Validators.required]),
            confirmPassword: new UntypedFormControl('', [Validators.required]),
            newPin: new UntypedFormControl('', [Validators.required]),
            confirmPin: new UntypedFormControl('', [Validators.required]),
        });

        this.route.queryParams.subscribe(params => {
            if ( params.email ) {
                this.form.get('email').setValue(params.email);
            }
        });
    }

}
