import { Component, OnInit, HostListener } from '@angular/core';
import { Router } from '@angular/router';

import { MessageReporter } from '../../services/messages/message-reporter.service';
import { UserService } from '../../services/user/user-service.service';
import { Transaction, CustomerTransaction } from '../../interfaces/pay-transactions.interface';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { PaymentReceiptComponent } from './payment-receipt/payment-receipt.component';

import * as moment from 'moment';

@Component({
    selector: 'app-pay-history',
    templateUrl: './pay-history.component.html',
    styleUrls: ['./pay-history.component.scss']
})
export class PayHistoryComponent implements OnInit {
    innerWidth: any;
    windowSize: string = 'large';

    @HostListener('window:resize', ['$event'])
        onResize(event: any) {
            this.innerWidth = window.innerWidth;
            this.setWindowSize();
        }

    customerTransaction: CustomerTransaction;
    gettingHistory: boolean = true;
    historyTableData: any[] = [];
    page = 1;
    pageSize = 1;
    payHistory: Transaction[] = [];
    payHistorySize = 3;

    constructor(
        private modal: NgbModal,
        private reporter: MessageReporter,
        private router: Router,
        private userService: UserService,
    ) { }

    setWindowSize(): void {
        if ( this.innerWidth > 1000 ) {
            this.windowSize = 'large';
        }else if ( this.innerWidth <= 1000 && this.innerWidth > 600 ) {
            this.windowSize = 'medium';
        } else if ( this.innerWidth <= 600 ) {
            this.windowSize = 'small';
        }
    }

    formatDate(date: any) {
        return moment(date).format('MM/DD/YYYY');
    }

    currencyFormat(amount: any) {
        return '$' + Number(amount).toFixed(2);
    }

    getTotalPaid(row: any): string {
        const total = Number(row.paymentAmount) + Number(row.customerFee);

        return this.currencyFormat(total);
    }

    goToBiller(payment: any) {
        this.router.navigate(['/biller-payment', { account: payment.accountNumber }]);
    }

    accountLast4(account: any): string|number {
        const length = account.length;
        const sub = account.toString().substring((length-4), length);

        return sub;
    }

    async getCustomerTransactions(): Promise<any> {
        try {
            const response: CustomerTransaction = await this.userService.customerTransactions(this.page);

            this.customerTransaction = response;
            this.page = response.currentPage;
            this.pageSize = response.pageSize;
            this.payHistory = response.listOfTransactionInfo;
            this.payHistorySize = response.entityCount;
            this.gettingHistory = false;
        
        } catch (error) {
            this.reporter.showErrorMessage('Could not get Transaction History');
            this.gettingHistory = false;

        }
    }

    showReceipt(row: any): void {
        const modalRef = this.modal.open(PaymentReceiptComponent, { size: 'md', centered: true });
        modalRef.componentInstance.payment = row;
        modalRef.componentInstance.name = 'PayReceipt';
    }

    refreshPayHistory() {
        if ( this.page <= this.customerTransaction.pageCount ) {
            this.getCustomerTransactions();
        }
    }

    ngOnInit(): void {
        this.innerWidth = window.innerWidth;
        this.setWindowSize();

        this.getCustomerTransactions();
    }

}
