<button type="button" class="btn btn-primary">{{'button.primary' | translate}}</button>
<button type="button" class="btn btn-secondary">{{'button.secondary' | translate}}</button>
<button type="button" class="btn btn-success">{{'button.success' | translate}}</button>
<button type="button" class="btn btn-danger">{{'button.danger' | translate}}</button>
<button type="button" class="btn btn-warning">{{'button.warning' | translate}}</button>
<button type="button" class="btn btn-info">{{'button.info' | translate}}</button>
<button type="button" class="btn btn-light">{{'button.light' | translate}}</button>
<button type="button" class="btn btn-dark">{{'button.dark' | translate}}</button>

<button type="button" class="btn btn-link">{{'button.link' | translate}}</button>
