import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { MessageReporter } from '../../services/messages/message-reporter.service';
import { BillersService } from 'src/app/services/billers/billers.service';
import { MyBillersComponent } from 'src/app/widgets/my-billers/my-billers.component';

@Component({
  selector: 'app-biller-delete',
  templateUrl: './biller-delete.component.html',
  styleUrls: ['./biller-delete.component.scss']
})
export class BillerDeleteComponent implements OnInit {
  @Input() name: any;
  @Input() biller: any;
  @Input() parent: MyBillersComponent;
  hasError: boolean = false;
  errorText: string = '';

  constructor(
      public activeModal: NgbActiveModal,
      private billerService: BillersService,
      private reporter: MessageReporter,
  ) { }

  closeAlert() {
      this.errorText = '';
      this.hasError = false;
  }

  async deleteBillerAccount(): Promise<any> {
      try {
          const response = await this.billerService.deleteBillerAccount(this.biller.accountId, this.billerService.userId());

          this.activeModal.close();
          this.parent.getMyBillers();
          this.activeModal.close();
          this.reporter.showSuccessMessage('Biller Account was successfully removed');

      } catch (error) {
          if (error.error_description) {
              this.errorText = error.error_description;
              this.hasError = true;
          }
      }
  }

  ngOnInit(): void {
      console.log(this.biller);
  }
}
