<div class="modal-header">
    <h4 class="modal-title">{{'global.barcode' | translate}}</h4>
</div>
<div class="modal-body align-center">
    <ngx-barcode6 [bc-value]="barCodeId" [bc-display-value]="false" bc-format="CODE128" [bc-height]="150" [bc-width]="3"></ngx-barcode6>
</div>
<div class="modal-footer">
    <div class="button-row">
        <button
            type="button"
            class="btn btn-default"
            (click)="activeModal.close()"
        >
        {{'global.close' | translate}}
        </button>
    </div>
</div>