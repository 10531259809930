import { environment } from './environments/environment';

// lets make the api config just a bit less fragile by ensuring the URL ends with a '/'
// even if they forgot to add it in the config.
//
var url = environment.apiUrl;
if(!url.endsWith('/'))
    url = url + '/';
export const apiUrl = url;
export const applePayMerchantId = environment.applePayMerchantId;


export const MapsApiKey = 'AIzaSyCo4vIvgUR-zqoEuulxDp33gT1PLiHbkLY';

export const billerImageUrl = apiUrl + 'Image';

export const GetCardType = (number: string) => {
    // Visa
    var re = new RegExp("^4");
    if (number.match(re) != null)
        return [1, "Visa"];

    // Mastercard
    re = new RegExp("^(5[1-5]|2[2-7])");
    if (number.match(re) != null)
        return [2, "Mastercard"];

    // AMEX
    re = new RegExp("^3[47]");
    if (number.match(re) != null)
        return [4, "AMEX"];

    // Discover
    re = new RegExp("^(6011|622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5]|64[4-9])|65)");
    if (number.match(re) != null)
        return [3, "Discover"];
    
    // Diners
    re = new RegExp("^36|^30[0-5]");
    if (number.match(re) != null)
        return [0, "Diners"];
    // JCB
    re = new RegExp("^35(2[89]|[3-8][0-9])");
    if (number.match(re) != null)
        return [0, "JCB"];

    return [];
}

export const CardLuhnCheck = (number: string) => {
	let total = 0, even = false;

	for (var n = number.length - 1; n >= 0; n--) {
		var cDigit = number.charAt(n),
			  nDigit = parseInt(cDigit, 10);

		if (even && (nDigit *= 2) > 9) nDigit -= 9;

		total += nDigit;
		even = !even;
	}

	let visa = /^4[0-9]{12}(?:([0-9]{3}|[0-9]{6}))?$/;
	let mastercard = /(?!^50[0-9]{14}$)(?!^2220[0-9]{12}$)(?!^272[1-9][0-9]{12}$)(?!^27[3-9][0-9]{13}$)^((5[1-5]|2[2-7])[0-9]{14})$/;
	let dinersclub = /^((36[0-9]{12,17})|(3[89][0-9]{14,17})|(30[0-5][0-9]{13,16})|(3095[0-9]{12,15})|(5[45][0-9]{14}))$/;
	let amex = /^3[47][0-9]{13}$/;
	let discover = /(?!^64[0-3][0-9]{13,16}$)(^(6((011[0-9]{12,15})|([45][0-9]{14,17})))$)/;
	let jcb = /(?!^352[0-7][0-9]{12,15}$)^(35[2-8][0-9][0-9]{12,15})$/;
	
	var seemsValid = false;
	seemsValid = seemsValid || visa.test(number);
	seemsValid = seemsValid || mastercard.test(number);
	seemsValid = seemsValid || dinersclub.test(number);
	seemsValid = seemsValid || amex.test(number);
	seemsValid = seemsValid || discover.test(number);
	seemsValid = seemsValid || jcb.test(number);
	seemsValid = seemsValid && (total % 10) == 0;
	return seemsValid;
}

export const ABACheckDigitCheck = (number: string) => {
	const digits = number.split('').map(d => parseInt(d, 10));        
	const checksum = 3 * (digits[0] + digits[3] + digits[6]) + 7 * (digits[1] + digits[4] + digits[7]) + (digits[2] + digits[5] + digits[8]);
	return checksum % 10 === 0 && number !== '000000000';
}

interface CreditCardList {
    creditCardName: string;
    creditCardTypeId: number; 
    startsWithList: any[];
}

interface BillerFees {
    billerFee: number;
    creditCardsList: CreditCardList[];
    customerFeePercent: boolean;
    customerFeeValue: number;
    customerSmsFee: number;
    feeFunction: string;
    feeIncrement: number;
    fileName: string;
    maxAmount: number;
    minimumAmount: number;
    minimumBillerFee: number;
    minimumCustomerFee: number;
    payCategory: number;
    paymentDesc: string;
    paymentTypeId: number;
    paymentTypeNameInLanguage: string;
}

interface Transaction {
    billerFee: number;
    fee: number;
    total: number;
    maxAmount: number;
}

export const GetTransactionFees = (billerFees: BillerFees, payAmount: any) => {
    const trans: Transaction = {
        billerFee: 0,
        fee: 0,
        total: 0,
        maxAmount: 0,
    };

    if (billerFees) {
        const isPercentage = billerFees.customerFeePercent;

        trans.total = payAmount;
        trans.billerFee = billerFees.billerFee;
    
        if ( isPercentage ) {
            const feePercent = billerFees.customerFeeValue / 100;
            const calcFee = payAmount * feePercent;
    
            trans.fee = Number(calcFee);
        } else {
            const dblFeeMultiplier = Math.ceil((payAmount / billerFees.feeIncrement));
            if ( trans.total < billerFees.minimumAmount ) {
                trans.fee = (dblFeeMultiplier * billerFees.minimumCustomerFee);
            } else {
                trans.fee = (dblFeeMultiplier * billerFees.customerFeeValue);
            }
        }

        trans.maxAmount = billerFees.maxAmount;
    
        trans.total = Number(trans.fee) + Number(payAmount);   
    }

    return trans;
}
