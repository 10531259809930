import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-reset-link',
  templateUrl: './reset-link.component.html',
  styleUrls: ['./reset-link.component.scss']
})
export class ResetLinkComponent implements OnInit {

  form: UntypedFormGroup = new UntypedFormGroup({
    token: new UntypedFormControl(''),
  });

  constructor(
    private router: Router,
    ) { }

  goToURL() {
    const token = this.form.get('token').value;
    document.getElementById('resetLinkHolder').innerHTML = '<a href="/reset-password?token=' + token + '">Click Here</a>';
    // this.router.navigate(['/passwordreset'], { queryParams: { token: token } });
  }

  ngOnInit(): void {
  }

}
