import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { PaymentMethodsComponent } from '../payment-methods.component';
import { PayMethodService } from '../../../../services/pay-method/pay-method.service';
import { MessageReporter } from '../../../../services/messages/message-reporter.service';
import { BillersService } from 'src/app/services/billers/billers.service';

@Component({
    selector: 'app-delete-pay-method',
    templateUrl: './delete-pay-method.component.html',
    styleUrls: ['./delete-pay-method.component.scss']
})
export class DeletePayMethodComponent implements OnInit {
    @Input() name: any;
    @Input() payMethod: any;
    @Input() parent: PaymentMethodsComponent;
    hasError: boolean = false;
    errorText: string = '';

    constructor(
        public activeModal: NgbActiveModal,
        private payMethodService: PayMethodService,
        private billerService: BillersService,
        private reporter: MessageReporter,
    ) { }

    closeAlert() {
        this.errorText = '';
        this.hasError = false;
    }

    async deletePayMethod(): Promise<any> {
        try {
            const response = await this.payMethodService.deletePaymentMethod(this.payMethod.paymentMethodId, this.billerService.userId());

            this.activeModal.close();
            this.parent.getPayMethods();
            this.activeModal.close();
            this.reporter.showSuccessMessage('Payment Method was successfully removed');

        } catch (error) {
            if (error.error_description) {
                this.errorText = error.error_description;
                this.hasError = true;
            }
        }
    }

    ngOnInit(): void { }

}
