<app-navbar title="{{'pin-reset.pin-reset-request' | translate}}"></app-navbar>

<div class="container">
    <paper header="{{'pin-reset.reset-your-pin' | translate}}">
        <form [formGroup]="form" class="p-3" (ngSubmit)="onSubmit()">
            <div class="form-group">
                <text-input
                    faIcon="user"
                    name="lastName"
                    required="true"
                    [label]="'global.last-name' | translate"
                    [value]="getFormValue('lastName')"
                    (changeEvent)="updateFormField($event)"
                    [requiredMessage]="'pin-reset.required-field' | translate"
                    ngbAutofocus
                ></text-input>
            </div>
            <div class="form-group">
                <phone-input
                    name="phoneNumber"
                    required="true"
                    [label]="'global.phone-number' | translate"
                    [value]="getFormValue('phoneNumber')"
                    (changeEvent)="updateFormField($event)"
                    [invalidMessage]="'pin-reset.invalid-phone' | translate"
                    [requiredMessage]="'pin-reset.required-field' | translate"
                ></phone-input>
            </div>
            <div class="form-group">
                <int-input
                    faIcon="hashtag"
                    name="accountLastFour"
                    required="true"
                    [label]="'global.account-last-four' | translate"
                    [value]="getFormValue('accountLastFour')"
                    (changeEvent)="updateFormField($event)"
                    maxLength="4"
                    (keypress)="checkKey($event)"
                    pattern="^\d{4}$"
                    [invalidMessage]="'pin-reset.account-four-digits' | translate"
                    [requiredMessage]="'pin-reset.required-field' | translate"
                ></int-input>
            </div>
            <div class="d-lg-none d-md-block d-lg-none">
                <p>* {{'global.required' | translate}}</p>
            </div>
            <div class="d-none d-lg-block">
                <div class="button-row">
                    <div class="row">
                        <div class="col-12 align-right">
                            <app-spinner *ngIf="running"></app-spinner>
                            <button class="btn btn-light" type="button" *ngIf="!running" (click)="goHome()">{{'global.cancel' | translate}}</button>
                            <button class="btn btn-primary" type="submit" *ngIf="!running">{{'global.submit' | translate}}</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="d-md-block d-lg-none">
                <div class="button-row">
                    <div class="row">
                        <div class="col-6 align-left">
                            <button class="btn btn-light" type="button" *ngIf="!running" (click)="goHome()">{{'global.cancel' | translate}}</button>
                        </div>
                        <div class="col-6 align-right">
                            <app-spinner *ngIf="running"></app-spinner>
                            <button class="btn btn-primary" type="submit" *ngIf="!running">{{'global.submit' | translate}}</button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </paper>
</div>
