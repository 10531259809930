import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';

import { UserService } from '../../../services/user/user-service.service';
import { MessageReporter } from '../../../services/messages/message-reporter.service';

import { ChangePasswordInterface } from '../../../interfaces/user.interface';

@Component({
    selector: 'app-password',
    templateUrl: './password.component.html',
    styleUrls: ['./password.component.scss']
})
export class PasswordComponent implements OnInit {

    form: UntypedFormGroup;
    validator = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{6,})/;

    constructor(
        private fb: UntypedFormBuilder,
        private reporter: MessageReporter,
        private userService: UserService,
    ) { }

    confirmValidator = () => {
        const noMatch: boolean = ( this.form.get('newPassword').value !== this.form.get('confirmPassword').value );
        return noMatch ? { MustMatch: true } : null;
    }

    getFormValue(field: string) {
        return this.form.get(field).value;
    }

    updateFormField($event: any) {
        this.form.controls[$event.name] = $event;
        this.form.updateValueAndValidity();
    }

    // convenience getter for easy access to form fields
    get f() { return this.form.controls; }

    async changePassword(): Promise<any> {
        try {
            const values = this.form.value;
            const body: ChangePasswordInterface = {
                confirmPassword: '',
                newPassword: '',
                oldPassword: '',
                userId: '',
            };

            body.confirmPassword = values.confirmPassword;
            body.newPassword = values.newPassword;
            body.oldPassword = values.oldPassword;
            body.userId = this.userService.userId();

            const response = await this.userService.changePassword(body);

            if ( response ) {
                this.reporter.showSuccessMessage('Password was successfully changed');

                this.form.get('oldPassword').setValue('');
                this.form.get('newPassword').setValue('');
                this.form.get('confirmPassword').setValue('');
                this.form.reset();
            }

        } catch (error) {
            console.log(error);
        }
    }

    ngOnInit(): void {
        this.form = this.fb.group({
            oldPassword: new UntypedFormControl('', [Validators.required]),
            newPassword: new UntypedFormControl('', [Validators.required]),
            confirmPassword: new UntypedFormControl('', [Validators.required]),
        });
    }

}
