export const GetBillerPayTypesWithFees: any[] = [
    {
        "BillerFee": 1,
        "CreditCardsList": [],
        "CustomerFeePercent": false,
        "CustomerFeeValue": 1,
        "CustomerSMSFee": 1,
        "FeeFunction": "",
        "FeeIncrement": 25000,
        "FileName": "",
        "MaxAmount": 25000,
        "MinimumAmount": 0,
        "MinimumBillerFee": 0,
        "MinimumCustomerFee": 0,
        "PayCategory": 2,
        "PaymentDesc": "Checking Account",
        "PaymentTypeID": 3,
        "PaymentTypeNameInLanguage": "Checking Account"
    },
    {
        "BillerFee": 0,
        "CreditCardsList": [
            {
                "CreditCardName": "Visa",
                "CreditCardTypeID": 1,
                "StartsWithList": [
                    "4"
                ]
            }
        ],
        "CustomerFeePercent": false,
        "CustomerFeeValue": 0,
        "CustomerSMSFee": 1,
        "FeeFunction": "",
        "FeeIncrement": 0,
        "FileName": "button_photo_paymenttype_card.png",
        "MaxAmount": 0,
        "MinimumAmount": 0,
        "MinimumBillerFee": 0,
        "MinimumCustomerFee": 0,
        "PayCategory": 5,
        "PaymentDesc": "Credit Card",
        "PaymentTypeID": 5,
        "PaymentTypeNameInLanguage": "Credit Card"
    }
];

export const GetBillersList: any[] = [
  {
    "id": 1,
    "name": "OG&E",
    "image": "logo-placeholder.png",
    "type": "Electric and Gas"
  },
  {
    "id": 2,
    "name": "AEP (Public Service Company of Oklahoma)",
    "image": "logo-placeholder.png",
    "type": "Electric and Gas"
  },
  {
    "id": 3,
    "name": "Cox Cable",
    "image": "logo-placeholder.png",
    "type": "Internet and Television"
  },
  {
    "id": 4,
    "name": "AT&T",
    "image": "logo-placeholder.png",
    "type": "Internet and Phone"
  }
];

export const GetMyBillersList: any[] = [
    {
      "id": 1,
      "name": "Public Service Company of Oklahoma",
      "type": "Gas and Electric",
      "balance": "144.34",
      "image": "logo-placeholder.png",
      "_mock": true
    },
    {
      "id": 2,
      "name": "Cox Cable",
      "type": "Cable TV",
      "balance": "144.34",
      "image": "logo-placeholder.png",
      "_mock": true
    },
    {
      "id": 3,
      "name": "OG&E",
      "type": "Gas and Electric",
      "balance": "144.34",
      "image": "logo-placeholder.png",
      "_mock": true
    },
    {
      "id": 4,
      "name": "Windstream",
      "type": "Telephone",
      "balance": "144.34",
      "image": "logo-placeholder.png",
      "_mock": true
    },
    {
      "id": 5,
      "name": "AT&T",
      "type": "Telephone",
      "balance": "144.34",
      "image": "logo-placeholder.png",
      "_mock": true
    },
    {
      "id": 6,
      "name": "Public Service Company of Texas",
      "type": "Gas and Electric",
      "balance": "144.34",
      "image": "logo-placeholder.png",
      "_mock": true
    },
    {
      "id": 7,
      "name": "Public Service Company of Arkansas",
      "type": "Gas and Electric",
      "balance": "144.34",
      "image": "logo-placeholder.png",
      "_mock": true
    }
  ];

export const GetMyBillersBalance: any = {
  "balance": "156.43"
};