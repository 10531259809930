<form [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="modal-header">
        <h4 class="modal-title">{{'fast-past-link.fast-past' | translate}}</h4>
    </div>
    <div class="modal-body">
        <ngb-alert 
            type="danger" 
            *ngIf="linkError" 
            (closed)="closeAltert()"
        >
            {{linkErrorText}}
        </ngb-alert>
        <div class="form-group">
            <text-input 
                faIcon="user"
                name="lastName" 
                [label]="'global.last-name' | translate"
                required="true"
                [value]="getFormValue('lastName')"
                (changeEvent)="updateFormField($event)"
            ></text-input>
        </div>
        <div class="form-group">
            <phone-input 
                name="phoneNumber" 
                required="true"
                [label]="'fast-past-link.phone-number' | translate"
                [value]="getFormValue('phoneNumber')"
                (changeEvent)="updateFormField($event)"
            ></phone-input>
        </div>
        <div class="form-group">
            <text-input 
                faIcon="map-marker-alt"
                name="zip" 
                [label]="'global.postal-code' | translate"
                required="true"
                [value]="getFormValue('zip')"
                (changeEvent)="updateFormField($event)"
            ></text-input>
        </div>
        <div class="form-group">
            <int-input 
                faIcon="lock"
                name="fastPassPin" 
                required="true"
                [label]="'fast-past-link.fast-past-pin' | translate"
                [value]="getFormValue('fastPassPin')"
                (changeEvent)="updateFormField($event)"
            ></int-input>
        </div>
    </div>
    <div class="modal-footer">
        <div class="button-row row w-100">
            <div class="col-12 align-right pe-0">
                <!-- <button 
                    type="button" 
                    class="btn btn-light" 
                    (click)="updateParent()"
                >Cancel</button> -->
                <button 
                    type="submit" 
                    class="btn btn-primary"  
                    *ngIf="!isLinking"
                    [disabled]="!form.valid || isLinking"
                >{{linkButtonText}}</button>
                <app-spinner *ngIf="isLinking"></app-spinner>
            </div>
        </div>
    </div>
</form>