import { Injectable, OnDestroy, OnInit } from '@angular/core';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { UserService } from '../user/user-service.service';
import { TimeoutModalComponent } from '../../widgets/timeout-modal/timeout-modal.component';

const idleValue: number = 60000;
const timeoutValue: number = 540000;

@Injectable({
    providedIn: 'root'
})
export class IdleTimeoutService implements OnDestroy {

    modalRef: any;
    timeout: any;
    timeoutValue: number = timeoutValue;
    idleValue: number = idleValue;
    interval: any;

    interuptTimeout: any;

    constructor(
        private modal: NgbModal,
        private userService: UserService,
    ) { }

    public start() {
        this.bindInterupts();
        this.startSession();
    }

    public destroy(): void {
        this.closeTimeoutNotice();

        window.clearTimeout(this.timeout);
        window.clearInterval(this.interval);

        this.idleValue = idleValue;
        this.timeoutValue = timeoutValue;

        this.removeInterupts();
    }

    public extendSession(): void {
        this.startSession();
    }

    public logout(): void {
        this.userService.logout();
    }

    startSession(): void {
        window.clearTimeout(this.timeout);
        window.clearInterval(this.interval);

        this.idleValue = idleValue;
        this.timeoutValue = timeoutValue;

        this.timeout = window.setTimeout(() => {
            this.showTimeoutNotice();
        }, this.timeoutValue);
    }

    bindInterupts(): void {
        document.addEventListener('mousemove', this.interuptEvent);
        document.addEventListener("keydown", this.interuptEvent);
        window.addEventListener("scroll", this.interuptEvent);
    }

    removeInterupts(): void {
        document.removeEventListener("mousemove", this.interuptEvent);
        document.removeEventListener("keydown", this.interuptEvent);
        window.removeEventListener("scroll", this.interuptEvent);
    }

    interuptEvent = (event: any) => {
        window.clearTimeout(this.interuptTimeout);

        this.interuptTimeout = window.setTimeout(() => {
            this.extendSession();
        }, 75);
    }

    public showTimeoutNotice(): void {
        this.modalRef = this.modal.open(TimeoutModalComponent, { size: 'md', centered: true });
        this.modalRef.componentInstance.idle = this;
        this.modalRef.componentInstance.name = 'TimeoutNotice';
    }

    closeTimeoutNotice(): void {
        if ( this.modalRef ) {
            this.modalRef.close();
        }
    }

    ngOnDestroy(): void {
        this.destroy();
    }
}