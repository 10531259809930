<form [formGroup]="form">
    <div class="modal-header">
        <h4 class="modal-title">{{'forgot-password.reset-password' | translate}}</h4>
    </div>
    <div class="modal-body">
        <div class="form-group">
            <email-input 
                name="emailAddress" 
                [label]="'Email'"
                [value]="getFormValue('emailAddress')"
                (changeEvent)="updateFormField($event)"
                ngbAutofocus
            ></email-input>
        </div>
    </div>
    <div class="modal-footer">
        <button 
            type="button" 
            class="btn btn-light" 
            (click)="activeModal.close('Close click')"
        >{{'global.cancel' | translate}}</button>
        <button 
            type="submit" 
            class="btn btn-primary" 
            [disabled]="!form.valid || formSubmitting"
            (click)="onSubmit()"
        >{{'global.submit' | translate}}</button>
    </div>
</form>