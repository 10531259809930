import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { MessageReporter } from '../../services/messages/message-reporter.service';
import { UserService } from '../../services/user/user-service.service';

@Component({
    selector: 'app-pin-reset',
    templateUrl: './pin-reset.component.html',
    styleUrls: ['./pin-reset.component.scss']
})
export class PINResetComponent implements OnInit {
    form: UntypedFormGroup;
    running: boolean = false;

    constructor(
        private fb: UntypedFormBuilder,
        private userService: UserService,
        private reporter: MessageReporter,
        private route: ActivatedRoute,
        private router: Router,
        @Inject(DOCUMENT) private document: Document,
        private translate: TranslateService
    ) { }

    getFormValue(field: string): string|number {
        return this.form.get(field).value;
    }

    updateFormField($event: any): void {
        this.form.controls[$event.name] = $event;
        this.form.updateValueAndValidity();
    }

    goHome(): void {
        this.router.navigate(['/']);
    }

    public async onSubmit(): Promise<any> {
        if (this.running) return;

        if (this.form.invalid) {
            var ctrls = this.document.getElementsByClassName("is-invalid");
            if (ctrls.length > 0) {
                setTimeout(() => { (ctrls[0] as HTMLElement).focus(); }, 100);
                return;
            }
        }

        if (this.getFormValue("id") === null || this.getFormValue("id") === ""){
            this.reporter.showErrorMessage(this.translate.instant("pin-reset.invalid-information"));
            return;
        }

        try {
            this.running = true;
            const body = this.form.value;
            const response = await this.userService.submitPINReset(body);
            if (response.success) {
                this.reporter.showSuccessMessage(this.translate.instant("pin-reset.pin-success"));
                this.goHome();
            }
            else
                this.reporter.showErrorMessage(this.translate.instant("pin-reset.reset-failed"));
        } catch (error) {
            console.log(error);
            this.reporter.showErrorMessage(this.translate.instant("pin-reset.reset-failed"));
        }
        
        this.running = false;
    }

    ngOnInit(): void {
        this.form = this.fb.group({
            phoneNumber: new UntypedFormControl('', [Validators.required]),
            token: new UntypedFormControl('', [Validators.required]),
            pin: new UntypedFormControl('', [Validators.required]),
            id: new UntypedFormControl('', [Validators.required]),
        });

        this.route.fragment.subscribe(id => {
            this.form.get("id").setValue(id);
        });
    }

    checkKey($event: any) {
        if ($event.key == "Enter") return true;
        return /\d/.test($event.key);
    }
}