<h2 class="thin" [class.mt-5]="isDashboard">{{'global.receipt-methods' | translate}}</h2>
<form [formGroup]="form">
    <div class="row radio-group">
        <div class="col-12 container" [class.pb-0]="isDashboard">
            <div class="selected-method-input d-flex justify-content-center">
                <div class="form-check form-switch">
                    <label for="print">
                        {{'global.print' | translate}}
                        <input
                            #printCheckbox
                            class="checkmark form-check-input"
                            type="checkbox"
                            role="switch"
                            name="printReceipt"
                            [value]="getFormValue('printReceipt')"
                            (change)="updateFormField($event, true)">
                        <small class="d-block">{{'receipt-method.kiosk-only' | translate}}</small>
                    </label>
                  </div>
            </div>
        </div>
    </div>
    <div class="row radio-group">
        <div class="col-12 container" [class.pb-0]="isDashboard">
            <div class="d-flex justify-content-center">
                <div class="form-check form-switch">
                    <label for="smsReceipt">
                        {{'global.text' | translate}}
                        <input
                            #smsCheckbox
                            class="checkmark form-check-input"
                            type="checkbox"
                            role="switch"
                            name="smsReceipt"
                            [value]="getFormValue('smsReceipt')"
                            (change)="updateFormField($event, true)">
                    </label>
                </div>
            </div>
            <div *ngIf="showPhone" class="selected-method-input d-flex justify-content-center">
                <div class="form-group w-75">
                    <phone-input
                        [disabled]="true"
                        name="phoneNumber"
                        [label]="'global.phone-number' | translate"
                        [value]="getFormValue('phoneNumber')"
                    ></phone-input>
                </div>
            </div>
        </div>
    </div>
    <div class="row radio-group">
        <div class="col-12 container" [class.pb-0]="isDashboard">
            <div class="d-flex justify-content-center">
                <div class="form-check form-switch">
                    <label for="emailReceipt">
                        {{'global.email' | translate}}
                        <input
                            #emailCheckbox
                            class="checkmark form-check-input"
                            type="checkbox"
                            role="switch"
                            name="emailReceipt"
                            [value]="getFormValue('emailReceipt')"
                            (change)="updateFormField($event, true)">
                    </label>
                </div>
            </div>
            <div *ngIf="showEmail" class="selected-method-input d-flex justify-content-center">
                <div class="form-group w-75">
                    <email-input
                        name="email"
                        [disabled]="true"
                        [label]="'global.email' | translate"
                        [value]="getFormValue('email')"
                    ></email-input>
                </div>
            </div>
        </div>
    </div>
    <div class="button-row">
        <div class="row">
            <div class="d-none d-md-block col-md-12 align-right">
                <button class="btn" [ngClass]="isDashboard ? 'btn-light' : 'btn-primary'" (click)="savePersonalInfo()">{{'receipt-method.save-receipt-methods' | translate}}</button>
            </div>
            <div class="d-sm-block d-md-none col-12 align-enter">
                <button class="btn btn-block" [ngClass]="isDashboard ? 'btn-light' : 'btn-primary'" (click)="savePersonalInfo()">{{'receipt-method.save-receipt-methods' | translate}}</button>
            </div>
        </div>
    </div>
</form>