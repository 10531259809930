<ribbon></ribbon>
<div id="site-loader" *ngIf="!loadSite">
    <app-roller></app-roller>
</div>
<div *ngIf="loadSite" id="full-height-wrapper" class="h-100">
    <div id="primary-container" class="container-fluid">
        <div *ngIf="url && url === '/'">
            <router-outlet></router-outlet>
        </div>

        <div class="mainWrapper" *ngIf="url && url !== '/'">
            <div [ngClass]="{'mobileWrapper': isMobile}" class="wrapper">
                <div class="w-100 p-3">
                    <router-outlet></router-outlet>
                </div>
                <div class="clearfix"></div>
            </div>
        </div>

        <app-footer [ngClass]="{'mobile': isMobile}"></app-footer>
    </div>
</div>
<div id="printReceipt"></div>