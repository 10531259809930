<div class="modal-header">
    <h4 class="modal-title">{{'biller-delete.delete-biller-account' | translate}}</h4>
</div>
<div class="modal-body">
    <ngb-alert 
        type="danger" 
        *ngIf="hasError" 
        (closed)="closeAlert()"
    >
        <div [innerHtml]="errorText"></div>
    </ngb-alert>
    <p>{{'biller-delete.are-you-sure' | translate}} <strong>{{'biller-delete.delete' | translate}}</strong> {{'biller-delete.this-biller-account' | translate}}?</p>
</div>
<div class="modal-footer">
    <div class="button-row">
        <button class="btn btn-normal" (click)="activeModal.dismiss('canceled')">{{'global.cancel' | translate}}</button>
        <button class="btn btn-danger" (click)="deleteBillerAccount()">{{'biller-delete.agree-delete-account' | translate}}</button>
    </div>
</div>