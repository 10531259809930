<form [formGroup]="form" class="form" (submit)="onSubmit()">
    <div class="modal-header">
        <h4 class="modal-title">{{'add-new-pay-method.add-payment-type' | translate}}</h4>
    </div>
    <div class="modal-body">
        <ngb-alert 
            type="danger" 
            *ngIf="hasError" 
            (closed)="closeAlert()"
        >
            <div [innerHtml]="errorText"></div>
        </ngb-alert>
        <div class="form-group">
            <span class="textLabel">{{'one-time-payment.payment-type' | translate}}</span>
            <select 
                name="payTypeId" 
                type="number" 
                class="form-control" 
                ngbAutoFocus
                formControlName="payTypeId"
                (change)="selectPaymentType($event)"
                tabindex="0"
            >
                <option disable value="" selected></option>
                <ng-container *ngFor="let type of paymentTypes">
                    <option *ngIf="type.id !== 12" value={{type.id}}>{{type.name}}</option>
                </ng-container>
            </select>
        </div>
        <div *ngIf="getFormValue('payTypeId') === '3' || getFormValue('payTypeId') === '4'">
            <div class="form-group">
                <int-input
                    name="routingNumber"
                    [label]="'Routing Number'"
                    [value]="getFormValue('routingNumber')"
                    (changeEvent)="updateFormField($event)"
                    [maxLength]="9"
					[isInValid]="isInValidABA"
                    invalidMessage={{inValidABAMessage}}
					[isRoutingNumber]="true"
                    required="true"
                    [tabindex]="1"
                    ngbAutofocus
                    [extraSpacing]="true"
                ></int-input>
            </div>
            <div class="form-group">
                <int-input
                    name="bankAccount"
                    [label]="'Account Number'"
                    [value]="getFormValue('bankAccount')"
                    [minLength]="2"
                    [maxLength]="17"
                    (changeEvent)="updateFormField($event)"
                    required="true"
                    [tabindex]="2"
                    [extraSpacing]="true"
                ></int-input>
            </div>
            <div class="get-routing-number">
                <button type="button" class="btn btn-link" (click)="openRoutingExample()">{{'global.where-is-routing-number' | translate}}</button>
            </div>
        </div>
        <div *ngIf="getFormValue('payTypeId') === '5' || getFormValue('payTypeId') === '6'">
            <div class="form-group">
                <int-input
                    faIcon="credit-card"
                    name="creditCardNumber"
                    [label]="cardPlaceholderText()"
                    [maxLength]="19"
                    [isInValid]="isInValidCC"
                    invalidMessage={{inValidCCMessage}}
                    [value]="getFormValue('creditCardNumber')"
                    (changeEvent)="updateFormField($event)"
					[isCreditCard]="true"
                    required="true"
                    [tabindex]="1"
                    ngbAutofocus
                    [extraSpacing]="true"
                ></int-input>
            </div>
            <div class="row expRow p-0">
                <div class="col">
                    <div class="form-group">
                        <span class="textLabel">{{'one-time-payment.expiration-month' | translate}}</span>
                        <select name="expireMonth" class="form-control expMonth" formControlName="expireMonth" tabindex="2">
                            <option value="" disable selected>{{'one-time-payment.mm' | translate}}</option>
                            <option *ngFor="let month of expireMonths" value="{{month}}">{{month}}</option>
                        </select>
                    </div>
                </div>
                <div class="col">
                    <div class="form-group">
                        <span class="textLabel">{{'one-time-payment.expiration-year' | translate}}</span>
                        <select name="expireYear" class="form-control expYear" formControlName="expireYear" tabindex="3">
                            <option value="" disable selected>{{'one-time-payment.yyyy' | translate}}</option>
                            <option *ngFor="let year of expireYears" value="{{year}}">{{year}}</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <div class="button-row">
            <button type="button" class="btn btn-danger" (click)="activeModal.dismiss('canceled')" tabindex="6">{{'global.cancel' | translate}}</button>
            <button type="submit" class="btn btn-primary" [disabled]="form.invalid || isInValidCC || isInValidABA || !selectedType" *ngIf="!isBusy" tabindex="5">{{'global.add' | translate}}</button>
            <app-spinner *ngIf="isBusy"></app-spinner>
        </div>
    </div>
</form>
        