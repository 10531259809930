<app-navbar title="Frequently Asked Questions"></app-navbar>
<div class="container">
    <div class="content-presentation">
        <section>
            <h3 class="thin">{{'faqs.what-is-mypaysite' | translate}}</h3>
            <p>{{'faqs.mypaysite-allows' | translate}}</p>
        </section>

        <section>
            <h3 class="thin">{{'faqs.charge-mypaysite' | translate}}</h3>
            <p>{{'faqs.convenience-fee' | translate}}</p>
        </section>

        <section>
            <h3 class="thin">{{'faqs.sign-up-mypaysite' | translate}}</h3>
            <p>{{'faqs.clicking-sign-up' | translate}}</p>
        </section>

        <section>
            <h3 class="thin">{{'faqs.payment-options' | translate}}</h3>
            <p>{{'faqs.pay-with-bank-account' | translate}}</p>
        </section>

        <section>
            <h3 class="thin">{{'faqs.mypaysite-similar' | translate}}</h3>
            <p>{{'faqs.new-and-improved' | translate}}</p>
        </section>
    </div>
</div>