
import Step from 'shepherd.js/src/types/step';

export const builtInButtons = {
    cancel: {
        classes: 'cancel-button',
        secondary: true,
        text: 'Exit',
        type: 'cancel'
    },
    next: {
        classes: 'next-button',
        text: 'Next',
        type: 'next'
    },
    back: {
        classes: 'back-button',
        primary: true,
        text: 'Back',
        type: 'back'
    },
    finish: {
        classes: 'back-button',
        primary: true,
        text: 'Finish',
        action: function() {
            console.log('hello');
            const nav = document.getElementById('navLinks-menu');
            nav.classList.remove('active');
            return console.log('button clicked');
        }
    }
};

export const defaultStepOptions: Step.StepOptions = {
    classes: 'shepherd-theme-arrows custom-default-class',
    scrollTo: true,
    cancelIcon: {
        enabled: false
    },
};

export const defaultSteps: Step.StepOptions[]  = [
    {
        attachTo: {
            element: '#myBillers-addNew',
            on: 'bottom'
        },
        buttons: [
            builtInButtons.next
        ],
        classes: 'shepherd-main-override',
        id: 'billers',
        title: 'Let\'s start by adding a new biller',
        text: `<p>Adding a new biller lets you pay bills and view your transaction history.</p>`
    },
    {
        attachTo: {
            element: '#history-list',
            on: 'bottom'
        },
        buttons: [
            builtInButtons.next
        ],
        classes: 'shepherd-main-override',
        id: 'payHistory',
        title: 'Your Pay History',
        text: `<p>As you make your payments, you will begin to see your Pay History appear here.</p>`
    },
    {
        attachTo: {
            element: '#receiptMethods',
            on: 'top'
        },
        buttons: [
            builtInButtons.next
        ],
        classes: 'shepherd-main-override',
        id: 'receiptMethods',
        title: 'Select a Receipt Method',
        text: `<p>Select the Receipt Method that works best for you. You can change it anytime from the Profile page.</p>`
    },
    {
        attachTo: {
            element: '#topNavProfileLink',
            on: 'bottom'
        },
        beforeShowPromise: function () {
            return new Promise(function (resolve) {
                const nav = document.getElementById('navLinks-menu');
                if ( nav !== null ) {
                    nav.classList.add('active');

                    window.setTimeout(() => {
                        // resolve();
                    }, 300);
                } else {
                    // resolve();
                }
            });
        },
        buttons: [
            builtInButtons.finish
        ],
        classes: 'shepherd-main-override',
        id: 'profile',
        title: 'Take a Look at Your Profile',
        text: `<p>Pay Methods, your personal info and more can added and edited from your Profile page. Take a look now!</p>`,
    },
];