import { Injectable } from '@angular/core';

import { ApiService } from '../api/api.service';

@Injectable({
    providedIn: 'root'
})
export class PayMethodService {

    constructor(
        private api: ApiService,
    ) { }

    userId(): string {
        return sessionStorage.getItem('userId');
    }

    public async getPayMethods(): Promise<any> {
        try {
            const data = await this.api.get({
                url: 'PaymentMethods?userId=' + this.userId()
            });

            return data;
        } catch (error) {
            throw error;
        }
    }

    public async createPaymentMethod(body: any): Promise<any> {
        console.log(body);
        try {
            const data = await this.api.post({
                url: 'PaymentMethods/Create?userId=' + this.userId(),
                body
            });

            return data;
        } catch (error) {
            throw error;
        }
    }

    public async deletePaymentMethod(paymentMethodId: any, userId: any): Promise<any> {
        try {
            const data = await this.api.delete({
                url: 'PaymentMethods/Delete?paymentMethodId=' + paymentMethodId + '&userId=' + userId,
            });

            return data;
        } catch (error) {
            throw error;
        }
    }
}
