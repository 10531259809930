<app-navbar title="One-Time Payment"></app-navbar>

<paper>
    <!-- XS and SM display only begin -->
    <form [formGroup]="form" class="d-block d-md-none">
        <div class="row">
            <div class="col-sm-12">
                <div *ngIf="billerDetails && stepIndex < 3" class="biller-details row">
                    <div class="logo-image col-sm-4 col-3">
                        <img src="{{billerImageUrl}}?billerId={{billerDetails.billerId}}"
                             [hidden]="billerImageHidden"
                             (load)='onBillerImageLoad()'
                             (error)='onBillerImageError()' />
                    </div>
                    <div *ngIf="billerBalance !== ''" class="col-sm-4 col-5">
                        <div *ngIf="gettingBalance">
                            <app-roller></app-roller>
                        </div>
                        <div class="biller-balance">
                            <div *ngIf="!gettingBalance">
                                <p>{{billerBalance}}</p>
                                <small>{{'global.current-balance' | translate}}</small>
                            </div>
                        </div>
                    </div>
                    <!-- Pay Method Details -->
                    <div class="selected-paymethod-details col-4" *ngIf="form.controls['payTypeId'].value">
                        <div *ngIf="isCreditCardPayment()">
                            <fa-icon [icon]="['fab', 'cc-visa']" class="ccIcon"></fa-icon>
                            <fa-icon [icon]="['fab', 'cc-mastercard']" class="ccIcon"></fa-icon>
                            <fa-icon [icon]="['fab', 'cc-discover']" class="ccIcon"></fa-icon>
                            <!-- <fa-icon [icon]="['fab', 'cc-amex']" class="ccIcon"></fa-icon> -->
                        </div>
                        <div *ngIf="isDebitCardPayment()">
                            <img src="../../../assets/images/star-card-logo.png" alt="STAR" class="debitIcon">
                            <img src="../../../assets/images/nyce-card-logo.png" alt="NYCE" class="debitIcon">
                            <img src="../../../assets/images/pulse-card-logo.png" alt="PULSE" class="debitIcon">
                            <img src="../../../assets/images/accel-card-logo.png" alt="ACCEL" class="debitIcon">
                        </div>
                        <div *ngIf="isApplePayPayment()">
                            <fa-icon [icon]="['fab', 'apple-pay']" class="largeCCIcon"></fa-icon>
                        </div>
                        <div class="paymethod-details row" *ngIf="displayCCPaymentDetails()">
                            <div class="col-sm-4">
                                <fa-icon [icon]="['fas', 'credit-card']" class="largeCCIcon"></fa-icon>
                            </div>
                            <div class="col-sm-8 col-12">
                                <p class="payment-type">{{paymentMethod}}</p>
                                <p class="last-four" *ngIf="!isInValidCC">{{'global.ending-in' | translate}} {{lastFourOfCC()}}</p>
                            </div>
                        </div>
                        <div class="paymethod-details row" *ngIf="displayBankPaymentDetails()">
                            <div class="col-sm-4">
                                <fa-icon [icon]="['fas', 'money-check-alt']" class="largeCheckIcon"></fa-icon>
                            </div>
                            <div class="col-sm-8 col-12">
                                <p class="payment-type">{{paymentMethod}}</p>
                                <p class="last-four">{{'global.ending-in' | translate}} {{lastFourOfAccount()}}</p>
                            </div>
                        </div>
                    </div>
                    <!-- // Pay Method Details -->
                </div>
                <div *ngIf="stepIndex >= 3" class="transaction-details">
                    <div id="receiptContent">
                        <h3>{{'global.transaction-receipt' | translate}}</h3>
                        <p>
                            <span class="title d-table-row">{{'global.payment-made-to' | translate}}</span>
                            <span class="amount">{{billerDetails.billerName}}</span>
                        </p>
                        <p>
                            <span class="title">{{'global.date' | translate}}</span>
                            <span class="amount">{{paymentDateFormatted}}</span>
                        </p>
                        <p>
                            <span class="title">{{'global.time' | translate}}</span>
                            <span class="amount">{{paymentTime}}</span>
                        </p>
                        <p>
                            <span class="title">{{'global.method' | translate}}</span>
                            <span class="amount">{{paymentMethod}}</span>
                        </p>
                        <p>
                            <span class="title">{{'global.account' | translate}}</span>
                            <span class="amount">{{form.controls['accountNumber'].value}}</span>
                        </p>
                        <p>
                            <span class="title">{{'global.applied-to-bill' | translate}}</span>
                            <span class="amount">{{currencyFormat(form.controls['payAmount'].value)}}</span>
                        </p>
                        <p>
                            <span class="title">{{'global.fee' | translate}}</span>
                            <span class="amount">{{convenienceFee()}}</span>
                        </p>
                        <p>
                            <span class="title">{{'global.total-amount' | translate}}</span>
                            <span class="amount">{{totalPaymentAmount()}}</span>
                        </p>
                        <p>
                            <span class="title">{{'global.transaction-id' | translate}}</span>
                            <span class="amount">{{paymentId}}</span>
                        </p>
                        <p>
                            <span class="title d-table-row">{{'global.reference-number' | translate}}</span>
                            <span class="amount">{{referenceNumber}}</span>
                        </p>
                        <p *ngIf="authCode">
                            <span class="title">{{'global.authorization-code' | translate}}</span>
                            <span class="amount">{{authCode | uppercase}}</span>
                        </p>
                    </div>
                    <div class="align-center">
                        <button type="button" class="btn btn-light" (click)="print($event)"><fa-icon [icon]="['fas', 'print']"></fa-icon>{{'global.print' | translate}}</button>
                    </div>
                    <div class="align-center">
                        <p class="footnoteText">{{'biller-payment.hard-copy-recipt' | translate}}</p>
                    </div>
                </div>
            </div>
            <div class="col-sm-12 mw-100 form-body">
                <div class="row rightColumnRow">
                    <h4 *ngIf="stepIndex === 1">{{'one-time-payment.payment-info' | translate}}</h4>
                    <h4 *ngIf="stepIndex === 2">{{'one-time-payment.details' | translate}}</h4>
                    <div class="divider" *ngIf="stepIndex === 1 || stepIndex === 2"></div>
                    <div class="d-flex align-items-start" *ngIf="stepIndex === 1">
                        <div class="row mw-100">
                            <div *ngIf="isInValidPayment">
                                <ngb-alert 
                                    type="danger" 
                                    (closed)="closeAlert()"
                                >
                                    {{inValidPaymentMessage}}
                                </ngb-alert>
                            </div>
                            <div class="col-6 form-group">
                                <span class="textLabel">{{'global.biller' | translate}}</span>
                                <app-spinner *ngIf="!billers"></app-spinner>
                                <select 
                                    name="billerId" 
                                    class="form-control" 
                                    formControlName="billerId" 
                                    (change)="getBillerDetails()"
                                    *ngIf="billers"
                                    tabindex="1"
                                >
                                    <!-- <option value="" disabled selected>Company</option> -->
                                    <option *ngFor="let biller of billers" value={{biller.billerId}}>{{biller.billerName}}</option>
                                </select>
                            </div>
                            <div class="col-6 form-group">
                                <text-input 
                                    faIcon="file-invoice"
                                    name="accountNumber"
                                    required="true"
                                    [label]="'Account Number'"
                                    [value]="getFormValue('accountNumber')"
                                    (changeEvent)="updateFormField($event)"
                                    (blurEvent)="accountNumberBlur()"
                                    [tabindex]="2"
                                    [extraSpacing]="true"
                                ></text-input>
                            </div>
                            <div *ngIf="form.controls['billerId'].value">
                                <div *ngIf="billerError">
                                    <ngb-alert 
                                        type="danger" 
                                        (closed)="closeAlert()"
                                    >
                                        {{billerErrorText}}
                                    </ngb-alert>
                                </div>
                                <div *ngIf="!billerError" class="row">
                                    <div class="col-6 form-group innerColLeft">
                                        <text-input 
                                            faIcon="user"
                                            name="customerLastName" 
                                            required="true"
                                            [label]="'Last Name'"
                                            [value]="getFormValue('customerLastName')"
                                            (changeEvent)="updateFormField($event)"
                                            [maxLength]="50"
                                            [tabindex]="3"
                                        ></text-input>
                                    </div>
                                    <div class="col-6 form-group innerColRight">
                                        <email-input 
                                            name="emailAddress" 
                                            [label]="'Email'"
                                            [value]="getFormValue('emailAddress')"
                                            (changeEvent)="updateFormField($event)"
                                            [tabindex]="4"
                                        ></email-input>
                                    </div>
                                    <div class="col-6 form-group innerColLeft">
                                        <phone-input 
                                            name="customerPhone" 
                                            required="true"
                                            [label]="'Phone Number'"
                                            [value]="getFormValue('customerPhone')"
                                            (changeEvent)="updateFormField($event)"
                                            [tabindex]="5"
                                        ></phone-input>
                                    </div>
                                    <div *ngIf="billerValidated && paymentTypesSet" class="col-6 form-group innerColRight">
                                        <select-input
                                            name="payTypeId"
                                            [label]="'one-time-payment.payment-type' | translate"
                                            [items]="paymentTypes"
                                            (changeEvent)="selectPaymentType($event)"
                                            [value]="getFormValue('payTypeId')"
                                            [tabindex]="6"
                                            [emptyLabel]="' '"
                                        ></select-input>
                                    </div>
                                    <div *ngIf="billerValidated" class="row">
                                        <!-- Credit Card Payment -->
                                        <div *ngIf="isCardPayment()" class="row">
                                            <div class="form-group innerColLeft ccInput">
                                                <int-input 
                                                    faIcon="credit-card"
                                                    name="creditCardNumber"
                                                    required="true"
                                                    [label]="cardPlaceholderText()"
                                                    [maxLength]="19"
                                                    [isInValid]="isInValidCC"
                                                    invalidMessage={{inValidCCMessage}}
                                                    [value]="getFormValue('creditCardNumber')"
                                                    (changeEvent)="updateFormField($event)"
                                                    [isCreditCard]="true"
                                                    [tabindex]="7"
                                                    [extraSpacing]="true"
                                                ></int-input>
                                            </div>
                                            <div class="row formGroupRow px-0">
                                                <div class="col ps-0">
                                                    <div class="form-group">
                                                        <select-input
                                                            name="expireMonth"
                                                            [label]="'one-time-payment.expiration-month' | translate"
                                                            [emptyLabel]="'one-time-payment.mm' | translate"
                                                            [items]="expireMonths"
                                                            (changeEvent)="updateFormField($event)"
                                                            [value]="getFormValue('expireMonth')"
                                                            [tabindex]="8"
                                                        ></select-input>
                                                    </div>
                                                </div>
                                                <div class="col pe-0">
                                                    <div class="form-group">
                                                        <select-input
                                                            name="expireYear"
                                                            [label]="'one-time-payment.expiration-year' | translate"
                                                            [emptyLabel]="'one-time-payment.yyyy' | translate"
                                                            [items]="expireYears"
                                                            (changeEvent)="updateFormField($event)"
                                                            [value]="getFormValue('expireYear')"
                                                            [tabindex]="9"
                                                        ></select-input>
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="isCreditCardPayment()" class="col-6 form-group innerColLeft">
                                                <int-input 
                                                    faIcon="credit-card"
                                                    name="cvv"
                                                    required="true"
                                                    [label]="'CVV'"
                                                    pattern="^\d{3}$"
                                                    [maxLength]="3"
                                                    [value]="getFormValue('cvv')"
                                                    (changeEvent)="updateFormField($event)"
                                                    [tabindex]="10"
                                                ></int-input>
                                            </div>
                                            <div *ngIf="isCreditCardPayment()" class="col-6 form-group innerColRight">
                                                <int-input 
                                                    faIcon="map-marker-alt"
                                                    name="customerZip"
                                                    required="true"
                                                    [label]="'Billing Postal Code'"
                                                    pattern="^\d{5}$"
                                                    [maxLength]="5"
                                                    [value]="getFormValue('customerZip')"
                                                    (changeEvent)="updateFormField($event)"
                                                    [disabled]="true"
                                                    [tabindex]="11"
                                                ></int-input>
                                            </div>
                                        </div>
                                        <!-- // Credit Card Payment -->
                                        <!-- Bank Account Payment -->
                                        <div *ngIf="isBankAccountPayment()" class="row">
                                            <div class="col-6 form-group innerColLeft">
                                                <int-input 
                                                    name="routingNumber" 
                                                    required="true"
                                                    [label]="'Routing Number'"
                                                    [maxLength]="9"
                                                    [isInValid]="isInValidABA"
                                                    invalidMessage={{inValidABAMessage}}
                                                    [value]="getFormValue('routingNumber')"
                                                    (changeEvent)="updateFormField($event)"
                                                    [isRoutingNumber]="true"
                                                    [tabindex]="7"
                                                    [extraSpacing]="true"
                                                ></int-input>
                                            </div>
                                            <div class="col-6 form-group innerColRight">
                                                <int-input 
                                                    name="bankAccount" 
                                                    required="true"
                                                    [label]="'Account Number'"
                                                    [maxLength]="17"
                                                    [value]="getFormValue('bankAccount')"
                                                    (changeEvent)="updateFormField($event)"
                                                    [tabindex]="8"
                                                    [extraSpacing]="true"
                                                ></int-input>
                                            </div>
                                            <div class="get-routing-number">
                                                <button type="buton" class="btn btn-link" (click)="openRoutingExample()">{{'global.where-is-routing-number' | translate}}</button>
                                            </div>
                                        </div>
                                        <!-- // Bank Account Payment -->
                                        <div *ngIf="form.controls['payTypeId'].value" class="row">
                                            <div class="col-6 form-group amountInput">
                                                <currency-input 
                                                    name="payAmount" 
                                                    required="true"
                                                    [label]="'Amount'"
                                                    [value]="getFormValue('payAmount')"
                                                    (changeEvent)="updateFormField($event)"
                                                    [tabindex]="12"
                                                ></currency-input>
                                                <p>{{'biller-payment.max-amount' | translate}}{{maxAmount()}}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex align-items-center" *ngIf="stepIndex === 2">
                        <div class="col mw-100">
                            <div *ngIf="creditCardDeclined">
                                <ngb-alert 
                                    type="danger" 
                                    (closed)="closeAlert()"
                                >
                                    {{creditCardDeclinedMessage}}
                                </ngb-alert>
                            </div>
                            <div *ngIf="paymentSuccessful">
                                <ngb-alert 
                                    type="danger" 
                                    (closed)="closeAlert()"
                                >
                                    {{paymentFailedMessage}}
                                </ngb-alert>
                            </div>
                            <p>
                                <span class="title">{{'global.biller-name' | translate}}</span>
                                <span class="amount">{{billerDetails.billerName}}</span>
                            </p>
                            <p>
                                <span class="title">{{'biller-payment.biller-account-number' | translate}}</span>
                                <span class="amount">{{form.controls['accountNumber'].value}}</span>
                            </p>
                            <p>
                                <span class="title">{{'global.last-name' | translate}}</span>
                                <span class="amount">{{form.controls['customerLastName'].value}}</span>
                            </p>
                            <ng-container *ngIf="isBankAccountPayment()">
                                <p>
                                    <span class="title">{{'biller-payment.routing-number' | translate}}</span>
                                    <span class="amount">*****{{form.controls['routingNumber'].value.substr(form.controls['routingNumber'].value.length - 4)}}</span>
                                </p>
                                <p>
                                    <span class="title">{{'biller-payment.account-number' | translate}}</span>
                                    <span class="amount">******{{form.controls['bankAccount'].value.substr(form.controls['bankAccount'].value.length - 4)}}</span>
                                </p>
                            </ng-container>
                            <p class="detailsSpacing">
                                <span class="title">{{'biller-payment.applied-to-account' | translate}}</span>
                                <span class="amount">{{currencyFormat(form.controls['payAmount'].value)}}</span>
                            </p>
                            <p>
                                <span class="title">{{'biller-payment.convenience-fee' | translate}}</span>
                                <span class="amount">{{convenienceFee()}}</span>
                            </p>
                            <p>
                                <span class="title">{{'biller-payment.total-payment-amount' | translate}}</span>
                                <span class="amount">{{totalPaymentAmount()}}</span>
                            </p>
                        </div>
                    </div>
                    <div class="d-flex align-items-center" *ngIf="stepIndex === 3">
                        <div class="col mw-100">
                            <h2 class="align-center">{{'global.thank-you' | translate}}!</h2>
                            <p class="align-center">{{'one-time-payment.create-account' | translate}} <b>{{'one-time-payment.faster' | translate}}</b>{{'global.question-mark' | translate}}</p>
                            <p>
                                <a routerLink="/signup"><button class="btn btn-lg btn-yes">{{'global.yes' | translate}}</button></a>
                                <a routerLink="/"><button class="btn btn-lg btn-no">{{'global.no' | translate}}</button></a>
                            </p>
                        </div>
                    </div>
                </div>
                <div class="button-row" *ngIf="stepIndex < 3">
                    <div *ngIf="stepIndex === 2" class="align-center">
                        <label class="checkbox-inline">    
                            <div class="row">
                                <div class="col-1">
                                    <input #approvedCheckbox type = "checkbox" class="checkmark form-check-input" checked="checked" [checked]="isApproved"
                                        (change)="isApproved = !isApproved" name ="approved">
                                </div>
                                <div class="col-11">
                                    <small *ngIf="isCardPayment()">{{'biller-payment.authorize-charge' | translate}} {{totalPaymentAmount()}}</small>
                                    <small *ngIf="isBankAccountPayment()">{{'biller-payment.authorize-one-time' | translate}} {{totalPaymentAmount()}} {{'biller-payment.account-ending' | translate}} {{lastFourOfAccount()}} {{'biller-payment.on-or-after' | translate}} {{todaysDate()}}. {{'biller-payment.questions' | translate}} 
                                        <small class="noWrap">{{'biller-payment.number' | translate}}</small>
                                    </small>
                                    <small *ngIf="isApplePayPayment()">{{'biller-payment.authorize-charge' | translate}} {{totalPaymentAmount()}}</small>
                                </div>
                            </div>
                        </label>
                    </div>
                    <div *ngIf="stepIndex < 3">
                        <button class="btn btn-danger" (click)="previous()" [tabindex]="13">{{'global.back' | translate}}</button>
                        <!-- Step 1 Buttons -->
                        <button 
                            class="btn btn-primary submit-button" 
                            (click)="next()"
                            [disabled]="!form.valid || isInValidCC || isInValidABA || !billerValidated || gettingBalance || badAmount"
                            *ngIf="stepIndex < 2"
                            [tabindex]="14"
                        >{{'global.next' | translate}}</button>
                        <!-- Step 2 Buttons -->
                        <button 
                            class="btn btn-primary submit-button" 
                            (click)="makeOneTimePayment()"
                            [disabled]="!form.valid || isInValidCC || isInValidABA || !billerValidated || !isApproved || paymentAttempted"
                            *ngIf="stepIndex === 2 && !paymentProcessing"
                            [tabindex]="14"
                        >{{'global.submit-payment' | translate}}</button>
                        <app-spinner class="mr-48" *ngIf="paymentProcessing"></app-spinner>
                    </div>
                </div>
            </div>
        </div>
    </form>
    <!-- XS and SM display only end -->
    <!-- MD+ display only begin -->
    <form [formGroup]="form" class="d-none d-md-block">
        <div *ngIf="stepIndex < 3" class="row">
            <div class="col-lg-11 col-xl-10 col-xxl-9 col-md-11 mx-auto">
                <div class="col-xxl-5 col-xl-5 col-lg-5 col-md-6 left-column float-left">
                    <div *ngIf="billerDetails" class="biller-details">
                        <div class="logo-image">
                            <img src="{{billerImageUrl}}?billerId={{billerDetails.billerId}}"
                                [hidden]="billerImageHidden"
                                (load)='onBillerImageLoad()'
                                (error)='onBillerImageError()' />
                        </div>
                        <div *ngIf="gettingBalance">
                            <div class="divider"></div>
                            <div class="mt-64">
                                <app-roller></app-roller>
                            </div>
                        </div>
                        <div *ngIf="billerBalance !== ''">
                            <div class="divider"></div>
                            <div class="biller-balance">
                                <div *ngIf="!gettingBalance">
                                    <p>{{billerBalance}}</p>
                                    <small>{{'global.current-balance' | translate}}</small>
                                </div>
                            </div>
                        </div>
                        <div class="divider"></div>
                        <!-- Pay Method Details -->
                        <div class="selected-paymethod-details" *ngIf="form.controls['payTypeId'].value">
                            <div *ngIf="isCreditCardPayment()">
                                <fa-icon [icon]="['fab', 'cc-visa']" class="ccIcon"></fa-icon>
                                <fa-icon [icon]="['fab', 'cc-mastercard']" class="ccIcon"></fa-icon>
                                <fa-icon [icon]="['fab', 'cc-discover']" class="ccIcon"></fa-icon>
                                <!-- <fa-icon [icon]="['fab', 'cc-amex']" class="ccIcon"></fa-icon> -->
                            </div>
                            <div *ngIf="isDebitCardPayment()">
                                <img src="../../../assets/images/star-card-logo.png" alt="STAR" class="debitIcon">
                                <img src="../../../assets/images/nyce-card-logo.png" alt="NYCE" class="debitIcon">
                                <img src="../../../assets/images/pulse-card-logo.png" alt="PULSE" class="debitIcon">
                                <img src="../../../assets/images/accel-card-logo.png" alt="ACCEL" class="debitIcon">
                            </div>
                            <div *ngIf="isApplePayPayment()">
                                <fa-icon [icon]="['fab', 'apple-pay']" class="ccIcon"></fa-icon>
                            </div>
                            <div class="paymethod-details" *ngIf="displayCCPaymentDetails()">
                                <fa-icon [icon]="['fas', 'credit-card']"></fa-icon>
                                <p class="payment-type">{{paymentMethod}}</p>
                                <p class="last-four" *ngIf="!isInValidCC">{{'global.ending-in' | translate}} {{lastFourOfCC()}}</p>
                            </div>
                            <div class="paymethod-details" *ngIf="displayBankPaymentDetails()">
                                <fa-icon [icon]="['fas', 'money-check-alt']"></fa-icon>
                                <p class="payment-type">{{paymentMethod}}</p>
                                <p class="last-four">{{'global.ending-in' | translate}} {{lastFourOfAccount()}}</p>
                            </div>
                        </div>
                        <!-- // Pay Method Details -->
                    </div>
                </div>
                <div class="col-xxl-6 col-xl-6 col-lg-7 col-md-6 mw-100 form-body float-right">
                    <div class="row rightColumnRow">
                        <h4 *ngIf="stepIndex === 1">{{'one-time-payment.payment-info' | translate}}</h4>
                        <h4 *ngIf="stepIndex === 2">{{'one-time-payment.payment-details' | translate}}</h4>
                        <div class="divider" *ngIf="stepIndex === 1 || stepIndex === 2"></div>
                        <div class="d-flex align-items-start" *ngIf="stepIndex === 1">
                            <div class="col mw-100">
                                <div *ngIf="isInValidPayment">
                                    <ngb-alert 
                                        type="danger" 
                                        (closed)="closeAlert()"
                                    >
                                        {{inValidPaymentMessage}}
                                    </ngb-alert>
                                </div>
                                <div class="form-group">
                                    <span class="textLabel">{{'global.biller' | translate}}</span>
                                    <app-spinner *ngIf="!billers"></app-spinner>
                                    <select 
                                        name="billerId" 
                                        class="form-control" 
                                        formControlName="billerId" 
                                        (change)="getBillerDetails()"
                                        *ngIf="billers"
                                        [tabindex]="1"
                                    >
                                        <option *ngFor="let biller of billers" value={{biller.billerId}}>{{biller.billerName}}</option>
                                    </select>
                                </div>
                                <div class="form-group">
                                    <text-input 
                                        faIcon="file-invoice"
                                        name="accountNumber"
                                        required="true"
                                        [label]="'Account Number'"
                                        [value]="getFormValue('accountNumber')"
                                        (changeEvent)="updateFormField($event)"
                                        (blurEvent)="accountNumberBlur()"
                                        [tabindex]="2"
                                        [extraSpacing]="true"
                                    ></text-input>
                                </div>
                                <div *ngIf="billerError">
                                    <ngb-alert 
                                        type="danger" 
                                        (closed)="closeAlert()"
                                    >
                                        {{billerErrorText}}
                                    </ngb-alert>
                                </div>
                                <div *ngIf="!billerError && form.controls['billerId'].value">
                                    <div class="form-group">
                                        <text-input 
                                            faIcon="user"
                                            name="customerLastName" 
                                            required="true"
                                            [label]="'Last Name'"
                                            [value]="getFormValue('customerLastName')"
                                            (changeEvent)="updateFormField($event)"
                                            [maxLength]="50"
                                            [tabindex]="3"
                                        ></text-input>
                                    </div>
                                    <div class="form-group">
                                        <email-input 
                                            name="emailAddress" 
                                            [label]="'Email'"
                                            [value]="getFormValue('emailAddress')"
                                            (changeEvent)="updateFormField($event)"
                                            [tabindex]="4"
                                        ></email-input>
                                    </div>
                                    <div class="form-group">
                                        <phone-input 
                                            name="customerPhone" 
                                            required="true"
                                            [label]="'Phone Number'"
                                            [value]="getFormValue('customerPhone')"
                                            (changeEvent)="updateFormField($event)"
                                            [tabindex]="5"
                                        ></phone-input>
                                    </div>
                                    <div *ngIf="billerValidated && paymentTypesSet">
                                        <div class="form-group">
                                            <select-input
                                                name="payTypeId"
                                                [label]="'one-time-payment.payment-type' | translate"
                                                [items]="paymentTypes"
                                                (changeEvent)="selectPaymentType($event)"
                                                [value]="getFormValue('payTypeId')"
                                                [tabindex]="6"
                                                [emptyLabel]="' '"
                                            ></select-input> 
                                        </div>
                                        <!-- Credit Card Payment -->
                                        <div *ngIf="isCardPayment()">
                                            <div class="form-group">
                                                <int-input 
                                                    faIcon="credit-card"
                                                    name="creditCardNumber"
                                                    required="true"
                                                    [label]="cardPlaceholderText()"
                                                    [maxLength]="19"
                                                    [isInValid]="isInValidCC"
                                                    invalidMessage={{inValidCCMessage}}
                                                    [value]="getFormValue('creditCardNumber')"
                                                    (changeEvent)="updateFormField($event)"
                                                    [isCreditCard]="true"
                                                    [tabindex]="7"
                                                    [extraSpacing]="true"
                                                ></int-input>
                                            </div>
                                            <div class="row formGroupRow px-0">
                                                <div class="col ps-0">
                                                    <div class="form-group">
                                                        <select-input
                                                            name="expireMonth"
                                                            [label]="'one-time-payment.expiration-month' | translate"
                                                            [emptyLabel]="'one-time-payment.mm' | translate"
                                                            [items]="expireMonths"
                                                            (changeEvent)="updateFormField($event)"
                                                            [value]="getFormValue('expireMonth')"
                                                            [tabindex]="8"
                                                        ></select-input>
                                                    </div>
                                                </div>
                                                <div class="col pe-0">
                                                    <div class="form-group">
                                                        <select-input
                                                            name="expireYear"
                                                            [label]="'one-time-payment.expiration-year' | translate"
                                                            [emptyLabel]="'one-time-payment.yyyy' | translate"
                                                            [items]="expireYears"
                                                            (changeEvent)="updateFormField($event)"
                                                            [value]="getFormValue('expireYear')"
                                                            [tabindex]="9"
                                                        ></select-input>
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="isCreditCardPayment()" class="form-group">
                                                <int-input 
                                                    faIcon="credit-card"
                                                    name="cvv"
                                                    required="true"
                                                    [label]="'CVV'"
                                                    pattern="^\d{3}$"
                                                    [maxLength]="3"
                                                    [value]="getFormValue('cvv')"
                                                    (changeEvent)="updateFormField($event)"
                                                    [tabindex]="10"
                                                ></int-input>
                                            </div>
                                            <div *ngIf="isCreditCardPayment()" class="form-group">
                                                <int-input 
                                                    faIcon="map-marker-alt"
                                                    name="customerZip"
                                                    required="true"
                                                    [label]="'Billing Postal Code'"
                                                    [maxLength]="5"
                                                    pattern="^\d{5}$"
                                                    [value]="getFormValue('customerZip')"
                                                    (changeEvent)="updateFormField($event)"
                                                    [tabindex]="11"
                                                ></int-input>
                                            </div>
                                        </div>
                                        <!-- // Credit Card Payment -->
        
                                        <!-- Bank Account Payment -->
                                        <div *ngIf="isBankAccountPayment()">
                                            <div class="form-group">
                                                <int-input 
                                                    name="routingNumber" 
                                                    required="true"
                                                    [label]="'Routing Number'"
                                                    [maxLength]="9"
                                                    [isInValid]="isInValidABA"
                                                    invalidMessage={{inValidABAMessage}}
                                                    [value]="getFormValue('routingNumber')"
                                                    (changeEvent)="updateFormField($event)"
                                                    [isRoutingNumber]="true"
                                                    [tabindex]="7"
                                                    [extraSpacing]="true"
                                                ></int-input>
                                            </div>
                                            <div class="form-group">
                                                <int-input 
                                                    name="bankAccount" 
                                                    required="true"
                                                    [label]="'Account Number'"
                                                    [minLength]="2"
                                                    [maxLength]="17"
                                                    [value]="getFormValue('bankAccount')"
                                                    (changeEvent)="updateFormField($event)"
                                                    [tabindex]="8"
                                                    [extraSpacing]="true"
                                                ></int-input>
                                            </div>
                                            <div class="get-routing-number">
                                                <button type="buton" class="btn btn-link" (click)="openRoutingExample()">{{'global.where-is-routing-number' | translate}}</button>
                                            </div>
                                        </div>
                                        <!-- // Bank Account Payment -->
                                        <div *ngIf="form.controls['payTypeId'].value">
                                            <div class="form-group">
                                                <currency-input 
                                                    name="payAmount" 
                                                    required="true"
                                                    [label]="'Amount'"
                                                    [value]="getFormValue('payAmount')"
                                                    (changeEvent)="updateFormField($event)"
                                                    [tabindex]="12"
                                                ></currency-input>
                                                <p>{{'biller-payment.max-amount' | translate}}{{maxAmount()}}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex align-items-center" *ngIf="stepIndex === 2">
                            <div class="col mw-100">
                                <div *ngIf="creditCardDeclined">
                                    <ngb-alert 
                                        type="danger" 
                                        (closed)="closeAlert()"
                                    >
                                        {{creditCardDeclinedMessage}}
                                    </ngb-alert>
                                </div>
                                <div *ngIf="paymentFailed">
                                    <ngb-alert 
                                        type="danger" 
                                        (closed)="closeAlert()"
                                    >
                                        {{paymentFailedMessage}}
                                    </ngb-alert>
                                </div>
                                <p>
                                    <span class="title">{{'global.biller-name' | translate}}</span>
                                    <span class="amount">{{billerDetails.billerName}}</span>
                                </p>
                                <p>
                                    <span class="title">{{'biller-payment.biller-account-number' | translate}}</span>
                                    <span class="amount">{{form.controls['accountNumber'].value}}</span>
                                </p>
                                <p>
                                    <span class="title">{{'global.last-name' | translate}}</span>
                                    <span class="amount">{{form.controls['customerLastName'].value}}</span>
                                </p>
                                <p *ngIf="isBankAccountPayment()">
                                    <span class="title">{{'biller-payment.routing-number' | translate}}</span>
                                    <span class="amount">*****{{form.controls['routingNumber'].value.substr(form.controls['routingNumber'].value.length - 4)}}</span>
                                </p>
                                <p *ngIf="isBankAccountPayment()">
                                    <span class="title">{{'biller-payment.account-number' | translate}}</span>
                                    <span class="amount">******{{form.controls['bankAccount'].value.substr(form.controls['bankAccount'].value.length - 4)}}</span>
                                </p>
                                <p class="detailsSpacing">
                                    <span class="title">{{'biller-payment.applied-to-account' | translate}}</span>
                                    <span class="amount">{{currencyFormat(form.controls['payAmount'].value)}}</span>
                                </p>
                                <p>
                                    <span class="title">{{'biller-payment.convenience-fee' | translate}}</span>
                                    <span class="amount">{{convenienceFee()}}</span>
                                </p>
                                <p>
                                    <span class="title">{{'biller-payment.total-payment-amount' | translate}}</span>
                                    <span class="amount">{{totalPaymentAmount()}}</span>
                                </p>
                            </div>
                        </div>
                        <div class="button-row">
                            <div *ngIf="stepIndex === 2" class="align-center">
                                <label class="checkbox-inline">    
                                    <div class="row">
                                        <div class="col-1">
                                            <input #approvedCheckbox type = "checkbox" class="checkmark form-check-input" checked="checked" [checked]="isApproved"
                                                (change)="isApproved = !isApproved" name ="approved">
                                        </div>
                                        <div class="col-11 text-white">
                                            <small *ngIf="isCardPayment()">{{'biller-payment.authorize-charge' | translate}} {{totalPaymentAmount()}}</small>
                                            <small *ngIf="isBankAccountPayment()">{{'biller-payment.authorize-one-time' | translate}} {{totalPaymentAmount()}} {{'biller-payment.account-ending' | translate}} {{lastFourOfAccount()}} {{'biller-payment.on-or-after' | translate}} {{todaysDate()}}.  {{'biller-payment.questions' | translate}} 
                                                <small class="noWrap">{{'biller-payment.number' | translate}}</small>
                                            </small>
                                            <small *ngIf="isApplePayPayment()">{{'biller-payment.authorize-charge' | translate}} {{totalPaymentAmount()}}</small>
                                        </div>
                                    </div>
                                </label>
                            </div>
                            <div>
                                <button class="btn btn-danger" (click)="previous()" [tabindex]="13">{{'global.back' | translate}}</button>
                                <!-- Step 1 Buttons -->
                                <button 
                                    class="btn btn-primary submit-button" 
                                    (click)="next()"
                                    [disabled]="!form.valid || isInValidCC || isInValidABA || !billerValidated || gettingBalance || badAmount"
                                    *ngIf="stepIndex < 2"
                                    [tabindex]="14"
                                >{{'global.next' | translate}}</button>
                                <!-- Step 2 Buttons -->
                                <button 
                                    class="btn btn-primary submit-button" 
                                    (click)="makeOneTimePayment()"
                                    [disabled]="!form.valid || isInValidCC || isInValidABA || !billerValidated || !isApproved || paymentAttempted"
                                    *ngIf="stepIndex === 2 && !paymentProcessing"
                                    [tabindex]="14"
                                >{{'global.submit-payment' | translate}}</button>
                                <app-spinner class="mr-48" *ngIf="paymentProcessing"></app-spinner>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row" *ngIf="stepIndex >= 3">
            <div class="col-lg-11 col-xl-11 col-xxl-10 col-md-11 mx-auto">
                <div class="col-xxl-5 col-xl-5 col-lg-5 col-md-6 left-column float-left">
                    <div class="transaction-details">
                        <div id="receiptContent">
                            <h3>{{'global.transaction-receipt' | translate}}</h3>
                            <p>
                                <span class="title d-table-row">{{'global.payment-made-to' | translate}}:</span>
                                <span class="amount">{{billerDetails.billerName}}</span>
                            </p>
                            <p>
                                <span class="title">{{'global.date' | translate}}:</span>
                                <span class="amount">{{paymentDateFormatted}}</span>
                            </p>
                            <p>
                                <span class="title">{{'global.time' | translate}}:</span>
                                <span class="amount">{{paymentTime}}</span>
                            </p>
                            <p>
                                <span class="title">{{'global.method' | translate}}:</span>
                                <span class="amount">{{paymentMethod}}</span>
                            </p>
                            <p>
                                <span class="title">{{'global.account' | translate}}:</span>
                                <span class="amount">{{form.controls['accountNumber'].value}}</span>
                            </p>
                            <p>
                                <span class="title">{{'global.applied-to-bill' | translate}}:</span>
                                <span class="amount">{{currencyFormat(form.controls['payAmount'].value)}}</span>
                            </p>
                            <p>
                                <span class="title">{{'global.fee' | translate}}:</span>
                                <span class="amount">{{convenienceFee()}}</span>
                            </p>
                            <p>
                                <span class="title">{{'global.total-amount' | translate}}:</span>
                                <span class="amount">{{totalPaymentAmount()}}</span>
                            </p>
                            <p>
                                <span class="title">{{'global.transaction-id' | translate}}:</span>
                                <span class="amount">{{paymentId}}</span>
                            </p>
                            <p>
                                <span class="title d-table-row">{{'global.reference-number' | translate}}:</span>
                                <span class="amount">{{referenceNumber}}</span>
                            </p>
                            <p *ngIf="authCode">
                                <span class="title">{{'global.authorization-code' | translate}}:</span>
                                <span class="amount">{{authCode | uppercase}}</span>
                            </p>
                        </div>
                        <div class="align-center">
                            <button type="button" class="btn btn-light" (click)="print($event)"><fa-icon [icon]="['fas', 'print']"></fa-icon> {{'global.print' | translate}}</button>
                        </div>
                        <div class="align-center">
                            <p class="footnoteText">{{'biller-payment.hard-copy-recipt' | translate}}</p>
                        </div>
                    </div>
                </div>
                <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 mw-100 form-body float-right">
                    <div class="d-flex align-items-center" *ngIf="stepIndex === 3">
                        <div class="col mw-100">
                            <h2 class="align-center">{{'global.thank-you' | translate}}!</h2>
                            <p class="align-center">{{'one-time-payment.create-account' | translate}} <b>{{'one-time-payment.faster' | translate}}</b>{{'global.question-mark' | translate}}</p>
                            <p>
                                <a routerLink="/signup"><button class="btn btn-lg btn-yes">{{'global.yes' | translate}}</button></a>
                                <a routerLink="/"><button class="btn btn-lg btn-no">{{'global.no' | translate}}</button></a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
    <!-- MD+ display only end -->
</paper>