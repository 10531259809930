
    <div class="modal-header">
        <h4 class="modal-title">{{'wheres-routing-number.routing-and-account' | translate}}</h4>
    </div>
    <div class="modal-body">
        <div class="image align-center">
            <img src="assets/images/routing-account-example.png" />
        </div>
    </div>
    <div class="modal-footer">
        <div class="row">
            <div class="col-12 align-right pe-0">
                <button 
                    type="button" 
                    class="btn btn-light" 
                    (click)="activeModal.close()"
                >{{'global.close' | translate}}</button>
            </div>
        </div>
    </div>