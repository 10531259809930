import { Component, OnInit } from '@angular/core';
import { trigger, style, transition, animate, keyframes, query, stagger, state } from "@angular/animations";
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { MessageReporter } from '../../services/messages/message-reporter.service';

interface RibbonInterface {
    id?: string;
    closable?: boolean;
    msg: string;
    state: string;
    timeout?: number;
    type?: string;
}

@Component({
    selector: 'ribbon',
    templateUrl: './ribbon.component.html',
    styleUrls: ['./ribbon.component.scss'],
    animations: [
      trigger('messages', [
        state('in', style({
            opacity: 1,
            height: 'initial',
            minHeight: '48px',
        })),
        state('out', style({
            opacity: 0,
            height: '0',
            minHeight: '0',
        })),
        transition('in => out', animate('200ms')),
        transition('* => in', animate('200ms')),
      ])
  
    ]
})
export class RibbonComponent implements OnInit {
    faTimesCircle = faTimesCircle;
    ribbons: RibbonInterface[] = [];
    
    constructor(
        public reporter: MessageReporter
    ) { }
    
    closeRibbon(ribbon: string) {
        this.reporter.removeMessage(ribbon);
    }

    ngOnInit() {
        this.reporter.messagesUpdated.subscribe((data: any) => {
            const ribbons: any[] = [];
            
            Object.keys(data).map((row: any) => {
                ribbons.push(data[row]);
            });

            this.ribbons = ribbons;
        });

    }
}
