import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';

import { UserService } from '../../../services/user/user-service.service';
import { MessageReporter } from '../../../services/messages/message-reporter.service';

import { UserInterface } from '../../../interfaces/user.interface';

@Component({
    selector: 'app-receipt-method',
    templateUrl: './receipt-method.component.html',
    styleUrls: ['./receipt-method.component.scss']
})
export class ReceiptMethodComponent implements OnInit {
    @Input() isDashboard: boolean;

    @ViewChild('printCheckbox') printCheckbox: ElementRef;
    @ViewChild('emailCheckbox') emailCheckbox: ElementRef;
    @ViewChild('smsCheckbox') smsCheckbox: ElementRef;
    
    user: UserInterface;
    showEmail: boolean;
    showPhone: boolean;

    form: UntypedFormGroup = new UntypedFormGroup({
        email: new UntypedFormControl('', [Validators.required]),
        phoneNumber: new UntypedFormControl('', [Validators.required]),
        printReceipt: new UntypedFormControl(),
        emailReceipt: new UntypedFormControl(),
        smsReceipt: new UntypedFormControl(),
    });

    constructor(
        private reporter: MessageReporter,
        private userService: UserService,
    ) { }

    async getPersonalInfo(): Promise<any> {
        try {
            this.user = await this.userService.getPersonalInfo();
            const email = sessionStorage.getItem('email');

            this.form.get('printReceipt').setValue(this.user.printReceipt);
            this.form.get('emailReceipt').setValue(this.user.emailReceipt);
            this.form.get('email').setValue(email);
            this.form.get('smsReceipt').setValue(this.user.smsReceipt);
            this.form.get('phoneNumber').setValue(this.user.phoneNumber);

            this.setEntryFields();
            this.setSwitches();
        } catch (error) {
            console.log(error);
        }
    }

    async savePersonalInfo(): Promise<any> {
        try {
            const values = this.form.value;
            const user = JSON.parse(JSON.stringify(this.user));
            console.log(user);
            // Updates and Adds to the User object before sending
            user.printReceipt = values.printReceipt;
            user.smsReceipt = values.smsReceipt;
            user.emailReceipt = values.emailReceipt;

            user.aspNetUserId = this.userService.userId();

            user.email = values.email;
            user.phoneNumber = values.phoneNumber.replace(/-/g,'');
            user.fastPassPIN = (!user.fastPassPIN || user.fastPassPIN === null) ? '0' : user.zip.toString();
            user.zip = (!user.zip || user.zip === null) ? '' : user.zip.toString();

            // Deletes from the User object before sending
            delete user.listOfBillerAccountInfo;
            delete user.languageId;
            delete user.customerId;

            // Send the User object
            const response = await this.userService.savePersonalInfo(user);

            if ( response ) {
                this.reporter.showSuccessMessage('Profile was successfully saved');
                this.getPersonalInfo();
            }

        } catch (error) {
            console.log(error);
        }
    }

    getFormValue(field: string) {
        return this.form.get(field).value;
    }

    updateFormField($event: any, setEntryFields: boolean) {
        var name;
        var value;
        if ($event.name) {
            name = $event.name;
            value = $event;
            this.form.controls[name] = value;    
        } else {
            name = $event.target.name;
            value = $event.target.checked;
            this.form.get(name).setValue(value);
        }
        this.form.updateValueAndValidity();

        if (setEntryFields) {
            this.setEntryFields();
        }
    }

    setEntryFields() {
        this.showEmail = this.form.value.emailReceipt;
        this.showPhone = this.form.value.smsReceipt;
    }

    setSwitches() {
        this.printCheckbox.nativeElement.checked = this.form.value.printReceipt;
        this.emailCheckbox.nativeElement.checked = this.form.value.emailReceipt;
        this.smsCheckbox.nativeElement.checked = this.form.value.smsReceipt;
    }

    ngOnInit(): void {
        this.getPersonalInfo();
    }

}
