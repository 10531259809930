<span class="textLabel">{{label}}</span><span *ngIf="required" class="textLabel"> *</span>
<div class="input-group" [formGroup]="form">
    <div class="input-group-prepend" *ngIf="faIcon && faIcon !== ''">
        <span class="input-group-text" id="basic-addon1"><fa-icon [icon]="[faPack, faIcon]"></fa-icon></span>
    </div>
    <input 
        type="password" 
        formControlName="formInput" 
        placeholder={{placeholder}}
        value={{value}}
        [className]="isDirtyandInvalid() ? 'form-control is-invalid' : 'form-control'"
        (ngModelChange)="onChange()"
        minLength={{minLength}}
        maxLength={{getMaxLength()}}
    />
    <div class="invalid-feedback" *ngIf="isDirtyandInvalid()" [innerHTML]="inputErrorMessage()">
    </div>
    <div class="invalid-feedback" *ngIf="notMinLength()">
        {{minLengthMessage}}
    </div>
    <div class="invalid-feedback" *ngIf="notMaxLength()">
        {{maxLengthMessage}}
    </div>
</div>