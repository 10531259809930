import { Component, Input, OnInit } from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import * as moment from 'moment';

import { PaymentTypes } from '../../../services/payment-types/payment-types.data';

@Component({
    selector: 'app-payment-receipt',
    templateUrl: './payment-receipt.component.html',
    styleUrls: ['./payment-receipt.component.scss']
})
export class PaymentReceiptComponent implements OnInit {

    @Input() payment: any;
    @Input() name: string;

    payTypes: any[] = PaymentTypes;

    constructor(
        public activeModal: NgbActiveModal,
    ) { }

    paymentDate(a: any): string {
        const b = moment(a).format('MM/DD/YYYY');
        return b;
    }

    paymentTime(a: any): string {
        const b = moment(a).format('hh:mm:ss a');
        return b;
    }

    paymentMethod(): string {
        const paymentType = this.payTypes.filter((row: any) => {
            return (row.id === this.payment.payType);
        });
        return paymentType[0]['name'];
    }

    currencyFormat(amount: any) {
        return '$' + Number(amount).toFixed(2);
    }

    getTotalPaid(): string {
        const total = Number(this.payment.paymentAmount) + Number(this.payment.customerFee);

        return this.currencyFormat(total);
    }

    print($event: any): void {
        const html = document.getElementById('receiptContent').innerHTML;
        document.getElementById('printReceipt').innerHTML = '<div id="receiptForPrint"><h3>Transaction Receipt</h3>' + html + '</div>';

        window.onafterprint = function () {
            document.getElementById('printReceipt').innerHTML = '';
        }

        window.print();

    }

    ngOnInit(): void {
    }

}
