<app-navbar title="Make a Payment"></app-navbar>

<form class="m-4" [formGroup]="form">
    <div id="payment-wrapper" [className]="!selectedPayMethod ? '' : 'paymethod-selected'">
        <h1 *ngIf="paymentStep > 0" class="thin align-center">{{'global.pay-now' | translate}}</h1>
        <!-- User is on first step. Keep the layout a little basic -->
        <div *ngIf="paymentStep === 0">
            <div class="row">
                <div class="col-md-5 left-column">
                    <h2 class="thin align-center">{{'global.pay-now' | translate}}</h2>
                    <!-- Biller Details -->
                    <div class="getting-biller-details" *ngIf="gettingBillerDetails || gettingBalance">
                        <app-roller></app-roller>
                    </div>
                    <div class="biller-details" *ngIf="billerDetails  && (!gettingBillerDetails || !gettingBalance)">
                        <div class="logo-image">
                            <img src="{{billerImageUrl}}?billerId={{billerDetails.billerId}}" />
                        </div>
                        <p class="biller-name" *ngIf="billerDetails.billerName && billerDetails.billerName !== ''">{{billerDetails.billerName}}</p>
                        <p *ngIf="billerDetails.accountNumber">{{billerDetails.accountNumber}}</p>
                        <div>
                            <div *ngIf="balanceDue && !gettingBalance">
                                <div class="divider"></div>
                                <div class="biller-balance">
                                    <p>{{'global.currency-symbol' | translate}}{{balanceDue}}</p>
                                    <small>{{'global.current-balance' | translate}}</small>
                                </div>
                            </div>
                            <div class="getting-biller-details" *ngIf="!balanceDue && gettingBalance">
                                <div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                            </div>
                        </div>
                    </div>
                </div> 
                <div class="col-md-7 right-column">
                    <div class="select-paymethod">
                        <h2>{{'biller-payment.pay-method' | translate}}</h2>
                        <p>{{'biller-payment.save-pay-method' | translate}}</p>
                    </div>
                    <div *ngIf="acceptedPaymentTypes && paymentTypesSet">
                        <app-payment-methods 
                            color="light" 
                            [accepted]="acceptedPaymentTypes"
                            [billerFees]="billerFees"
                            [showApplePay]="showApplePay"
                            (onPayMethodSelected)="selectPayMethod($event)"
                        ></app-payment-methods>
                    </div>
                </div>
            </div>
        </div>
        <!-- User moved past first step. Make it more complicated -->
        <div *ngIf="paymentStep > 0">
            <div class="row">
                <div class="col-md-4 left-column" *ngIf="paymentStep !== 3">
                    <!-- Biller Details -->
                    <div class="biller-details row" *ngIf="billerDetails && paymentStep < 3">
                        <div class="col-12 col-sm-4 col-md-12">
                            <p class="title" *ngIf="billerDetails.type && billerDetails.type !== ''">{{billerDetails.type}}</p>
                            <p class="balance-details" *ngIf="billerDetails.balance && billerDetails.balance !== ''">{{'global.current-balance' | translate}}:{{'global.currency-symbol' | translate}}{{billerDetails.balance}}</p>
                            <div class="logo-image">
                                <img src="{{billerImageUrl}}?billerId={{billerDetails.billerId}}" />
                            </div>
                            <p class="biller-name" *ngIf="billerDetails.billerName && billerDetails.billerName !== ''">{{billerDetails.billerName}}</p>
                        </div>
                        <!-- // Biller Details -->
                        <div class="divider d-none d-md-block"></div>
                        <div *ngIf="balanceDue !== ''" class="col-12 col-sm-4 col-md-12">
                            <div class="biller-balance">
                                <p>{{'global.currency-symbol' | translate}}{{balanceDue}}</p>
                                <small>{{'global.current-balance' | translate}}</small>
                            </div>
                        </div>
                        <div class="divider d-none d-md-block"></div>
                        <!-- Pay Method Details -->
                        <div class="selected-paymethod-details col-12 col-sm-4 col-md-12" *ngIf="selectedPayMethod">
                            <div class="paymethod-details" *ngIf="selectedPayMethod.payTypeId === 5">
                                <fa-icon [icon]="['fas', 'credit-card']"></fa-icon>
                                <p class="payment-type">{{'global.credit-card' | translate}}</p>
                                <p class="last-four">{{'global.ending-in' | translate}} {{selectedPayMethod.ccLast4}}</p>
                            </div>
                            <div class="paymethod-details" *ngIf="selectedPayMethod.payTypeId === 6">
                                <fa-icon [icon]="['fas', 'credit-card']"></fa-icon>
                                <p class="payment-type">Debit Card</p>
                                <p class="last-four">Ending in {{selectedPayMethod.ccLast4}}</p>
                            </div>
                            <div class="paymethod-details" *ngIf="selectedPayMethod.payTypeId === 3 || selectedPayMethod.payTypeId === 4">
                                <fa-icon [icon]="['fas', 'money-check-alt']"></fa-icon>
                                <p class="payment-type">{{selectedPayMethod.paymentTypeNameInLanguage}}</p>
                                <p class="last-four">{{'global.ending-in' | translate}} {{selectedPayMethod.bankAcctLast4}}</p>
                            </div>
                            <div class="paymethod-details" *ngIf="selectedPayMethod.payTypeId === 12">
                                <fa-icon [icon]="['fab', 'apple']"></fa-icon>
                                <p class="payment-type">{{selectedPayMethod.paymentTypeNameInLanguage}}</p>
                            </div>
                        </div>
                        <!-- // Pay Method Details -->
                    </div>
                </div>
                <div class="col-md-6 left-column" *ngIf="paymentStep === 3">
                    <div id="transactionReceipt" class="transaction-receipt">
                        <div id="receiptContent">
                            <h3>{{'global.transaction-receipt' | translate}}</h3>
                            <p>
                                <span class="title">{{'global.payment-made-to' | translate}}:</span>
                                <span class="amount">{{selectedBiller.billerName}}</span>
                            </p>
                            <p>
                                <span class="title">{{'global.date' | translate}}:</span>
                                <span class="amount">{{paymentDateFormatted}}</span>
                            </p>
                            <p>
                                <span class="title">{{'global.time' | translate}}:</span>
                                <span class="amount">{{paymentTime}}</span>
                            </p>
                            <p>
                                <span class="title">{{'global.method' | translate}}:</span>
                                <span class="amount">{{paymentMethod}}</span>
                            </p>
                            <p>
                                <span class="title">{{'global.account' | translate}}:</span>
                                <span class="amount">{{this.selectedBiller.accountNumber}}</span>
                            </p>
                            <p>
                                <span class="title">{{'global.applied-to-bill' | translate}}:</span>
                                <span class="amount">{{paymentAmount()}}</span>
                            </p>
                            <p>
                                <span class="title">{{'global.fee' | translate}}:</span>
                                <span class="amount">{{customerFeeAmount()}}</span>
                            </p>
                            <p>
                                <span class="title">{{'global.total-amount' | translate}}:</span>
                                <span class="amount">{{totalPaymentAmount()}}</span>
                            </p>
                            <p>
                                <span class="title">{{'global.transaction-id' | translate}}:</span>
                                <span class="amount">{{paymentId}}</span>
                            </p>
                            <p>
                                <span class="title">{{'global.reference-number' | translate}}:</span>
                                <span class="amount">{{referenceNumber}}</span>
                            </p>
                            <p *ngIf="authCode">
                                <span class="title">{{'global.authorization-code' | translate}}:</span>
                                <span class="amount">{{authCode | uppercase}}</span>
                            </p>
                        </div>
                        <div class="align-center">
                            <button type="button" class="btn btn-light" (click)="print($event);"><fa-icon [icon]="['fas', 'print']"></fa-icon>{{'global.print' | translate}}</button>
                        </div>
                        <div class="align-center">
                            <p class="footnoteText">{{'biller-payment.hard-copy-recipt' | translate}}</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-8 right-column h-100" *ngIf="paymentStep !== 3">
                    <div class="step step2" *ngIf="paymentStep === 1">
                        <div class="form-body">
                            <div class="biller-pay-amount-input">
                                <input 
                                    type="text" 
                                    name="paymentAmount" 
                                    [label]="'Amount'"
                                    mask="separator" 
                                    thousandSeparator=","
                                    formControlName="paymentAmount"
                                    (ngModelChange)="onChange()"
                                    (blur)="onBlurred()"
                                    [tabindex]="1"
                                />
                                <label>$</label>
                            </div>
                            <br />
                            <p>{{'biller-payment.how-much-pay' | translate}} {{'biller-payment.max-amount' | translate}}{{'global.currency-symbol' | translate}}{{maxAmount}}.</p>
                        </div>
                    </div>
                    <div class="step step3 w-100" *ngIf="paymentStep === 2">
                        <div class="form-body">
                            <div *ngIf="paymentError">
                                <ngb-alert 
                                    type="danger" 
                                    (closed)="closeAlert()"
                                >
                                    {{paymentErrorText}}
                                </ngb-alert>
                            </div>
                            <h2>{{'biller-payment.details' | translate}}</h2>
                            <div class="details-totals">
                                <p>
                                    <span class="title">{{'global.biller-name' | translate}}</span>
                                    <span class="amount">{{billerDetails.billerName}}</span>
                                </p>
                                <p>
                                    <span class="title">{{'biller-payment.biller-account-number' | translate}}</span>
                                    <span class="amount">{{selectedBiller.accountNumber}}</span>
                                </p>
                                <p *ngIf="isBankAccountPayment()">
                                    <span class="title">{{'biller-payment.routing-number' | translate}}</span>
                                    <span class="amount">*****{{selectedPayMethod.routingNumber.substr(selectedPayMethod.routingNumber.length - 4)}}</span>
                                </p>
                                <p *ngIf="isBankAccountPayment()">
                                    <span class="title">{{'biller-payment.account-number' | translate}}</span>
                                    <span class="amount">******{{selectedPayMethod.bankAcctLast4}}</span>
                                </p>
                                <p class="detailsSpacing">
                                    <span class="title">{{'biller-payment.applied-to-account' | translate}} </span>
                                    <span class="amount">{{paymentAmount()}}</span>
                                </p>
                                <p>
                                    <span class="title">{{'biller-payment.convenience-fee' | translate}} </span>
                                    <span class="amount">{{customerFeeAmount()}}</span>
                                </p>
                                <p>
                                    <span class="title">{{'biller-payment.total-payment-amount' | translate}} </span>
                                    <span class="amount">{{totalPaymentAmount()}}</span>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="button-row">
                        <div *ngIf="paymentStep === 2" class="align-center detailsSpacing">
                            <small *ngIf="isCreditOrDebitPayment()">{{'biller-payment.authorize-charge' | translate}} {{totalPaymentAmount()}}</small>
                            <small *ngIf="isBankAccountPayment()">{{'biller-payment.authorize-one-time' | translate}} {{totalPaymentAmount()}} {{'biller-payment.account-ending' | translate}} {{selectedPayMethod.bankAcctLast4}} {{'biller-payment.on-or-after' | translate}} {{todaysDate()}}.  {{'biller-payment.questions' | translate}} <small class="noWrap">{{'biller-payment.number' | translate}}.</small></small>
                        </div>
                        <div *ngIf="isCreditPayment() && paymentStep === 1" class="ccMargin">
                            <div class="form-group">
                                <int-input 
                                    faIcon="map-marker-alt"
                                    name="customerZip"
                                    [label]="'global.postal-code' | translate"
                                    required="true"
                                    pattern="^\d{5}$"
                                    [maxLength]="5"
                                    [value]="getFormValue('customerZip')"
                                    (changeEvent)="updateFormField($event)"
                                    [tabindex]="2"
                                ></int-input>
                            </div>
                            <div class="form-group">
                                <int-input
                                    faIcon="credit-card"
                                    name="cvv"
                                    [label]="'biller-payment.cvv' | translate"
                                    required="true"
                                    [value]="getFormValue('cvv')"
                                    [minLength]="3"
                                    [maxLength]="3"
                                    (changeEvent)="updateFormField($event)"
                                    [tabindex]="3"
                                ></int-input>
                            </div>
                        </div>
                        <div *ngIf="paymentStep < 3">
                            <button class="btn btn-danger" (click)="previousStep()" tabindex="4">{{'global.back' | translate}}</button>
                            <button  
                                *ngIf="paymentStep < 2"
                                class="btn btn-primary submit-button" 
                                (click)="nextStep()"
                                [disabled]="form.invalid || badAmount"
                                [tabindex]="5"
                            >{{'global.next' | translate}}</button>
                            <button  
                                *ngIf="paymentStep === 2 && !paymentProcessing"
                                class="btn btn-primary submit-button" 
                                (click)="onSubmit()"
                                [disabled]="form.invalid || paymentAttempted"
                                [tabindex]="5"
                                name="submit-payment"
                            >{{'global.submit-payment' | translate}}</button>
                            <app-spinner *ngIf="paymentProcessing"></app-spinner>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 right-column h-100" *ngIf="paymentStep === 3">
                    <div class="step step4">
                        <div class="form-body">
                            <h1>{{'global.thank-you' | translate}}!</h1>
                            <span *ngIf="user && (user.emailReceipt || user.smsReceipt)">
                                <p>{{'biller-payment.send-receipt' | translate}}:</p>
                                <div *ngIf="user.emailReceipt">
                                    <p class="receipt-method">{{email}}</p>
                                </div>
                                <div *ngIf="user.smsReceipt">
                                    <p class="receipt-method">{{formatPhoneNumber(user.phoneNumber)}}</p>
                                </div>
                            </span>
                            <p>
                                <button class="btn btn-primary doneButton" (click)="goToDashboard()">{{'global.done' | translate}}</button>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>