import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { MessageReporter } from 'src/app/services/messages/message-reporter.service';
import { UserService } from 'src/app/services/user/user-service.service';

@Component({
  selector: 'app-update-phone-number',
  templateUrl: './update-phone-number.component.html',
  styleUrls: ['./update-phone-number.component.scss']
})
export class UpdatePhoneNumberComponent implements OnInit {
  @Input() phoneNumber: any;
  @Input() lastName: any;
  form: UntypedFormGroup = new UntypedFormGroup({
    lastFour: new UntypedFormControl(),
    code: new UntypedFormControl(),
  });
  resetId: string;
  codeSent: boolean;
  hasError: boolean = false;
  errorText: string = '';

  constructor(
    public activeModal: NgbActiveModal,
    private userService: UserService,
    private translate: TranslateService,
    private reporter: MessageReporter,
  ) { }

  getFormValue(field: string): string|number {
    return this.form.get(field).value;
}

updateFormField($event: any): void {
    this.form.controls[$event.name] = $event;
    this.form.updateValueAndValidity();
}

  closeAlert() {
    this.errorText = '';
    this.hasError = false;
  }

  async verifyAccount(): Promise<any> {
    try {
      const values = this.form.value;

      if (!this.codeSent) {
        const body = {
            PhoneNumber: this.phoneNumber,
            LastName: this.lastName,
            AccountLastFour: values.lastFour
        };
        const response = await this.userService.submitRequestPhoneUpdate(body);

        this.resetId = response.id;
        this.codeSent = true;  
      } else {
        const body = {
          Id: this.resetId,
          PhoneNumber: this.phoneNumber,
          Token: values.code
      };
        const response = await this.userService.submitPhoneUpdate(body);
        
        if (response.success) {
          // this.reporter.showSuccessMessage(this.translate.instant("update-phone-number.verification-success"));
          this.activeModal.close(true);
        }
        else
            this.reporter.showErrorMessage(this.translate.instant("update-phone-number.verification-failed"));
      }

    } catch (error) {
        if (error.error_description) {
            this.errorText = error.error_description;
            this.hasError = true;
        }
    }
}

  ngOnInit(): void { }

}
