<app-navbar title="Terms and Conditions"></app-navbar>
<div class="container">
    <div class="content-presentation">
        <section>
            <h1>{{'terms-conditions.user-agreement' | translate}}</h1>
            <p>{{'terms-conditions.i-accept' | translate}}</p>
            
            <p>{{'terms-conditions.special-notice' | translate}} <a href="http://www.dob.texas.gov" target="_new">{{'terms-conditions.website' | translate}}</a>.</p>
            
            <p>{{'terms-conditions.notice-to-oregon' | translate}}</p>
            
            <p>{{'terms-conditions.special-notice-maryland' | translate}}</p>
            
            <p>{{'terms-conditions.notice-to-new-york' | translate}}</p>
            
            <p>{{'terms-conditions.notice-to-district-of-columbia' | translate}} <a href="http://dc.gov" target="_new">{{'terms-conditions.website2' | translate}}</a>; {{'terms-conditions.tty' | translate}}</p>
           
            <p>{{'terms-conditions.alaska-resident' | translate}}</p>
            
            <p>{{'terms-conditions.colorado-customer-notice' | translate}}
            </p>
            <p><strong>{{'terms-conditions.definitons' | translate}}</strong></p>
            <ul>
                <li>{{'terms-conditions.bill-payment-system' | translate}}</li>
                <li>{{'terms-conditions.business-day' | translate}}</li>
                <li>{{'terms-conditions.cutoff-time' | translate}}</li>
                <li>{{'terms-conditions.enrollment-information' | translate}}</li>
                <li>{{'terms-conditions.payee' | translate}}</li>
                <li>{{'terms-conditions.payment-account' | translate}}</li>
                <li>{{'terms-conditions.payment-instruction' | translate}}</li>
                <li>{{'terms-conditions.schedule-payment-date' | translate}}</li>
            </ul>
            
            <p>{{'terms-conditions.payment-service' | translate}}</p>
            
            <p>{{'terms-conditions.usp-does-not-allow' | translate}}</p>
            
            <p><strong>{{'terms-conditions.usp-use-commercially' | translate}}</strong></p>
            <ol type="1">
                <li>{{'terms-conditions.user-payment-account' | translate}}</li>
                <li>{{'terms-conditions.usp-bill-payment' | translate}}
                    </li>
                <li>{{'terms-conditions.payee-mishandles' | translate}}</li>
                <li>{{'terms-conditions.correct-enrollment' | translate}}</li>
                <li>{{'terms-conditions.circumstances' | translate}}</li>
            </ol>
            
            <p>{{'terms-conditions.funds-transmission' | translate}}</p>
            
            <p>{{'terms-conditions.limitation-of-liability' | translate}}</p>
            
            <p>{{'terms-conditions.errors-and-questions' | translate}}</p>
            
            <p>{{'terms-conditions.email' | translate}} <a href="mailto:research@uspayments.com">{{'terms-conditions.research-email' | translate}}</a>.</p>
            
            <p>{{'terms-conditions.telephone' | translate}}</p>
            
            <div>{{'terms-conditions.write-at' | translate}}:</div>
                <address>
                    <strong>{{'terms-conditions.mailing-address' | translate}}</strong><br />
                    {{'terms-conditions.mailing-address2' | translate}}<br />
                    {{'terms-conditions.mailing-address3' | translate}}<br />
                    {{'terms-conditions.mailing-address4' | translate}}<br />
                    {{'terms-conditions.mailing-address5' | translate}}
                </address>
            <p>{{'terms-conditions.charges' | translate}}</p>
            
            <p>{{'terms-conditions.privacy-of-information' | translate}}</p>
            
            <p>{{'terms-conditions.governing-law' | translate}}</p>
            
            <p>{{'terms-conditions.dispute-resolution' | translate}}</p>
            
            <p>{{'terms-conditions.no-claim' | translate}}</p>
            
            <p>{{'terms-conditions.result-of-dispute' | translate}}</p>
            
            <p>{{'terms-conditions.entire-agreement' | translate}}</p>
            
            <p>{{'terms-conditions.acceptance' | translate}}</p>
            
            <h5>{{'terms-conditions.sms' | translate}}</h5>
            
            <p>{{'terms-conditions.carriers' | translate}}</p>
            
            <p>{{'terms-conditions.usp-receipt-messaging' | translate}}</p>
            
            <p>{{'terms-conditions.program-description' | translate}}</p>
            
            <p>{{'terms-conditions.cost' | translate}}</p>
            
            <p>{{'terms-conditions.message-frequency' | translate}}.</p>
            
            <p>{{'terms-conditions.how-to-opt-out' | translate}}</p>
            
            <p>{{'terms-conditions.support' | translate}}</p>
            
            <p>{{'terms-conditions.paysite' | translate}}</p>
            
            <p>{{'terms-conditions.program-description-text-promo' | translate}}</p>
            
            <p>{{'terms-conditions.support-carriers' | translate}}</p>
            
            <p>{{'terms-conditions.cost-no-premium' | translate}}</p>
            
            <p>{{'terms-conditions.message-frequency-opt-in' | translate}}</p>
            
            <p>{{'terms-conditions.how-to-opt-out-text' | translate}}</p>
            
            <p>{{'terms-conditions.support-help' | translate}} <a href="mailto:research@uspayments.com">{{'terms-conditions.research-email' | translate}}</a> {{'terms-conditions.research-number' | translate}}</p>
            
            <p>{{'terms-conditions.privacy-policy' | translate}}</p>
            
            <p>{{'terms-conditions.usp--never-sell' | translate}}</p>

            <p>{{'terms-conditions.usp-never-directly' | translate}}</p>

            <p>{{'terms-conditions.usp-never-distribute' | translate}}</p>

            <p>{{'terms-conditions.questions-email' | translate}} <a href="mailto:research@uspayments.com">{{'terms-conditions.research-email' | translate}}</a>.</p>
        </section>
    </div>
</div>