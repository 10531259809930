<div class="container">
    <div class="row align-center">
        <div id="paySiteLogo">
            <img src="assets/images/logo-lrg.png" />
        </div>
        <h2>Sorteo de $500</h2>
        <p>Estamos regalando $500 al mes de junio a septiembre.</p>
    </div>
    <div class="row pt-3">
      <div class="col-3"></div>
      <div class="col-6">
        <h3 class="align-center">Cómo entrar:</h3>
        <ol>
            <li>Rellene el siguiente formulario</li>
            <li>Use PaySite para pagar una factura</li>
            <li>Asegúrese de usar el mismo número de teléfono cada vez que pague una factura</li>
        </ol>
      </div>
    </div>
    <div class="row pt-3">
      <div class="col align-self-center">
        <iframe class="contestForm" width="100%" height="250" [src]="url"></iframe>
      </div>
    </div>
    <div class="row mt-5">
        <h3 class="align-center"><b>Sorteo PaySite® 2022</b></h3>
        <h6 class="align-center"><b>REGLAS OFICIALES</b></h6>
        <p>Para participar, visite pay.uspayments.com/sweepstakes. Se entregarán un total de tres (3) cheques de $500 a participantes elegibles seleccionados al azar. Solo se entregará un (1) cheque de $500 por período de sorteo según el cronograma de sorteos a continuación. Los usuarios reciben una entrada cuando ingresan su nombre, número de teléfono y correo electrónico en el sitio web de la promoción. Las entradas subsiguientes se pueden obtener utilizando PaySite Kiosk o pay.uspayments.com para pagar una factura. A continuación se muestra un calendario de los sorteos de premios. Los métodos alternativos de entrada se describen a continuación.</p>
        <b class="align-center">Calendario de sorteos:</b>
        <b class="align-center">Sorteo 1 período 7/1/2022-7/31/2022: cheque de $500</b>
        <b class="align-center">Sorteo 2 período 8/1/2022-8/31/2022: cheque de $500</b>
        <b class="align-center">Sorteo 3 período 9/1/2022-9/31/2022: cheque de $500</b>
        <p class="mt-3"><b>NINGUNA COMPRA, TRANSACCIÓN O PAGO DE NINGÚN TIPO ES NECESARIO PARA PARTICIPAR O GANAR. NINGUNA COMPRA, TRANSACCIÓN O PAGO AUMENTARÁ LAS POSIBILIDADES DE GANAR.</b></p>
        <p><b>Esta promoción está destinada únicamente a los residentes de Oklahoma y se interpretará y regirá por la ley de Oklahoma y las leyes de los Estados Unidos, según corresponda. No participe en este sorteo si no es residente de Oklahoma en el momento de la participación.</b></p>
        <h6><b>Quién puede entrar:</b></h6>
        <p>Esta promoción está abierta solo para los residentes del estado de Oklahoma que tengan al menos 18 años de edad al momento de participar. Los ganadores deben demostrar elegibilidad.</p>
        <h6><b>Cómo entrar:</b></h6>
        <p>Visite pay.uspayments.com/sweepstakes-spanish e ingrese su nombre, número de teléfono y correo electrónico. Use PaySite para pagar cualquier factura por entradas adicionales. Los sorteos se basan en el número de teléfono ingresado en el sitio web del Sorteo. Alternativamente, los participantes elegibles pueden participar en el concurso por correo ordinario imprimiendo las palabras "PaySite 2022 Giveaway" y el nombre, la dirección, la ciudad, el estado, el código postal, la dirección de correo electrónico y el número de teléfono del participante en una hoja de papel estándar de 8,5" x 11". y envío por correo en un sobre n.º 10 a través del Servicio Postal de EE. UU. a Pagos de EE. UU., 7380 S. Olympia Avenue Tulsa, OK 74132. Las participaciones no se transfieren de un mes al siguiente. Los participantes que deseen ser incluidos en cada sorteo mensual deben ingresar utilizando uno de los dos métodos de participación enumerados anteriormente durante cada período de sorteo mensual. Las probabilidades de ganar dependen del número de entradas.</p>
        <h6><b>Premios:</b></h6>
        <p>Se entregarán tres (3) cheques de $500 a los ganadores del sorteo al azar. El patrocinador sorteará un ganador por mes en los meses de agosto, septiembre y octubre; después de que termine el período de entrada anterior. Solo tres (3) personas en total ganarán el sorteo.</p>
        <h6><b>Notificación de ganadores:</b></h6>
        <p>Después de los sorteos, los patrocinadores notificarán a los participantes elegibles ganadores por correo electrónico o por teléfono. Los Patrocinadores no son responsables de ninguna inexactitud en la información proporcionada por cualquier participante. Los premios deben entregarse de inmediato (dentro de dos semanas) después de la notificación. Si los Patrocinadores no pueden, utilizando esfuerzos comercialmente razonables, contactar a cualquier participante ganador o completar la entrega utilizando la información de participación provista, los Patrocinadores seleccionarán al azar a otro ganador sustituto.</p>
        <h6><b>Lista de ganadores:</b></h6>
        <p>La lista de ganadores se puede obtener por correo postal imprimiendo las palabras "Lista de ganadores del sorteo de PaySite 2022" y el nombre del solicitante, la dirección, la ciudad, el estado, el código postal, la dirección de correo electrónico y el número de teléfono en una hoja estándar de 8,5 x 11 ” papel y envío por correo en un sobre n.º 10 a través del Servicio Postal de EE. UU. a Pagos de EE. UU., 7380 S. Olympia Avenue Tulsa, OK 74132. El patrocinador proporcionará una lista por correo ordinario o correo electrónico dentro de los 30 días posteriores a la recepción de la solicitud.</p>
        <h6><b class="align-center">CONDICIONES GENERALES</b></h6>
        <b>(a) ESTA PROMOCIÓN ES NULA FUERA DE OKLAHOMA Y DONDE ESTÉ PROHIBIDO O RESTRINGIDO POR LA LEY.</b>
        <p>(b) Al enviar una entrada, el participante acepta y acepta estar sujeto a estas Reglas oficiales y las decisiones de los Patrocinadores, cuyas decisiones serán definitivas.</p>
        <p>(c) Los patrocinadores no son responsables por entradas de correo ilegibles, dañadas, perdidas, tardías o mal dirigidas, o por información incompleta o inadecuada provista en cualquier entrada.</p>
        <p>(d) Los patrocinadores, los afiliados de los patrocinadores y sus respectivos directores, funcionarios, empleados, contratistas, agentes y representantes (en conjunto, el "grupo de patrocinadores") no serán responsables de ninguna lesión o daño relacionado o resultante de la promoción. Al participar en el sorteo, los participantes liberan y eximen irrevocablemente al Grupo Patrocinador de cualquier daño y reclamo de cualquier tipo en relación con la promoción o que resulten de la aceptación, posesión o uso de cualquier premio.</p>
        <p>(e) Al participar en la promoción del Sorteo, los participantes liberan irrevocablemente al Grupo Patrocinador de cualquier responsabilidad y renuncian a todas y cada una de las causas de acción relacionadas con cualquier reclamo, costo, lesión, pérdida o daño de cualquier tipo que surja de o en conexión con esta promoción o aceptación, uso o incapacidad para usar, o participación o incapacidad para participar en la promoción o uso o incapacidad para usar cualquier premio (incluidos, entre otros, reclamos, juicios, lesiones, pérdidas y daños relacionados con lesiones, muerte, daño o destrucción de propiedad, derechos de publicidad o privacidad, difamación o representación bajo una luz falsa, ya sea intencional o no), ya sea bajo una teoría de contrato, agravio (incluyendo negligencia), garantía u otra teoría.</p>
        <p>(f) La responsabilidad fiscal, si la hubiere, es responsabilidad exclusiva del ganador. El Patrocinador presentará los formularios correspondientes ante las autoridades fiscales correspondientes y el ganador deberá certificar que no está sujeto a la retención adicional, o el Patrocinador seguirá las reglas que exigen la retención adicional.</p>
        <p>(g) Los premios no pueden ser sustituidos, transferidos o intercambiados.</p>
        <p>(h) Los patrocinadores no son responsables de los intentos fallidos de notificar a un ganador.</p>
        <p>(i) Al registrarse para participar en el sorteo, los participantes afirman que han leído y aceptado estas Reglas oficiales. Las leyes del Estado de Oklahoma se aplican y rigen la promoción y cualquier reclamo debe presentarse y resolverse en el Estado de Oklahoma.</p>
        <b class="align-center">NO SE REQUIERE COMPRA. Abierto solo para residentes del estado de Oklahoma que tengan 18 años de edad o más al momento de ingresar. Nulo fuera de Oklahoma y donde lo prohíba la ley. Los períodos de participación en el sorteo comienzan el 1 de julio de 2022 y finalizan el 30 de septiembre de 2022.</b>
    </div>
</div>