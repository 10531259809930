<app-navbar title="Profile"></app-navbar>

<div id="profile-wrapper">
    <div id="nav-list" class="h-100">
        <ul class="d-none d-lg-block">
            <li [className]="(selectedContent === 0) ? 'selected' : ''" (click)="selectContent(0)">{{'global.personal-info' | translate}}</li>
            <li [className]="(selectedContent === 1) ? 'selected' : ''" (click)="selectContent(1)">{{'global.change-password' | translate}}</li>
            <li [className]="(selectedContent === 2) ? 'selected' : ''" (click)="selectContent(2)">{{'global.receipt-methods' | translate}}</li>
            <li [className]="(selectedContent === 3) ? 'selected' : ''" (click)="selectContent(3)">{{'global.change-pin' | translate}}</li>
            <li [className]="(selectedContent === 4) ? 'selected' : ''" (click)="selectContent(4)">{{'global.payment-methods' | translate}}</li>
            <!-- <li [className]="(selectedContent === 4) ? 'selected' : ''" (click)="selectContent($event)">Payment Methods</li> -->
        </ul>
        <div id="nav-select" class="d-lg-none d-sm-block d-md-block">
            <div class="form-group row">
                <div [className]="(selectedContent === 0) ? 'selected buttonNav col-4' : 'buttonNav col-4'" (click)="selectContent(0)">
                    <span>{{'global.personal-info' | translate}}</span>
                </div>
                <div class="buttonNav" [className]="(selectedContent === 1) ? 'selected buttonNav col-4' : 'buttonNav col-4'" (click)="selectContent(1)">
                    <span>{{'global.change-password' | translate}}</span>
                </div>
                <div class="buttonNav" [className]="(selectedContent === 2) ? 'selected buttonNav col-4' : 'buttonNav col-4'" (click)="selectContent(2)">
                    <span>{{'global.receipt-methods' | translate}}</span>
                </div>
                <div class="buttonNav" [className]="(selectedContent === 3) ? 'selected buttonNav col-4' : 'buttonNav col-4'" (click)="selectContent(3)">
                    <span>{{'global.change-pin' | translate}}</span>
                </div>
                <div class="buttonNav" [className]="(selectedContent === 4) ? 'selected buttonNav col-4' : 'buttonNav col-4'" (click)="selectContent(4)">
                    <span>{{'global.payment-methods' | translate}}</span>
                </div>
            </div>
        </div>
    </div>
    <div id="nav-content" class="h-100">
        <app-personal-info *ngIf="selectedContent === 0"></app-personal-info>
        <app-password *ngIf="selectedContent === 1"></app-password>
        <app-receipt-method *ngIf="selectedContent === 2"></app-receipt-method>
        <app-pin *ngIf="selectedContent === 3"></app-pin>
        <div *ngIf="selectedContent === 4">
            <h2 class="thin">{{'global.payment-methods' | translate}}</h2>
            <app-payment-methods
                [accepted]="acceptedPaymentTypes"
                [showApplePay]="showApplePay"></app-payment-methods>
        </div>
    </div>
</div>
