import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, OnChanges, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators, AbstractControl } from '@angular/forms';
import { faPhone } from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'phone-input',
    templateUrl: './phone-input.component.html',
    styleUrls: ['./phone-input.component.scss']
})
export class PhoneInputComponent implements OnInit, OnChanges, AfterViewInit {
    @ViewChild('phoneInput') phoneInput: ElementRef;
    
    @Output() changeEvent = new EventEmitter<any>();

    faPhone = faPhone;
    
    @Input() invalidMessage: string;
    @Input() name: string;
    @Input() required: boolean;
    @Input() placeholder: string;
    @Input() requiredMessage: string;
    @Input() label: string;
    @Input() value: any = '';
    @Input() autoFocus: boolean;
    @Input() tabindex: string;
    @Input() disabled: boolean;

    form: UntypedFormGroup = new UntypedFormGroup({
        formInput: new UntypedFormControl(''),
    });

    constructor() { }
        
    ngOnChanges(changes: SimpleChanges) {
        if ( changes.value ) {
            if (changes.value.currentValue && changes.value.currentValue.length === 10 && !changes.value.currentValue.includes('-')) {
                changes.value.currentValue = [changes.value.currentValue.slice(0, 3), '-', changes.value.currentValue.slice(3, 6), '-', changes.value.currentValue.slice(6)].join('');
            }
            const formInput = this.form.controls['formInput'];
            formInput.setValue(changes.value.currentValue);
            formInput.updateValueAndValidity();
        }
    }

    isRequired(): boolean {
        const formInput = this.form.get('formInput');
        const validator = formInput.validator({} as AbstractControl);
        return (validator && validator.required);
    }

    isDisabled(): any {
        if ( !this.disabled ) {
            return false;
        } else {
            return true;
        }
    }

    inputErrorMessage(): string {
        const inputVal = this.form.get('formInput');
        if (this.isRequired() && inputVal.value === '' && (inputVal.dirty || inputVal.touched)) {
            return this.requiredMessageOutput();
        }

        return !inputVal.valid ? this.invalidMessageOutput() : '';
    }

    invalidMessageOutput(): string {
        const label = this.label.replace('*', '');
        const text = `Not a valid ${label}`;
        const message = this.invalidMessage || text;

        return message;
    }

    isDirtyandInvalid(): boolean {
        const formInput = this.form.get('formInput');
        return ( formInput.invalid && ( formInput.dirty || formInput.touched ) );
    }

    onChange(): void {
        const formInput = this.form.get('formInput');
        formInput['name'] = this.name;

        this.changeEvent.emit(formInput);
    }

    requiredMessageOutput(): string {
        const label = this.label.replace('*', '');
        const text = `${label} is a required field`;
        const message = this.requiredMessage || text;

        return message;
    }

    ngOnInit(): void {
        this.form.controls['formInput'].setValue(this.value);
        const validators = [];

        if (this.required) {
            validators.push(Validators.required);
        }

        this.form.controls['formInput'].setValidators(validators);
    }

    ngAfterViewInit(): void {
        if ( this.disabled ) {
            this.phoneInput.nativeElement.disabled = true;
        }
        
        if (this.autoFocus) {
            this.phoneInput.nativeElement.focus();
        }
    }

}
