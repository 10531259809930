
<div class="section">
    <h2>Alerts</h2>
    <ngb-alert type="success">This is an alert</ngb-alert>
    <ngb-alert type="warning">This is an alert</ngb-alert>
    <ngb-alert type="danger">This is an alert</ngb-alert>
</div>
<div class="section">
    <h2>Modal</h2>
    <app-modal></app-modal>
</div>
<div class="section">
    <h2>Date Picker</h2>
    <ngb-datepicker #d></ngb-datepicker>
</div>
<div class="section">
    <h2>Form Fields</h2>
    <app-form-fields></app-form-fields>
</div>
<div class="section">
    <h2>Buttons</h2>
    <app-buttons></app-buttons>
</div>
<div class="section">
    <h2>Tables</h2>
    <app-tables></app-tables>
</div>