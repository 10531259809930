<div id="payment-methods-list">
    <ng-container *ngFor="let payMethod of payMethods">
        <div class="apple-pay-button apple-pay-button-white" *ngIf="payMethod.payTypeId === 12" (click)="payMethodSelected(payMethod)"></div>
    </ng-container>
    <div class={{color}}>
        <div class="saved-methods row">
            <ng-container *ngFor="let payMethod of payMethods">
                <div class="col-sm-6 col-md-12 col-lg-6" *ngIf="!payMethod.isWallet" (click)="payMethodSelected(payMethod)">
                    <div class="paper-tile">
                        <div *ngIf="payMethod.payTypeId === 5 || payMethod.payTypeId === 6">
                            <div class="delete-icon" (click)="deletePayMethod(payMethod)">
                                <fa-icon [icon]="['fas', 'trash']"></fa-icon>
                            </div>
                            <div class="row">
                                <fa-icon class="col-4 col-sm-12 col-md-4 col-lg-12" [icon]="['fas', 'credit-card']"></fa-icon>
                                <div class="col-8 col-sm-12 col-md-8 col-lg-12">
                                    <p class="title" *ngIf="payMethod.payTypeId === 5">{{'global.credit-card' | translate}}</p>
                                    <p class="title" *ngIf="payMethod.payTypeId === 6">{{'global.debit-card' | translate}}</p>
                                    <p class="balance-details">{{'global.ending-in' | translate}} {{payMethod.ccLast4}}</p>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="payMethod.payTypeId === 3 || payMethod.payTypeId === 4">
                            <div class="delete-icon" (click)="deletePayMethod(payMethod)">
                                <fa-icon [icon]="['fas', 'trash']"></fa-icon>
                            </div>
                            <div class="row">
                                <fa-icon class="col-4 col-sm-12 col-md-4 col-lg-12" [icon]="['fas', 'money-check-alt']"></fa-icon>
                                <div class="col-8 col-sm-12 col-md-8 col-lg-12">
                                    <p class="title">{{payMethod.paymentTypeNameInLanguage}}</p>
                                    <p class="balance-details">{{'global.ending-in' | translate}} {{payMethod.bankAcctLast4}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
            <div class="col-sm-6 col-md-12 col-lg-6" (click)="addNewPayMethod()" *ngIf="showAddMethod()">
                <div class="add-tile add-payment-method">
                    <fa-icon [icon]="['fas', 'plus']" class="h-100 align-self-middle"></fa-icon>
                </div>
            </div>
        </div>
    </div>
</div>