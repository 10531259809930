import { Component, Input } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'ngbd-modal-content',
    templateUrl: 'modal-content.component.html'
  })
  export class NgbdModalContent {
    @Input() name: string;
  
    constructor(public activeModal: NgbActiveModal) {}
  }