import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';

import { Router } from '@angular/router';

import { CustomerTransaction } from '../../interfaces/pay-transactions.interface';
import { ChangePasswordInterface } from '../../interfaces/user.interface';

@Injectable({
    providedIn: 'root'
})
export class UserService {

    constructor(
        private api: ApiService,
        private router: Router,
    ) { }

    public userId(): string {
        const userId = sessionStorage.getItem('userId');

        if ( !userId || userId === null ) {
            this.logout();
            return '';

        } else {
            return userId;

        }

    }

    public async logout(): Promise<any> {
        await this.api.logout();
    }

    public async signUp(body: any): Promise<any> {
        try {
            const data = await this.api.post({
                url: 'Auth/Register',
                body,
                requireAuth: false,
                suppressErrors: true,
            });

            return data;

        } catch (error) {
            throw error;
        }
    }

    public async signIn(body: any): Promise<any> {
        try {
            const data = await this.api.post({
                url: 'Auth/Login',
                body,
                requireAuth: false,
                suppressErrors: true,
            });

            sessionStorage.setItem('userId', data.userId);

            return data;

        } catch (error) {
            throw error;
        }
    }

    public async customerTransactions (currentPage: number|string): Promise<CustomerTransaction> {
        try {
            const data = await this.api.get({
                url: 'Payments/CustomerTransactions?userId=' + this.userId() + '&currentPage=' + currentPage,
            });

            return data;
        } catch (error) {
            throw error;
        }
    }

    public async getPersonalInfo(): Promise<any> {
        try {
            const data = await this.api.get({
                url: 'BillerAccounts?userId=' + this.userId()
            });

            return data;
        } catch (error) {
            throw error;
        }
    }

    public async savePersonalInfo(body: any): Promise<any> {
        try {
            const data = await this.api.post({
                url: 'Profile/Update',
                body
            });

            return data;
        } catch (error) {
            throw error;
        }
    }

    public async changePassword(body: ChangePasswordInterface): Promise<any> {
        try {
            const data = await this.api.post({
                url: 'Auth/ChangePassword',
                body
            });

            return data;
        } catch (error) {
            throw error;
        }
    }

    public async sendForgotPassword(body: any): Promise<any> {
        try {
            const data = await this.api.post({
                url: 'Auth/SendForgotPassword',
                requireAuth: false,
                body
            });

            return data;

        } catch (error) {
            throw error;
        }
    }

    public async submitForgotPassword(body: any): Promise<any> {
        try {
            const data = await this.api.post({
                url: 'Auth/SubmitForgotPassword',
                requireAuth: false,
                body
            });

            return data;
        } catch ( error ) {
            throw error;
        }
    }

    public async findLegacyAccount(body: any): Promise<any> {
        try {
            const data = await this.api.post({
                url: 'Auth/SearchForAccountToUpgrade',
                requireAuth: false,
                body
            });

            return data;
        } catch ( error ) {
            throw error;
        }
    }

    public async upgradeAccount(body: any): Promise<any> {
        try {
            const data = await this.api.post({
                url: 'Auth/UpgradeAccount',
                requireAuth: false,
                body
            });

            return data;
        } catch ( error ) {
            throw error;
        }
    }

    public async migrateBillerAccounts(body: any): Promise<any> {
        try {
            const data = await this.api.post({
                url: 'Auth/MigrateBillerAccounts',
                requireAuth: false,
                body
            });

            return data;
        } catch ( error ) {
            throw error;
        }
    }

    public async linkFastPass(body: any): Promise<any> {
        try {
            const url = 'Auth/LinkFastPass';

            body.phoneNumber = Number(body.phoneNumber);
            body.fastPassPin = Number(body.fastPassPin);
            body.languageId = Number(body.languageId);

            const data = await this.api.post({
                url,
                body,
                suppressErrors: true,
            });

            return data;

        } catch (error) {
            throw error;
        }
    }

    public async submitRequestPINReset(body: any): Promise<any> {
        try {
            const data = await this.api.post({
                url: 'Auth/RegisterForPINReset',
                requireAuth: false,
                body
            });

            return data;
        } catch ( error ) {
            throw error;
        }
    }

    public async submitPINReset(body: any): Promise<any> {
        try {
            const data = await this.api.post({
                url: 'Auth/ResetPIN',
                requireAuth: false,
                body
            });

            return data;
        } catch ( error ) {
            throw error;
        }
    }

    public async submitRequestPhoneUpdate(body: any): Promise<any> {
        try {
            const data = await this.api.post({
                url: 'Auth/RegisterForPhoneUpdate',
                requireAuth: false,
                body
            });

            return data;
        } catch ( error ) {
            throw error;
        }
    }

    public async submitPhoneUpdate(body: any): Promise<any> {
        try {
            const data = await this.api.post({
                url: 'Auth/ValidatePhoneUpdateCode',
                requireAuth: false,
                body
            });

            return data;
        } catch ( error ) {
            throw error;
        }
    }
}
