import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-upgrade-modal',
  templateUrl: './upgrade-modal.component.html',
  styleUrls: ['./upgrade-modal.component.scss']
})
export class UpgradeModalComponent implements OnInit {

  email: string;

    constructor(
        public activeModal: NgbActiveModal,
        private router: Router,
    ) { }

    onSubmit() {
      this.activeModal.close(true);
      this.router.navigate(['/upgrade'], { queryParams: { email: this.email }});
    }

    ngOnInit(): void { }

}
