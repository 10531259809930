export class GlobalConstants {
    public static regexCCNumber = `^\\d{13,19}$`;
    public static regexRoutingNumber = `^\\d{9}$`;
    public static payType = {
        Cash: 1,
        Checking: 3,
        Savings: 4,
        Credit: 5,
        Debit: 6,
        ApplePay: 12,
      } as const;
    public static mpoTerminalId = 'WEB';
}