import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { PaymentMethodsComponent } from '../payment-methods.component';
import { PaymentTypesService } from '../../../../services/payment-types/payment-types.service';
import { PayMethodService } from '../../../../services/pay-method/pay-method.service';
import { MessageReporter } from '../../../../services/messages/message-reporter.service';
import { BillersService } from '../../../../services/billers/billers.service';

import { WheresRoutingNumberComponent } from '../../../wheres-routing-number/wheres-routing-number.component';

import { GetCardType, CardLuhnCheck, ABACheckDigitCheck } from '../../../../../config';
import { GlobalConstants } from 'src/app/common/global-constants';
import { AppService } from 'src/app/services/app/app.service';

const makeMonthArray = () => {
    const months: string[] = [];
    for ( let i = 1; i <= 12; i++ ) {
        const m = (i < 10 ) ? '0' + i : i;
        months.push(m.toString());
    }

    return months;
}

const makeYearArray = () => {
    const d = new Date();
    const n = d.getFullYear();

    const years: string[] = [];

    for ( let i = 0; i < 11; i++ ) {
        const y = n + i;
        years.push(y.toString());
    }

    return years;
}

@Component({
    selector: 'app-add-new-pay-method',
    templateUrl: './add-new-pay-method.component.html',
    styleUrls: ['./add-new-pay-method.component.scss']
})
export class AddNewPayMethodComponent implements OnInit {
    @Input() name: any;
    @Input() parent: PaymentMethodsComponent;

    errorText: string = '';
    expireMonths: string[] = makeMonthArray();
    expireYears: string[] = makeYearArray();
    hasError: boolean = false;
    isInValidCC: boolean = false;
    inValidCCMessage: string = 'The Card number you have entered is not valid';
	isInValidABA: boolean = false;
	inValidABAMessage: string = 'The Routing Number you have entered is not valid';
    paymentTypes: any[];
    selectedCreditCardInfo: any;
    timeout: any;
    user: any;
    selectedType: boolean = false;
    isBusy: boolean = false;

    form: UntypedFormGroup = new UntypedFormGroup({
        bankAccount: new UntypedFormControl(''),
        creditCardNumber: new UntypedFormControl(''),
        expirationDate: new UntypedFormControl(''),
        expireMonth: new UntypedFormControl(''),
        expireYear: new UntypedFormControl(''),
        payTypeId: new UntypedFormControl(''),
        routingNumber: new UntypedFormControl(''),
    });

    constructor(
        public activeModal: NgbActiveModal,
        private billersService: BillersService,
        private modal: NgbModal,
        private payMethod: PayMethodService,
        private paymentTypesService: PaymentTypesService,
        private reporter: MessageReporter,
        private appService: AppService,
    ) { }

    getFormValue(field: string): string|number {
        return this.form.get(field).value;
    }

    updateFormField($event: any): void {
        this.form.controls[$event.name] = $event;
        this.form.updateValueAndValidity();

        if ( $event.name === 'creditCardNumber' ) {
            window.clearTimeout(this.timeout);

            this.timeout = window.setTimeout(() => {
                this.validateTheCCNumber();
            }, 0);
        } else if ( $event.name === 'routingNumber' ) {
			window.clearTimeout(this.timeout);
			
			this.timeout = window.setTimeout(() => {
				this.validateTheABANumber();
			}, 0);
        }
    }

    validateTheABANumber(): void {
        this.isInValidABA = false;
		const formInput = this.form.controls['routingNumber'].value;
        if (formInput === '')
            return;
		const checksum: boolean = ABACheckDigitCheck(formInput);
		if (!checksum) {
			this.isInValidABA = true;
			this.inValidABAMessage = 'The routing number you have entered is not valid';
		}
		else
			this.isInValidABA = false;
	}
	
    validateTheCCNumber(): void {
        this.isInValidCC = false;
        const formInput = this.form.controls['creditCardNumber'].value;
        if (formInput === '')
            return;
        const cardType: any[] = GetCardType(formInput);
		const luhnCheck: boolean = CardLuhnCheck(formInput);
		
		if ( cardType.length === 0 || !luhnCheck ) {
			this.isInValidCC = true;
			this.inValidCCMessage = 'The card number you have entered does not appear to be valid';

			return;
		}

        const billerFees = this.parent['_billerFees'];
        const ccRow = billerFees.filter((row: any) => {
            return (row.paymentTypeId === 5);
        });

        if ( ccRow.length > 0 ) {
            const cardList: any[] = ccRow[0]['creditCardsList'];

            const ccDetails = cardList.filter((row: any) => {
                const { creditCardTypeId } = row;
                return ( creditCardTypeId === cardType[0] );
            });

            console.log(ccDetails);
            if ( ccDetails.length > 0 ) {
                this.isInValidCC = false;
                this.selectedCreditCardInfo = ccDetails[0];
            } else {
                this.isInValidCC = true;
                this.inValidCCMessage = 'The credit card type (' + cardType[1] + ') you have entered is not accepted by the biller';
            }

        } else {
            this.isInValidCC = true;
        }
    }

    getPaymentTypes = async () => {
        try {
            const data = await this.paymentTypesService.getPaymentTypes();
            const accepted = this.parent['_accepted'];
            const acceptedIds: any[] = [];
            
            accepted.map((fee: any) => {
                acceptedIds.push(fee.id);
            });

            const types = data.filter((row: any) => {
                return (acceptedIds && acceptedIds.includes(row.id));
            });

            this.paymentTypes = types;
        } catch (error) {
            console.log(error);
        }
    }

    selectPaymentType($event: any): void {
        this.selectedType = true;
        const payTypeId = $event.target.value;
        const bankAccount = this.form.get('bankAccount');
        const routingNumber = this.form.get('routingNumber');
        const creditCardNumber = this.form.get('creditCardNumber');
        const expirationDate = this.form.get('expirationDate');
        const expireMonth = this.form.get('expireMonth');
        const expireYear = this.form.get('expireYear');

        if (this.appService.isPayTypeCheckingSavings(payTypeId)) {
            bankAccount.setValidators([Validators.required])
            creditCardNumber.clearValidators();
            expireMonth.clearValidators();
            expireYear.clearValidators();
            this.validateTheABANumber();

        } else if (this.appService.isPayTypeCreditDebit(payTypeId)) {
            bankAccount.clearValidators();
            routingNumber.clearValidators();
            expireMonth.setValidators([Validators.required]);
            expireYear.setValidators([Validators.required]);
            this.validateTheCCNumber();
        }

        bankAccount.updateValueAndValidity();
        routingNumber.updateValueAndValidity();
        creditCardNumber.updateValueAndValidity();
        expireMonth.updateValueAndValidity();
        expireYear.updateValueAndValidity();
        this.form.updateValueAndValidity();
    }

    closeAlert() {
        this.errorText = '';
        this.hasError = false;
    }

    openRoutingExample() {
        const modalRef = this.modal.open(WheresRoutingNumberComponent, { size: 'md', centered: true });
        modalRef.componentInstance.name = 'WheresRoutingNumber';
    }

    cardPlaceholderText(): string {
        if (this.form.controls['payTypeId'].value) {
            if (this.form.controls['payTypeId'].value == GlobalConstants.payType.Credit) {
                return 'Credit Card Number';
            } else if (this.form.controls['payTypeId'].value == GlobalConstants.payType.Debit) {
                return 'Debit Card Number';
            } else {
                return '';
            }
        } else {
            return '';
        }
    }

    async getBillerAccounts(): Promise<any> {
        try {
            const response = await this.billersService.getBillerAccounts();
            this.user = response;
        } catch (error) {
            console.log(error);
        }
    }

    async onSubmit(): Promise<any> {
        const form: any = this.form.value;

        form.payTypeId = Number(form.payTypeId);
        form.creditCardNumber = form.creditCardNumber.toString();

        // some clean up of properties not needed when submitting to API
        if (this.appService.isPayTypeCreditDebit(form.payTypeId)) {
            const expirationDate = form.expireMonth + '/' + form.expireYear;
            form.expirationDate = expirationDate;
            form.subPayTypeId = this.selectedCreditCardInfo['creditCardTypeId'];
            form.customerId = this.user.customerId;

            delete form.expireMonth;
            delete form.expireYear;
            delete form.bankAccount;
            delete form.routingNumber;

        } else if (this.appService.isPayTypeCheckingSavings(form.payTypeId)) {
            delete form.creditCardNumber;
            delete form.expirationDate;
            delete form.expireMonth;
            delete form.expireYear;
        }

        try {
            this.isBusy = true;
            const response = await this.payMethod.createPaymentMethod(form);
            
            if (response) {
                this.getPaymentTypes();
                this.parent.getPayMethods();
                this.activeModal.close();
                this.reporter.showSuccessMessage('Payment Method was successfully added');    
            } else {
                this.errorText = "There was an error adding you Payment Method. Please ensure all information is accurate and try again.";
                this.hasError = true;
            }
        } catch(error) {
            if ( error.error_description) {
                this.errorText = error.error_description;
                this.hasError = true;
            }
        }

        this.isBusy = false;
    }

    ngOnInit(): void {
        this.getBillerAccounts();
        this.getPaymentTypes();
    }
}