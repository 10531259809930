<div id="history-list">
    <div id="payHistory-empty" *ngIf="!payHistory || (payHistory && payHistory.length === 0)">
        <div class="spinner-wrapper" *ngIf="gettingHistory">
            <app-spinner color="light"></app-spinner>
        </div>
        <p *ngIf="!payHistory || (payHistory && payHistory.length === 0) && !gettingHistory">{{'pay-history.first-payment' | translate}}</p>
    </div>
    <div class="paper-table" *ngIf="payHistory && payHistory.length > 0">
        <table class="table table-striped">
            <thead>
                <tr>
                    <th class="company">{{'pay-history.company' | translate}}</th>
                    <th class="account" class="hide-on-small">{{'upgrade-legacy-account.account' | translate}}</th>
                    <th class="amount">{{'pay-history.amount' | translate}}</th>
                    <th class="date">{{'global.date' | translate}}</th>
                    <th class="pay-method"></th>
                    <!-- <th>&nbsp;</th> -->
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let row of payHistory" (click)="showReceipt(row)">
                    <td class="align-left company"><div class="d-inline-block">{{row.billerName}}</div></td>
                    <td class="align-right hide-on-small">
                        <span>{{row.accountNumber}}</span>
                    </td>
                    <td class="align-right">{{getTotalPaid(row)}}</td>
                    <td class="align-center">{{formatDate(row.paymentDate)}}</td>
                    <td class="align-center">
                        <span *ngIf="row.payType === 5 || row.payType === 6">
                            <fa-icon [icon]="['fas', 'credit-card']"></fa-icon>
                            <div class="last-four last-four-width">{{row.ccLast4}}</div>
                        </span>
                        <span *ngIf="row.payType === 2 || row.payType === 3 || row.payType === 4">
                            <fa-icon [icon]="['fas', 'money-check-alt']"></fa-icon>
                            <div class="last-four last-four-width">{{row.bankAcctLast4}}</div>
                        </span>
                        <span *ngIf="row.payType === 1">
                            <fa-icon [icon]="['fas', 'money-bill-alt']"></fa-icon>
                            <div class="last-four last-four-width"></div>
                        </span>
                        <span *ngIf="row.payType === 12">
                            <fa-icon [icon]="['fab', 'apple-pay']"></fa-icon>
                            <div class="last-four last-four-width">{{row.ccLast4}}</div>
                        </span>
                    </td>
                    <!-- <td class="align-center"><button class="btn btn-secondary" style="min-width: 105px;" (click)="goToBiller(row)">Pay Again</button></td> -->
                </tr>
            </tbody>
        </table>
        <div class="pagination-wrapper d-flex justify-content-between p-2">
            <ngb-pagination 
                [collectionSize]="payHistorySize" 
                [(page)]="page" 
                [pageSize]="pageSize" 
                (pageChange)="refreshPayHistory()"
                [maxSize]="3" 
                [rotate]="false" 
                [ellipses]="true" 
                [boundaryLinks]="false"
                size="sm"
            ></ngb-pagination>
        </div>
    </div>
</div>