<div id="billers-list">
    <div class="row">
        <div class="col-6 col-sm-4 col-lg-6 col-xxl-4" *ngFor="let biller of billers" (click)="goToBiller(biller)">
            <div class="h-100 d-flex align-items-center">
                <div class="paper-tile">
                    <div class="delete-icon" (click)="deleteBillerAccount(biller)">
                        <fa-icon [icon]="['fas', 'trash']"></fa-icon>
                    </div>
                    <div class="logo-image">
                        <img src="{{billerImageUrl}}?billerId={{biller.billerId}}" />
                    </div>
                    <div class="biller-details">
                        <p class="title" *ngIf="biller.billerName && biller.billerName !== ''">{{biller.billerName}}</p>
                        <p class="balance-details" *ngIf="biller.accountNumber">{{biller.accountNumber}}</p>
                        <p class="balance-details" *ngIf="biller.balance && biller.balance !== ''">{{'global.current-balance' | translate}}: ${{biller.balance}}</p>
                        <p class="biller-disabled" *ngIf="!biller.billerEnabled">{{'global.no-web-payments' | translate}}</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-6 col-sm-4 col-lg-6 col-xxl-4">
            <div id="myBillers-addNew" class="add-biller" (click)="openAddBiller()">
                <div class="spinner-wrapper" *ngIf="gettingMyBillers">
                    <app-spinner color="light"></app-spinner>
                </div>
                <fa-icon [icon]="['fas', 'plus']" *ngIf="!gettingMyBillers"></fa-icon>
            </div>
        </div>
    </div>
</div>