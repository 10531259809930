import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { MessageReporter } from '../messages/message-reporter.service';
import { apiUrl } from '../../../config';
import { Router } from '@angular/router';
import { firstValueFrom } from 'rxjs/internal/firstValueFrom';

export interface Params {
    [key: string]: any;
}

class GetOptions {
    url: string;
    params?: Params;
    body?: any;
    requireAuth?: boolean = true;
    suppressErrors?: boolean = false;
}

@Injectable({
    providedIn: 'root'
})
export class ApiService {
    expiredShowing: boolean = false;

    headers = {
        'content-type': 'application/json',
    };

    constructor(
        private http: HttpClient,
        private reporter: MessageReporter,
        private router: Router,
    ) { }

    public async logout(): Promise<any> {
        await this.post({
            url: 'Auth/Logout',
            requireAuth: true,
        }).then(()=> {

            sessionStorage.removeItem('userId');

            window.location.href = '/';
        });
    }

    private isLoggedIn(): boolean {
        return sessionStorage.getItem('userId') != null;
    }

    parseError(res: any) {
        if (res.status === 401) {
            if (!this.expiredShowing) {
                this.expiredShowing = true;
                this.reporter.showErrorMessage('Your session has expired. You will be redirected to the home page. Please login again.')
                
                window.setTimeout(() => {
                    this.logout();
                }, 3000);
            }
        } else {
            console.error('parseError', res.status, res.message, res.error);
            this.reporter.showErrorMessage(res.message);
        }
    }

    public async get(options: GetOptions): Promise<any> {
        try {
            delete this.headers['Authorization'];
            if (options.requireAuth || !options.hasOwnProperty('requireAuth')) {
                if(!this.isLoggedIn()) {
                    this.router.navigate(['login']);
                    return Promise.resolve();
                }
            }
            
            const data = await this.http.get(
                apiUrl + options.url,
                {
                    headers: this.headers,
                }
            ).toPromise();

            return data;

        } catch (error) {
            if (!options.suppressErrors) {
                this.parseError(error);
                //throw error.error;

            } else {
                throw error.error;
            }
        }
    }

    public async post(options: GetOptions): Promise<any> {
        try {
            delete this.headers['Authorization'];
            if (options.requireAuth || !options.hasOwnProperty('requireAuth')) {
                if(!this.isLoggedIn()) {
                    this.router.navigate(['login']);
                    return Promise.resolve();
                }
            }

            const data = await firstValueFrom(this.http.post(
                apiUrl + options.url,
                JSON.stringify(options.body),
                {
                    headers: this.headers,
                },
            ));

            return data;

        } catch (error) {
            if (!options.suppressErrors) {
                this.parseError(error);
                throw error;

            } else {
                throw error;
            }
        }
    }

    public async delete(options: GetOptions): Promise<any> {
        try {
            delete this.headers['Authorization'];
            if (options.requireAuth || !options.hasOwnProperty('requireAuth')) {
                if(!this.isLoggedIn()) {
                    this.router.navigate(['login']);
                    return Promise.resolve();
                }
            }

            const data = await this.http.delete(
                apiUrl + options.url,
                {
                    headers: this.headers
                }
            ).toPromise();

            return data;

        } catch (error) {
            if (!options.suppressErrors) {
                this.parseError(error);
                throw error.error;
            } else {
                throw error.error;
            }
        }
    }

    private async refreshToken(): Promise<any> {
        try {
            const url = apiUrl + 'Auth/RefreshToken';
            const data = await this.http.post(url, JSON.stringify({}), { headers: this.headers }).toPromise();

            sessionStorage.setItem('userId', data['userId']);
        } catch (error) {
            this.parseError(error);
            throw error.error;
        }
    }
}
