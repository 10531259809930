import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { ForgotPasswordComponent } from '../forgot-password/forgot-password.component';

import { BillersService } from '../../services/billers/billers.service';
import { UserService } from '../../services/user/user-service.service';
import { UpgradeModalComponent } from './upgrade-modal/upgrade-modal.component';

@Component({
    selector: 'app-sign-in',
    templateUrl: './sign-in.component.html',
    styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent implements OnInit {
    @Input() modalName: string;
    @Input() fromSignUp: boolean = false;

    billerAccounts: any;
    form: UntypedFormGroup = new UntypedFormGroup({});
    loginError: boolean = false;
    loginActive: boolean = false;

    loginErrorText: string = 'You have entered an invalid email and/or password';

    constructor(
        public activeModal: NgbActiveModal,
        private fb: UntypedFormBuilder,
        private modal: NgbModal,
        private router: Router,
        private userService: UserService,
    ) { }


    updateFormField($event: any): void {
        this.form.controls[$event.name] = $event;
        this.form.updateValueAndValidity();
    }

    closeAltert(): void {
        this.loginError = false;
    }

    getFormValue(field: string): string|number {
        return this.form.get(field).value;
    }

    public async onSubmit(): Promise<any> {
        const values = this.form.value;
        
        this.loginActive = true;

        try {
            const response = await this.userService.signIn(values);
            
            if (response.emailExistsInLegacy && !response.emailExistsInRevamp) {
                this.loginActive = false;
                //prompt to upgrade account
                const modalRef = this.modal.open(UpgradeModalComponent, { size: 'md', centered: true });
                modalRef.componentInstance.name = 'Upgrade';
                modalRef.componentInstance.email = this.form.get('email').value;

                modalRef.result.then((data) => {
                    if (data) {
                        this.activeModal.close();   
                    }
                });
            } else if (response.error_Description) {
                this.loginError = true;
                this.loginActive = false;
                this.loginErrorText = response.error_Description;
            } else {
                this.loginError = false;
                this.activeModal.close();
                this.loginActive = false;
                this.router.navigate(['/dashboard']);
    
                sessionStorage.setItem('email', this.form.get('email').value);
            }
        } catch (error) {
            if (error.error.error_Description) {
                this.loginErrorText = error.error.error_Description;
            }

            this.loginActive = false;
            this.loginError = true;
        }
    }

    forgotPassword(): void {
        this.activeModal.close();
        const modalRef = this.modal.open(ForgotPasswordComponent, { size: 'md', centered: true });
        modalRef.componentInstance.name = 'ForgotPassword';

    }

    ngOnInit(): void {
        this.form = this.fb.group({
            email: new UntypedFormControl('', [Validators.required]),
            password: new UntypedFormControl('', [Validators.required]),
        });
    }

}
