import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { UserService } from '../../services/user/user-service.service';

@Component({
    selector: 'app-sign-up',
    templateUrl: './sign-up.component.html',
    styleUrls: ['./sign-up.component.scss']
})
export class SignUpComponent implements OnInit {
    @Input() modalName: string;
    
    signUpError: boolean = false;
    signUpErrorComplex: boolean;
    signUpErrorText: string = 'There was a problem with the sign-up request';
    signUpActive: boolean = false;
    accountType: string;
    stepIndex: string|number = 0;

    form = new UntypedFormGroup({});
    validator = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{12,})/;
    nameValidator = /^[a-zA-Z]+$/;

    constructor(
        private fb: UntypedFormBuilder,
        public activeModal: NgbActiveModal,
        private userService: UserService,
    ) { }

    confirmValidator = () => {
        const noMatch: boolean = ( this.form.get('password').value !== this.form.get('confirmPassword').value );
        return noMatch ? { MustMatch: true } : null;
    }

    // convenience getter for easy access to form fields
    get f() { return this.form.controls; }

    getFormValue(field: string): string|number {
        return this.form.get(field).value;
    }

    updateFormField($event: any): void {
        this.form.controls[$event.name] = $event;
        this.form.updateValueAndValidity();
    }

    clearFieldValues() {
        this.form.reset();
    }

    closeAlert(): void {
        this.signUpError = false;
    }

    selectType($event: any) {
        const $target = $event.target;
        const $parent = $target.closest('.left-column');
        const $buttons: HTMLCollectionOf<Element> = $parent.getElementsByTagName('button');

        for ( let i = 0; i < $buttons.length; i++ ) {
            $buttons[i].classList.remove('selected');
        }

        $target.classList.add('selected');
        
        this.accountType = $target.name;
        this.stepIndex = 1;

        this.clearFieldValues();
    }

    parseModelState(modelState: any): void {
        let errorText = '';
        
        for ( const prop in modelState ) {
            let label = '';
            
            if ( prop !== '' ) {
                label = '<b>' + prop.replace('model.', '') + ': </b>';
            }
            errorText += '<div>' + label + modelState[prop] + '</div>';
        }

        this.signUpErrorText = errorText;
    }

    public async onSubmit(): Promise<void> {
        this.signUpActive = true;
        const values = JSON.parse(JSON.stringify(this.form.value));

        const register = {
            linkFastPassRequest: {
                preExistingAccount: (this.accountType === 'kiosk'),
                phoneNumber: values.phone.replace(/-/g, ''),
                fastPassPin: values.pin,
                lastName: values.lastName,
                languageId: 1
            },
            createUserRequest: {
                email: values.email,
                password: values.password,
                confirmPassword: values.confirmPassword
            }
        }
        

        try {
            const response = await this.userService.signUp(register);
            
            this.activeModal.close();
            this.signUpActive = false;

        } catch (error) {
            if ( error.error_description ) {
                this.signUpErrorText = error.error_description;
                this.signUpError = true;
            } else if ( error.ModelState ) {
                this.parseModelState(error.ModelState);
                this.signUpErrorComplex = true;
            } else {
                this.signUpError = true;
                this.signUpErrorText = "Something went wrong. Please try again."
            }

            this.signUpActive = false;
        }
    }

    ngOnInit(): void { 
        this.form = this.fb.group({
            phone: new UntypedFormControl('', [Validators.required]),
            pin: new UntypedFormControl('', [Validators.required]),
            lastName: new UntypedFormControl('', [Validators.required]),
            email: new UntypedFormControl('', [Validators.required]),
            password: new UntypedFormControl('', [Validators.required]),
            confirmPassword: new UntypedFormControl('', [Validators.required]),
        });
    }

}
