import { Component, Input, OnInit, AfterViewInit, OnDestroy, Inject } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { DOCUMENT } from '@angular/common';
import { MessageReporter } from '../../services/messages/message-reporter.service';
import { BillersService } from '../../services/billers/billers.service';
import { IdleTimeoutService } from '../../services/idle-timeout/idle-timeout.service';
import { UserService } from '../../services/user/user-service.service';
import { PaymentTypes } from '../../services/payment-types/payment-types.data';
import { TranslateService } from '@ngx-translate/core';
import { MerchantValidationEvent, PayMethod } from '../../interfaces/pay-method.interface';

import { billerImageUrl, GetTransactionFees } from '../../../config';
import { AppService } from 'src/app/services/app/app.service';
import { PaymentTypesService } from 'src/app/services/payment-types/payment-types.service';
import { GlobalConstants } from 'src/app/common/global-constants';
import { applePayMerchantId } from '../../../config';

class Payment {
    accountNumber: string = '';
    bankAcctLast4: string = '';
    billerId: number = 0;
    ccLast4: number = 0;
    ccExpDateMMYY: string = '';
    cvv: string = '';
    customerFee: number = 0;
    customerZip: number = 0;
    emailAddress: string = '';
    isDebitCard: boolean = false;
    payAmount: number = 0;
    paymentMethodId: number = 0;
    userId: string = '';
    serviceId: number = 0;
    payTypeId: number = 0;
    subPayTypeId: number = 1;
    terminalId: string = GlobalConstants.mpoTerminalId;
    customerLastName: string = '';
    walletData: string;
}

@Component({
    selector: 'app-biller-payment',
    templateUrl: './biller-payment.component.html',
    styleUrls: ['./biller-payment.component.scss']
})
export class BillerPaymentComponent implements OnInit, AfterViewInit, OnDestroy {
    @Input() name: string;
    @Input() parent: any;

    acceptedPaymentTypes: any[] = [];
    accountNumber: any;
    balanceDue: number|string = 0.00;
    balanceTypes: any = {};
    billers: any[];
    billerDetails: any;
    billerFees: any[];
    billerImageUrl: string = billerImageUrl;
    customerFee: any;
    email: string;
    fullTransaction: any;
    gettingBalance: boolean = true;
    gettingBillerDetails: boolean = true;
    payment: Payment = new Payment();
    paymentError: boolean = false;
    paymentErrorText: string = 'There was a problem processing your payment. Please try again, or select a different payment method.'
    paymentId: any;
    paymentMethod: any;
    paymentProcessing: boolean = false;
    paymentTime: any;
    paymentTypesSet: boolean = false;
    acceptCc: boolean = false;
    acceptDc: boolean = false;
    paymentDateFormatted: any;
    referenceNumber: any;
    authCode: any;
    selectedBiller: any;
    selectedPayMethod: any;
    user: any;
    windowTimeout: any;
    paymentAttempted: boolean = false;
    badAmount: boolean = true;
    paymentStep: number = 0;
    maxAmount: string;
    showApplePay: boolean = false;

    form: UntypedFormGroup = new UntypedFormGroup({
        paymentAmount: new UntypedFormControl('', [Validators.required]),
        cvv: new UntypedFormControl(''),
        customerZip: new UntypedFormControl(''),
    });

    constructor(
        private billersService: BillersService,
        private idle: IdleTimeoutService,
        private reporter: MessageReporter,
        private route: ActivatedRoute,
        private router: Router,
        private userService: UserService,
        private appService: AppService,
        private paymentTypesService: PaymentTypesService,
        @Inject(DOCUMENT) private document: Document,
        private translate: TranslateService
    ) { }

    closeAlert(): void {
        this.paymentError = false;
    }

    getFormValue(field: string): string | number {
        return this.form.get(field).value;
    }

    updateFormField($event: any): void {
        this.form.controls[$event.name] = $event;
        this.form.updateValueAndValidity();
    }

    goToDashboard(): void {
        this.router.navigate(['/dashboard']);
    }

    todaysDate() {
        return moment().format('MM/DD/YYYY');
    }

    toCurrency(i: number|string): string {
        return '$' + Number(i).toFixed(2);
    }

    paymentAmount(): string {
        return this.toCurrency(this.form.get('paymentAmount').value);
    }

    customerFeeAmount(): string {
        return this.toCurrency(this.fullTransaction.fee);
    }

    totalPaymentAmount(): string {
        // const customerFee = this.customerFee;
        // const paymentAmount = this.form.get('paymentAmount').value;

        return this.toCurrency(this.fullTransaction.total);
    }
    
    formatPhoneNumber(phoneNumberString: string) {
        var cleaned = ('' + phoneNumberString).replace(/\D/g, '')
        var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
        if (match) {
            var intlCode = (match[1] ? '+1 ' : '')
            return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('')
        }
        return null
    }

    previousStep(): void {
        this.paymentStep--;

        if ( this.paymentStep < 2 ) {
            this.paymentError = false;
            this.paymentAttempted = false;
        }

        if ( this.paymentStep === 0 ) {
            this.selectedPayMethod = undefined;
        }
    }

    nextStep(): void {
        this.paymentStep++;

        if ( this.paymentStep < 2 ) {
            this.paymentError = false;
        }

        if (this.paymentStep === 2)
            setTimeout(() => { (this.document.getElementsByName('submit-payment')[0] as HTMLElement).focus(); }, 100);
    }

    onChange(): void {
        let val = this.form.get('paymentAmount').value;

        this.payment.payAmount = val;
    }

    onBlurred(): void {
        let val = this.form.get('paymentAmount').value;

        if ( val !== '' ) {
            val = Number(val).toFixed(2);
            const billerFee = this.billerFees.filter((row: any) => {
                const payTypeId = this.selectedPayMethod['payTypeId'];
                return (Number(row.paymentTypeId) === Number(payTypeId));
            });

            if (Number(val) <= Number(billerFee[0].maxAmount)) {
                this.badAmount = false;
                this.fullTransaction = GetTransactionFees(billerFee[0], Number(val));
                this.form.get('paymentAmount').setValue(val);
            }
            else
                this.badAmount = true;
        }

        this.payment.payAmount = val;
    }

    selectPayMethod(payMethod: PayMethod): void {
        this.paymentStep = 1;
        this.selectedPayMethod = payMethod;
        if (this.appService.isPayTypeCredit(payMethod.payTypeId)) {
            const cvv = this.form.get('cvv');
            const customerZip = this.form.get('customerZip');

            cvv.setValidators([Validators.required, Validators.pattern(/^\d{3}$/)]);
            cvv.updateValueAndValidity();

            customerZip.setValidators([Validators.required, Validators.pattern(/^\d{5}$/)]);
            customerZip.updateValueAndValidity();
        }
        
        this.getSelectedPaymentFee();
        setTimeout(() => { (this.document.getElementsByName('paymentAmount')[0] as HTMLElement).focus(); }, 100);
    }

    getSelectedPaymentFee(): void {
        const fee: any[] = this.billerFees.filter((fee: any) => {
            return (fee.paymentTypeId === this.selectedPayMethod.payTypeId);
        });

        if ( fee.length > 0 ) {
            this.customerFee = fee[0].billerFee;
            this.maxAmount = Number(fee[0].maxAmount).toFixed(2);
        }
    }

    getSelectedBiller(): void {
        if ( this.billers ) {
            const selected = this.billers.filter((biller) => {
                return ( biller.accountNumber === this.accountNumber);
            });

            if ( selected.length > 1 ) {
                for ( let i = 0; i < selected.length; i++ ) {
                    if ( selected[i]['balanceResponse'] !== null ) {
                        this.selectedBiller = selected[i];
                    }
                }

                if ( !this.selectedBiller ) {
                    this.selectedBiller = selected[0];
                }

                this.getBillersList();
                this.getBalance();

            } else if ( selected.length === 1 ) {

                this.selectedBiller = selected[0];
                this.getBillersList();
                this.getBalance();
            }
        }
    }

    async onSubmit(): Promise<void> {
        const { payTypeId } = this.selectedPayMethod;

        if (this.appService.isPayTypeCheckingSavings(payTypeId))
            await this.makeBankPayment();
        else if (this.appService.isPayTypeCreditDebit(payTypeId))
            await this.makeCCPayment();
        else if (this.appService.isPayTypeWallet(payTypeId))
            await this.makeWalletPayment();
    }

    async makeBankPayment(): Promise<any> {
        const payment = this.collectPaymentData();
        const payAmount = Number(this.form.get('paymentAmount').value);

        delete payment.ccLast4;
        delete payment.ccExpDateMMYY;
        delete payment.cvv;
        delete payment.subPayTypeId;
        delete payment.customerZip;
        delete payment.walletData;

        this.paymentProcessing = true;

        const paymentMethod = PaymentTypes.filter((row: any) => {
            return ((Number(row.id) === payment.payTypeId ));
        });

        this.paymentMethod = paymentMethod[0]['name'] || '';

        try {
            this.paymentAttempted = true;

            const response = await this.billersService.makePaymentWithSavedOption(payment);

            if ( response.paymentSuccessful === true ) {
                this.reporter.showSuccessMessage('A Payment of ' + this.toCurrency(payAmount) + ' to ' + this.billerDetails.billerName + ' was successful.');
            
                this.paymentId = response.paymentId;
                this.referenceNumber = response.referenceNumber;
                this.authCode = response.authCode;
                this.paymentProcessing = false;

                const paymentDate = response.paymentDate;
                const dt = paymentDate.toString().split(' ');

                this.paymentTime = dt[1];
                this.paymentDateFormatted = dt[0];

                this.nextStep();

            }
            else {
                this.paymentProcessing = false;
                this.paymentError = true;
                this.reporter.showErrorMessage('Payment was unsuccessful. Please try again, select a different payment method, or call 877-876-7076.');
            }
        } catch (error) {
            console.error(error);
            this.paymentProcessing = false;
            this.reporter.showErrorMessage('An error has occurred during payment processing. Please try again, select a different payment method, or call 877-876-7076.');
        }
    }

    async makeCCPayment(): Promise<any> {
        const payment = this.collectPaymentData();
        const payAmount = Number(this.form.get('paymentAmount').value);
        
        delete payment.bankAcctLast4;
        delete payment.walletData;
        if (this.appService.isPayTypeDebit(payment.payTypeId)) {
            delete payment.customerZip;
            delete payment.cvv;
        }

        this.paymentProcessing = true;

        const paymentMethod = PaymentTypes.filter((row: any) => {
            return ((Number(row.id) === payment.payTypeId ));
        });

        this.paymentMethod = paymentMethod[0]['name'] || '';

        try {
            this.paymentAttempted = true;

            const response = await this.billersService.makePaymentWithSavedOption(payment);

            if ( response.paymentSuccessful === true ) {
                this.reporter.showSuccessMessage('A Payment of ' + this.toCurrency(payAmount) + ' to ' + this.billerDetails.billerName + ' was successful.');
            
                this.paymentProcessing = false;
                this.paymentId = response.paymentId;
                this.referenceNumber = response.referenceNumber;
                this.authCode = response.authCode;

                const paymentDate = response.paymentDate;
                const dt = paymentDate.toString().split(' ');

                this.paymentTime = dt[1];
                this.paymentDateFormatted = dt[0];

                this.nextStep();

            }
            else {
                this.paymentProcessing = false;
                this.paymentError = true;
                this.reporter.showErrorMessage('Payment was unsuccessful. Please try again, select a different payment method, or call 877-876-7076.');
            }
        } catch (error) {
            console.error(error);
            this.paymentProcessing = false;
            this.reporter.showErrorMessage('An error has occurred during payment processing. Please try again, select a different payment method, or call 877-876-7076.');
        }
    }

    async makeWalletPayment(): Promise<void> {
        var walletMethod = {} as PaymentMethodData;
        var walletMethods = [] as PaymentMethodData[];
        var paymentRequest: PaymentRequest;

        const payment = this.collectPaymentData();

        delete payment.bankAcctLast4;
        delete payment.ccLast4;
        delete payment.ccExpDateMMYY;
        delete payment.cvv;
        delete payment.subPayTypeId;
        delete payment.customerZip;

        this.paymentProcessing = true;
        this.paymentError = false;

        const paymentMethod = this.billerFees.filter((row: any) => {
            return ((row.paymentTypeId === payment.payTypeId ));
        });

        this.paymentMethod = paymentMethod[0].paymentTypeNameInLanguage;

        const paymentDetails = this.buildWalletDetails(payment);

        if (this.appService.isPayTypeApplePay(payment.payTypeId))
            walletMethod = this.buildApplePayMethod();
            
        if (walletMethod.data) {
            try {
                walletMethods.push(walletMethod);
                paymentRequest = new PaymentRequest(walletMethods, paymentDetails);
                paymentRequest.addEventListener('merchantvalidation', async (event) => {
                    const evt = (event as MerchantValidationEvent);
                    const merchantSessionPromise = await this.billersService.verifyMerchant(evt.validationURL, this.selectedBiller.billerName);
                    evt.complete(merchantSessionPromise);
                })
        
                paymentRequest.onpaymentmethodchange = event => {
                    const evt = (event as PaymentMethodChangeEvent);
                    if (evt.methodDetails.type !== undefined) {
                        var paymentDetailsUpdate = {} as PaymentDetailsUpdate;
                        paymentDetailsUpdate.total = paymentDetails.total;
                        evt.updateWith(paymentDetailsUpdate);
                    }
                };
                
                const response = await paymentRequest.show();
                payment.walletData = JSON.stringify(response);
                await this.submitWalletPayment(payment);
                while (this.paymentError) {
                    await response.retry();
                    await this.submitWalletPayment(payment);
                }
                response.complete('success');
            }
            catch (e) {
                console.error(e);
                this.paymentError = true;

                try {
                    if (paymentRequest)
                        paymentRequest.abort();
                }
                catch {}
            }
        }
        else {
            this.paymentError = true;
            this.reporter.showErrorMessage('The selected payment method is currently unavailable. Please select a different payment method, or call 877-876-7076.');
        }

        this.paymentProcessing = false;
    }

    async submitWalletPayment(payment: Payment) {
        try {
            this.paymentError = false;
            const response = await this.billersService.makePayment(payment);

            if ( response.paymentSuccessful ) {
                this.reporter.showSuccessMessage('A Payment of ' + this.toCurrency(payment.payAmount) + ' to ' + this.billerDetails.billerName + ' was successful.');
            
                this.paymentId = response.paymentId;
                this.referenceNumber = response.referenceNumber;
                this.authCode = response.authCode;
                this.paymentProcessing = false;

                const paymentDate = response.paymentDate;
                const dt = paymentDate.toString().split(' ');

                this.paymentTime = dt[1];
                this.paymentDateFormatted = dt[0];

                this.nextStep();
            }
            else {
                this.paymentProcessing = false;
                this.paymentError = true;
            }
        }
        catch (e) {
            console.error(e);
            this.paymentError = true;
            this.paymentProcessing = false;
        }
    }

    buildApplePayMethod() {
        var applePay = {} as PaymentMethodData;
        var supportedNetworks = [];
        var capabilities = [];

        capabilities.push('supports3DS'); //Required
        if (this.acceptCc)
            capabilities.push('supportsCredit');
        if (this.acceptDc)
            capabilities.push('supportsDebit');

        const cardType = this.billerFees.filter((row: any) => {
            return (Number(row.paymentTypeId) == GlobalConstants.payType.Credit);
        });

        if (cardType.length > 0) {
            for (var i = 0; i < cardType[0].creditCardsList.length; i++) {
                switch (cardType[0].creditCardsList[i].creditCardName) {
                    case 'Visa':
                        supportedNetworks.push('visa');
                        break;
                    case 'MasterCard':
                        supportedNetworks.push('masterCard');
                        break;
                    case 'Discover':
                        supportedNetworks.push('discover');
                        break;
                    case 'American Express':
                        supportedNetworks.push('amex');
                        break;
                }
            }
        }
        
        if (supportedNetworks.length > 0 && capabilities.length > 1) {
            applePay.supportedMethods = "https://apple.com/apple-pay";
            applePay.data = {
                version: 12,
                merchantIdentifier: applePayMerchantId,
                merchantCapabilities: capabilities,
                supportedNetworks: supportedNetworks,
                countryCode: 'US',
                requiredBillingContactFields: ["name", "postalAddress"]
            };
        }

        return applePay;
    }

    buildWalletDetails(payment: Payment) {
        var paymentDetails = {} as PaymentDetailsInit;
        var paymentItem = {} as PaymentItem;
        var paymentCurrency = {} as PaymentCurrencyAmount;
        const displayItems = [] as PaymentItem[];

        paymentCurrency.value = this.fullTransaction.total.toFixed(2);
        paymentCurrency.currency = 'USD';

        paymentItem.label = this.selectedBiller.billerName;
        paymentItem.amount = paymentCurrency;

        if ( payment.customerFee > 0.00 ) {
            displayItems.push(this.createWalletDisplayItem(this.translate.instant('biller-payment.amount-to-apply'), payment.payAmount));
            displayItems.push(this.createWalletDisplayItem(this.translate.instant('biller-payment.convenience-fee-wallet'), payment.customerFee));
        }

        paymentDetails.total = paymentItem;
        paymentDetails.displayItems = displayItems;

        return paymentDetails;
    }

    createWalletDisplayItem(label: string, value: number) {
        var displayItem = {} as PaymentItem;
        var amountItem = {} as PaymentCurrencyAmount;

        amountItem.value = value.toFixed(2);
        amountItem.currency = 'USD';
        displayItem.label = label;
        displayItem.amount = amountItem;

        return displayItem;
    }

    collectPaymentData() {
        const payment = this.payment;
        const payAmount = Number(this.form.get('paymentAmount').value);
        const cvv = this.form.get('cvv').value;
        const emailAddress = sessionStorage.getItem('email');
        const customerZip = this.form.get('customerZip').value;

        payment.accountNumber = this.selectedBiller.accountNumber;
        payment.bankAcctLast4 = this.selectedPayMethod.bankAcctLast4;
        payment.ccLast4 = this.selectedPayMethod.ccLast4;
        payment.cvv = cvv;
        payment.ccExpDateMMYY = this.selectedPayMethod.ccExpDateMmyy;
        payment.billerId = this.selectedBiller.billerId;
        payment.emailAddress = emailAddress;
        payment.isDebitCard = false;
        payment.payAmount = payAmount;
        payment.paymentMethodId = this.selectedPayMethod.paymentMethodId;
        payment.userId = this.userService.userId();
        payment.serviceId = this.selectedPayMethod.serviceId;
        payment.payTypeId = this.selectedPayMethod.payTypeId;
        payment.subPayTypeId = this.selectedPayMethod.subPayTypeId;
        payment.customerFee = this.fullTransaction.fee;
        payment.customerZip = customerZip;
        payment.customerLastName = this.user.lastName;

        return payment;
    }

    async getBillerAccounts(): Promise<any> {
        try {
            const response = await this.billersService.getBillerAccounts();
            this.user = response;
            
        } catch (error) {
            this.reporter.showErrorMessage(error);
        }
    }

    async getBalance() {
        const response: any = await this.billersService.getBillerAccount(
            this.selectedBiller.billerId,
            this.selectedBiller.accountNumber
        );

        if ( !response ) {
            this.gettingBillerDetails = false;
            this.gettingBalance = false;
            return;
        }

        if (response.result && response.result.toLowerCase() === 'invalid') {
            this.reporter.showErrorMessage(this.selectedBiller.billerName + ' account ' + this.selectedBiller.accountNumber + ' could not be found. Please contact ' + this.selectedBiller.billerName + ' for further details.');
            this.goToDashboard();
            return;
        }

        this.updateAcceptedPaymentTypes(response);

        const balanceTypes = this.getBalanceTypes(response);
        if ( balanceTypes['BALANCEDUE'] ) {
            let balance = balanceTypes['BALANCEDUE']['balance'];
            this.balanceDue = Number(balance).toFixed(2);
        } else {
            this.balanceDue = '';
        }

        this.gettingBillerDetails = false;
        this.gettingBalance = false;
    }

    async updateAcceptedPaymentTypes(accountLookupResponse: any) {
        this.acceptedPaymentTypes = [];

        if (accountLookupResponse.noPayments) {
            //show error, and nav back to dashboard
            this.reporter.showErrorMessage(this.selectedBiller.billerName + ' currently does not allow any online payments for account ' + this.selectedBiller.accountNumber + '. Please contact ' + this.selectedBiller.billerName + ' for further details.');
            this.goToDashboard();
        }
        else {
            const types: any[] = [];
            this.paymentTypesSet = false;
        
            if (this.billerFees && this.billerFees.length > 0) {
                this.billerFees.map((fee: any) => {
                    types.push(fee.paymentTypeId);
                });   
            }
            
            if ( types && types.length > 0 ) {
                const methods = await this.paymentTypesService.getPaymentTypes();
                var newAcceptedPaymentTypes = types;
                
                this.acceptCc = accountLookupResponse.acceptCc;
                this.acceptDc = accountLookupResponse.acceptDc;
                if (!accountLookupResponse.acceptCc) {
                    //remove CC from available payment types
                    const ccIndex = newAcceptedPaymentTypes.indexOf(5);
                    if (ccIndex !== -1) {
                        newAcceptedPaymentTypes.splice(ccIndex, 1);
                    }
                }
                if (!accountLookupResponse.acceptDc) {
                    //remove DC from available payment types
                    const debitIndex = newAcceptedPaymentTypes.indexOf(6);
                    if (debitIndex !== -1) {
                        newAcceptedPaymentTypes.splice(debitIndex, 1);
                    }
                }
                if (!accountLookupResponse.acceptChecks) {
                    //remove ACH from available payment types
                    const checkingIndex = newAcceptedPaymentTypes.indexOf(3);
                    if (checkingIndex !== -1) {
                        newAcceptedPaymentTypes.splice(checkingIndex, 1);
                    }
    
                    const savingsIndex = newAcceptedPaymentTypes.indexOf(4);
                    if (savingsIndex !== -1) {
                        newAcceptedPaymentTypes.splice(savingsIndex, 1);
                    }
                }
                
                if (window.PaymentRequest && (window as any).ApplePaySession && (window as any).ApplePaySession.canMakePayments()) {
                    const applePay: any = types.filter((pt: any) => {
                        return (pt == GlobalConstants.payType.ApplePay);
                    });

                    this.showApplePay = applePay && applePay.length > 0;
                }

                this.acceptedPaymentTypes = methods.filter((method: any) => {
                    return ( newAcceptedPaymentTypes.includes( method.id ) );
                });

                this.paymentTypesSet = true;
            }

            if (this.acceptedPaymentTypes.length === 0)
                this.reporter.showErrorMessage(this.selectedBiller.billerName + ' currently does not allow any online payments for account ' + this.selectedBiller.accountNumber + '. Please contact ' + this.selectedBiller.billerName + ' for further details.');
        }
    }

    isCreditOrDebitPayment(): boolean {
        const { payTypeId } = this.selectedPayMethod;
        return this.appService.isPayTypeCreditDebit(payTypeId);
    }

    isCreditPayment(): boolean {
        const { payTypeId } = this.selectedPayMethod;
        return this.appService.isPayTypeCredit(payTypeId);
    }

    isBankAccountPayment(): boolean {
        const { payTypeId } = this.selectedPayMethod;
        return this.appService.isPayTypeCheckingSavings(payTypeId);
    }

    getBalanceTypes(response: any): any {

        const { msgList }: any = response;

        if ( !msgList ) {
            return {};
        }

        this.balanceTypes = {};

        for ( let i = 0; i < msgList.length; i++ ) {
            const msg = msgList[i];
            const lstVerbiage: any[] = msg['lstVerbiage'];

            if ( lstVerbiage.length > 0 ) {
            
                for ( let o = 0; o < lstVerbiage.length; o++ ) {
                    this.balanceTypes[lstVerbiage[o]['verbiageName']] = msg;
                }
            }
        }

        return this.balanceTypes;
    }

    async getBillerPaymentFees(): Promise<any> {
        try {
            const billerId = this.selectedBiller.billerId;
            const response = await this.billersService.getBillerInformation(billerId);
            this.billerFees = response;
        } catch (error) {
            this.reporter.showErrorMessage(error);
        }
    }

    async getBillersList(): Promise<any> {
        const billerDetails: any = this.billers.filter((row: any) => {
            return (row.accountNumber == this.accountNumber);
        });
        
        if ( billerDetails.length > 0 ) {
            this.billerDetails = billerDetails[0];
        }
        
        this.getBillerPaymentFees();
    }

    async getMyBillers(): Promise<any> {
        try {

            const data = await this.billersService.getMyBillers(true, false);
            this.billers = data;
            this.getSelectedBiller();

        } catch (error) {
            this.reporter.showErrorMessage(error);
        }
    }

    print($event: any): void {
        const html = document.getElementById('receiptContent').innerHTML;
        document.getElementById('printReceipt').innerHTML = '<div id="receiptForPrint">' + html + '</div>';
        
        window.onafterprint = function() {
            document.getElementById('printReceipt').innerHTML = '';
        }

        window.print();
        
    }

    ngOnDestroy(): void {
        this.idle.destroy();
    }

    ngAfterViewInit(): void {
        this.idle.start();
        this.email = sessionStorage.getItem('email');
    }

    ngOnInit(): void {
        this.accountNumber = this.route.snapshot.paramMap.get('account');
        this.gettingBalance = true;
        this.gettingBillerDetails = true;

        this.getMyBillers();
        this.getBillerAccounts();
    }

}
