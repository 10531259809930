<div class="modal-header">
    <h4 class="modal-title">{{'payment-receipt.payment-receipt' | translate}}</h4>
</div>
<div class="modal-body">
    <div *ngIf="payment">
        <div class="transaction-details">
            <div id="receiptContent">
                <p><b>{{'global.payment-made-to' | translate}}: </b><span>{{payment.billerName}}</span></p>
                <p><b>{{'global.transaction-id' | translate}}: </b><span>{{payment.transactionId}}</span></p>
                <p><b>{{'global.date' | translate}}: </b><span>{{paymentDate(payment.paymentDate)}}</span></p>
                <p><b>{{'global.time' | translate}}: </b><span>{{paymentTime(payment.paymentDate)}}</span></p>
                <p><b>{{'global.service' | translate}}: </b><span>{{payment.serviceName}}</span></p>
                <p><b>{{'global.method' | translate}}: </b><span>{{paymentMethod()}}</span></p>
                <p><b>{{'global.account-number' | translate}}: </b><span>{{payment.accountNumber}}</span></p>
                <p><b>{{'global.payment-amount' | translate}}: </b><span>{{currencyFormat(payment.paymentAmount)}}</span></p>
                <p><b>{{'global.fee' | translate}}: </b><span>{{currencyFormat(payment.customerFee)}}</span></p>
                <p><b>{{'global.total-amount' | translate}}: </b><span>{{getTotalPaid()}}</span></p>
            </div>
        </div>
        <div class="print-icon">
            <button type="button" class="btn btn-default" (click)="print($event)">
                <fa-icon [icon]="['fas', 'print']"></fa-icon> {{'global.print' | translate}}
            </button>
        </div>
    </div>
</div>
<div class="modal-footer">
    <div class="button-row">
        <button 
            type="button" 
            class="btn btn-light" 
            (click)="activeModal.close()"
        >{{'global.close' | translate}}</button>
    </div>
</div>