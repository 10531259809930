<div class="modal-header">
    <h4 class="modal-title">{{'biller-search.search-biller' | translate}}</h4>
</div>
<div class="modal-body">
    <p>{{'biller-search.members-of-us-payments' | translate}}</p>
    <div class="form-group">
        <input
            type="text"
            class="form-control"
            id="billerSearch"
            name="billerSearch"
            placeholder="{{'biller-search.search-by-name' | translate}}"
            ngbAutofocus
            (keyup)="searchBillers($event)"
        />
    </div>
    <app-spinner *ngIf="gettingBillers"></app-spinner>
    <div *ngIf="!gettingBillers && searchTermPresent">
        <ul class="biller-list">
            <li *ngFor="let biller of billers">
                {{biller.billerName}}
            </li>
        </ul>
    </div>
</div>
<div class="modal-footer">
    <div class="button-row">
        <button type="button" class="btn btn-default" (click)="activeModal.close()">{{'global.close' | translate}}</button>
        <button type="button" class="btn btn-primary" (click)="openSignUp()">{{'global.sign-up' | translate}}</button>
    </div>
</div>