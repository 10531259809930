<div ribbonParent *ngIf="ribbons.length">
    <div 
        *ngFor="let ribbon of ribbons" 
        class="ribbon-message {{ribbon.type}}"
        id="ribbon.id"
        [@messages]="ribbon.state"
    >
        {{ribbon.msg}}
        <fa-icon 
            class="ribbon-close" 
            [icon]="faTimesCircle"
            (click)="closeRibbon(ribbon.id)"
        ></fa-icon>
    </div>
</div>