<div class="container">
    <div class="row align-center">
        <div id="paySiteLogo">
            <img src="assets/images/logo-lrg.png" />
        </div>
        <h2>$500 Giveaway</h2>
        <p>We're giving away $500 a month from June to September.</p>
    </div>
    <div class="row pt-3">
      <div class="col-3"></div>
      <div class="col-6">
        <h3 class="align-center">How to Enter:</h3>
        <ol>
            <li>Fill out the form below</li>
            <li>Use PaySite to pay a bill</li>
            <li>Make sure you use the same phone number each time you pay a bill</li>
        </ol>
      </div>
    </div>
    <div class="row pt-3">
      <div class="col align-self-center">
        <iframe class="contestForm" width="100%" height="250" [src]="url"></iframe>
      </div>
    </div>
    <div class="row mt-5">
        <h3 class="align-center"><b>PaySite® Sweepstakes 2022</b></h3>
        <h6 class="align-center"><b>OFFICIAL RULES</b></h6>
        <p>To enter, visit pay.uspayments.com/sweepstakes.  A total of three (3) $500 checks will be given to randomly selected eligible entrants. Only one (1) $500 check will be given away per drawing period per the Giveaway schedule below. Users receive an entry when they enter their name, phone number, and email on the promotion website. Subsequent entries can be attained by using the PaySite Kiosk or pay.uspayments.com to pay a bill. Below is a schedule of the prize giveaways. Alternative methods of entry are described below.</p>
        <b class="align-center">Giveaway schedule:</b>
        <b class="align-center">Drawing 1 period 7/1/2022-7/31/2022: $500 check</b>
        <b class="align-center">Drawing 2 period 8/1/2022-8/31/2022: $500 check</b>
        <b class="align-center">Drawing 3 period 9/1/2022-9/31/2022: $500 check</b>
        <p class="mt-3"><b>NO PURCHASE, TRANSACTION OR PAYMENT OF ANY KIND IS NECESSARY TO ENTER OR WIN.  NO PURCHASE, TRANSACTION OR PAYMENT WILL INCREASE CHANCES OF WINNING.</b></p>
        <p><b>This promotion is intended only for residents of Oklahoma and shall be construed and governed by Oklahoma law and the laws of the United States as may be applicable. Do not enter this giveaway if you are not a resident of Oklahoma at the time of entry.</b></p>
        <h6><b>Who May Enter:</b></h6>
        <p>This promotion is open only to residents of the state of Oklahoma who are at least 18 years of age at the time of entry.  Winners must prove eligibility.</p>
        <h6><b>How To Enter:</b></h6>
        <p>Visit pay.uspayments.com/sweepstakes and enter your name, phone number, and email. Use PaySite to pay any bill for additional entries. Drawings are based on the phone number entered on the Sweepstakes website. Alternatively, eligible entrants may enter the contest by regular mail by printing the words “PaySite 2022 Giveaway” and the entrant’s name, street address, city, state, zip, Email address and phone number on a piece of standard 8.5” x 11” paper and mailing in a #10 envelope via the U.S. Postal Service to U.S. Payments, 7380 S. Olympia Avenue Tulsa, OK 74132. Entries do not carry over from one month to the next. Entrants wishing to be included in each monthly drawing must enter using one of the two entry methods listed above during each monthly drawing period. The odds of winning are dependent on the number of entries.</p>
        <h6><b>Prizes:</b></h6>
        <p>Three (3) $500 checks will be given to winners of the random drawing. Sponsor will draw one winner per month in the month of August, September, and October; after the preceding entry period ends. Only three (3) people total will win the giveaway.</p>
        <h6><b>Notification Of Winners:</b></h6>
        <p>After the drawings, winning eligible entrants will be notified by the Sponsors by email or phone.  The Sponsors are not responsible or liable for any inaccuracy in any information supplied by any entrant. Prizes should be promptly (within two weeks) delivered following notification.  If the Sponsors are unable, using commercially reasonable efforts, to contact any winning entrant, or complete delivery utilizing the entry information provided, the Sponsors will randomly select another, substitute winner.</p>
        <h6><b>List Of Winners:</b></h6>
        <p>The list of winners can be obtained through regular mail by printing the words “PaySite 2022 Giveaway-Winner List” and the requestor’s name, street address, city, state, zip, Email address and phone number on a piece of standard 8.5” x 11” paper and mailing in a #10 envelope via the U.S. Postal Service to U.S. Payments, 7380 S. Olympia Avenue Tulsa, OK 74132. Sponsor will provide a list via regular mail or email within 30 days of receiving the request.</p>
        <h6><b class="align-center">GENERAL CONDITIONS</b></h6>
        <b>(a) THIS PROMOTION IS VOID OUTSIDE OKLAHOMA AND WHERE PROHIBITED OR RESTRICTED BY LAW.</b>
        <p>(b) By submitting an entry, the entrant accepts and agrees to be bound by these Official Rules and the decisions of the Sponsors, whose decisions shall be final.</p>
        <p>(c) Sponsors are not responsible for illegible, damaged, lost, late, or misdirected mail entries, or for incomplete or inadequate information provided in any entry.</p>
        <p>(d) Sponsors, Sponsor affiliates and their respective directors, officers, employees, contractors, agents, and representatives (together the “Sponsor group”) shall not be liable for any injury or damage related to or resulting from the promotion. By entering the drawing, entrants irrevocably release and hold the Sponsor Group harmless from any and all damages and claims of any kind in connection with the promotion or resulting from acceptance, possession or use of any prize.</p>
        <p>(e) By entering the Sweepstakes promotion, entrants irrevocably release the Sponsor Group from any liability whatsoever, and waives any and all causes of action, related to any claims, costs, injuries, losses, or damages of any kind arising out of or in connection with this promotion or acceptance, use of or inability to use, or participation in or inability to participate in the promotion or use of or inability to use any prize (including, without limitation, claims, suits, injuries losses and damages related to personal injuries, death, damage to or destruction of property, rights of publicity or privacy, defamation or portrayal in a false light, whether intentional or unintentional), whether under a theory of contract, tort (including negligence), warranty or other theory.</p>
        <p>(f) Tax liability, if any, is the sole responsibility of the winner. Sponsor will file the appropriate forms with the appropriate tax authorities, and the winner must certify that he or she is not subject to back-up withholding, or Sponsor will follow the rules requiring back-up withholding.</p>
        <p>(g) Prizes may not be substituted, transferred, or exchanged.</p>
        <p>(h) Sponsors are not liable for unsuccessful efforts to notify a winner.</p>
        <p>(i) By registering to enter the drawing, entrants affirm that they have read and accepted these Official Rules. The laws of the State of Oklahoma apply to and govern the promotion and any claims must be raised and resolved in the State of Oklahoma.</p>
        <b class="align-center">NO PURCHASE NECESSARY. Open only to residents of the state of Oklahoma who are 18 years of age or older at time of entry. Void outside Oklahoma and where prohibited by law. Sweepstakes entry periods start July 1st, 2022 and end September 30th, 2022.</b>
    </div>
</div>