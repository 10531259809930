<app-navbar title="Kiosk Locator" [ngClass]="{'mobile': isMobile}"></app-navbar>
<form [formGroup]="form" >
    <div class="row h-100">
        <div class="col-lg-3 col-md-12 left-column">
            <div class="row zip-and-radius">
                <div class="d-none d-sm-block d-lg-none col-sm-2"></div>
                <div class="pe-0 col-sm-4 col-lg-7 zipDiv">
                    <text-input 
                        name="zip" 
                        faIcon="map-marker-alt"
                        class="text-black"
                        [label]="'Postal Code'"
                        [value]="getFormValue('zip')"
                        (changeEvent)="updateFormField($event)"
                    ></text-input>
                </div>
                <div class="col-sm-4 col-lg-5 radiusDiv">
                    <span class="textLabel">Radius</span>
                    <select formControlName="radius" class="form-control text-black" placeholder="Radius" (change)="updateFormField($event)">
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                    </select>
                </div>
            </div>
            <div class="locations-list row">
                <div class="location-details" *ngFor="let marker of markers">
                    <h5>{{marker.locationName}}</h5>
                    <div class="location-info">
                        <address>
                            {{marker.address1}}<br />
                            {{marker.city}}, {{marker.state}} {{marker.zip}}
                        </address>
                        <p><strong>Number of Kiosks: </strong>{{marker.numberOfKiosks}}</p>
                        <p><strong>Distance: </strong>{{distanceTo(marker)}} miles</p>
                        <p>
                            <button type="button" class="btn-sm btn-secondary d-none d-sm-block d-lg-none" (click)="getDirections(marker)">Get Directions</button>
                            <button type="button" class="btn btn-secondary d-lg-block d-sm-none" (click)="getDirections(marker)">Get Directions</button>
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-9 col-md-12 h-100">
            <div class="maps-wrapper" *ngIf="lat && lng">
                <google-map
                    *ngIf="this.appService.getWindowSize() === 'xs' || this.appService.getWindowSize() === 'small'"
                    id="mapSmall"
                    class="text-black"
                    height="66vh"
                    width="100%"
                    [center]="currentPos"
                    [options]="options">
                    <map-circle
                        [center]="currentPos"
                        [radius]="getRadius()"
                        [options]="circleOptions"></map-circle>
                    <map-marker
                        #markerElem="mapMarker"
                        *ngFor="let marker of markers"
                        [position]="marker.position"
                        [title]="marker.title"
                        [options]="marker.options"
                        (mapClick)="openInfo(markerElem, infoWindow)">
                        <map-info-window #infoWindow="mapInfoWindow">
                            <h4>{{marker.locationName}}</h4>
                            <div class="marker-content">
                                <p>
                                    {{marker.address1}}<br />
                                    {{marker.city}}, {{marker.state}} {{marker.zip}}
                                </p>
                                <p><strong>{{'kiosk-locator.number-of-kiosks' | translate}} </strong>{{marker.numberOfKiosks}}</p>
                                <p><strong>{{'kiosk-locator.distance' | translate}} </strong>{{distanceTo(marker)}} {{'kiosk-locator.miles' | translate}}</p>
                                <p><button type="button" class="btn btn-secondary" (click)="getDirections(marker)">{{'kiosk-locator.get-directions' | translate}}</button></p>
                            </div>
                        </map-info-window>
                    </map-marker>
                </google-map>
                <google-map
                    *ngIf="this.appService.getWindowSize() !== 'xs' && this.appService.getWindowSize() !== 'small'"
                    id="mapLarge"
                    class="text-black"
                    height="75vh"
                    width="100%"
                    [center]="currentPos"
                    [options]="options">
                    <map-circle
                        [center]="currentPos"
                        [radius]="getRadius()"
                        [options]="circleOptions"></map-circle>
                    <map-marker
                        #markerElem="mapMarker"
                        *ngFor="let marker of markers"
                        [position]="marker.position"
                        [title]="marker.title"
                        [options]="marker.options"
                        (mapClick)="openInfo(markerElem, infoWindow)">
                        <map-info-window #infoWindow="mapInfoWindow">
                            <h4>{{marker.locationName}}</h4>
                            <div class="marker-content">
                                <p>
                                    {{marker.address1}}<br />
                                    {{marker.city}}, {{marker.state}} {{marker.zip}}
                                </p>
                                <p><strong>{{'kiosk-locator.number-of-kiosks' | translate}} </strong>{{marker.numberOfKiosks}}</p>
                                <p><strong>{{'kiosk-locator.distance' | translate}} </strong>{{distanceTo(marker)}} {{'kiosk-locator.miles' | translate}}</p>
                                <p><button type="button" class="btn btn-secondary" (click)="getDirections(marker)">{{'kiosk-locator.get-directions' | translate}}</button></p>
                            </div>
                        </map-info-window>
                    </map-marker>
                </google-map>
            </div>
        </div>
    </div>
</form>