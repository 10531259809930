import { Injectable, Output, EventEmitter } from '@angular/core';
import * as uuid from 'uuid';

interface MessageInterface {
    closable?: boolean;
    id?: string;
    msg: string;
    state: string;
    timeout?: number;
    type?: string;
}

@Injectable({
    providedIn: 'root'
})
export class MessageReporter {
    @Output() messagesUpdated = new EventEmitter<any>();

    messages: { [key: string]: any } = {};
    timeoutsList: { [key: string]: any } = {};

    constructor() { }

    public showSuccessMessage(message: string, timeout = 5000) {
        if (message) {
            const id = uuid.v4();
            this.messages[id] = {
                id: id,
                msg: message,
                state: 'in',
                timeout: timeout,
                type: 'success',
            };
            
            this.messagesUpdated.emit(this.messages);

            this.timeoutsList[id] = window.setTimeout(() => {
                this.messages[id].state = 'out';

                window.setTimeout(() => {
                    delete this.messages[id];
                    delete this.timeoutsList[id];

                    this.messagesUpdated.emit(this.messages);
                }, 250);

            }, timeout);
        }
    }

    public showInfoMessage(message: string, timeout = 5000) {
        if (message) {
            const id = uuid.v4();
            this.messages[id] = {
                id: id,
                msg: message,
                state: 'in',
                timeout: timeout,
                type: 'info',
            };

            this.messagesUpdated.emit(this.messages);

            this.timeoutsList[id] = window.setTimeout(() => {
                this.messages[id].state = 'out';

                window.setTimeout(() => {
                    delete this.messages[id];
                    delete this.timeoutsList[id];

                    this.messagesUpdated.emit(this.messages);
                }, 250);

            }, timeout);
        }
    }

    public showWarnMessage(message: string) {
        if (message) {
            const id = uuid.v4();
            this.messages[id] = {
                id: id,
                msg: message,
                state: 'in',
                type: 'warn',
            };
            
            this.messagesUpdated.emit(this.messages);
        }
    }

    public showErrorMessage(message: string) {
        if (message) {
            const id = uuid.v4();
            this.messages[id] = {
                id: id,
                msg: message,
                state: 'in',
                type: 'danger',
            };

            this.messagesUpdated.emit(this.messages);

            return this.messages[id];
        }
    }

    public removeMessage(message: string) {
        this.messages[message].state = 'out';

        window.clearTimeout(this.timeoutsList[message]);
        delete this.timeoutsList[message];
        
        window.setTimeout(() => {
            delete this.messages[message];
            this.messagesUpdated.emit(this.messages);
        }, 250);

    }
}
