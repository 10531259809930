<div class="modal-header">
    <h4 class="modal-title">{{'delete-pay-method.delete-payment-type' | translate}}</h4>
</div>
<div class="modal-body">
    <ngb-alert 
        type="danger" 
        *ngIf="hasError" 
        (closed)="closeAlert()"
    >
        <div [innerHtml]="errorText"></div>
    </ngb-alert>
    <p>{{'delete-pay-method.are-you-sure' | translate}} <strong>{{'delete-pay-method.delete' | translate}}</strong> {{'delete-pay-method.this-pay-method' | translate}}?</p>
</div>
<div class="modal-footer">
    <div class="button-row">
        <button class="btn btn-normal" (click)="activeModal.dismiss('canceled')">{{'global.cancel' | translate}}</button>
        <button class="btn btn-danger" (click)="deletePayMethod()">{{'delete-pay-method.agree-delete-pay-method' | translate}}</button>
    </div>
</div>