<h2 class="thin">{{'personal-info.personal-info' | translate}}</h2>
<form>
    <div class="row">
        <div class="col-12">
            <div class="form-group">
                <text-input
                    faIcon="user"
                    name="firstName"
                    [label]="'global.first-name' | translate"
                    [value]="getFormValue('firstName')"
                    (changeEvent)="updateFormField($event)"
                    ngbAutofocus
                ></text-input>
            </div>
            <div class="form-group">
                <text-input
                    faIcon="user"
                    name="lastName"
                    required="true"
                    [label]="'global.last-name' | translate"
                    [value]="getFormValue('lastName')"
                    (changeEvent)="updateFormField($event)"
                ></text-input>
            </div>
            <div class="form-group">
                <email-input
                    [disabled]="true"
                    name="email"
                    required="true"
                    [label]="'global.email' | translate"
                    [value]="getFormValue('email')"
                    (changeEvent)="updateFormField($event)"
                ></email-input>
            </div>
            <div class="form-group">
                <phone-input
                    name="phoneNumber"
                    required="true"
                    [label]="'global.phone-number' | translate"
                    [value]="getFormValue('phoneNumber')"
                    (changeEvent)="updateFormField($event)"
                ></phone-input>
            </div>
            <div class="form-group">
                <int-input 
                    faIcon="map-marker-alt"
                    name="zip"
                    required="true"
                    [maxLength]="5"
                    [label]="'global.postal-code' | translate"
                    [value]="getFormValue('zip')"
                    (changeEvent)="updateFormField($event)"
                ></int-input>
            </div>
        </div>
        <div class="d-sm-block d-md-none col-12 requiredLabel">
            <div class="notice">* {{'global.required' | translate}}</div>
        </div>
    </div>
    <div class="button-row">
        <div class="row">
            <div class="d-none d-md-block col-6 align-left align-self-center">
                <div class="notice">* {{'global.required' | translate}}</div>
            </div>
            <div class="d-none d-md-block col-6 align-right">
                <button class="btn btn-primary" (click)="savePersonalInfo()">{{'personal-info.save-profile-changes' | translate}}</button>
            </div>
            <div class="d-sm-block d-md-none col-12 align-enter">
                <button class="btn btn-primary btn-block" (click)="savePersonalInfo()">{{'personal-info.save-profile-changes' | translate}}</button>
            </div>
        </div>
    </div>
</form>