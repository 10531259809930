import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { MessageReporter } from '../../services/messages/message-reporter.service';
// import { StatesService } from '../../services/states/states.service';
import { UserService } from '../../services/user/user-service.service';

import { BillersService } from '../../services/billers/billers.service';
import { BillerInterface } from '../../interfaces/biller.interface';

@Component({
    selector: 'app-biller-add',
    templateUrl: './biller-add.component.html',
    styleUrls: ['./biller-add.component.scss']
})
export class BillerAddComponent implements OnInit {
    @Input() name: string;
    @Input() parent: any;

    addingBiller: boolean = false;
    billers: BillerInterface[];
    states: any[] = [];
    billerError: boolean = false;
    billerErrorText: string = '';

    form: UntypedFormGroup = new UntypedFormGroup({
        accountNumber: new UntypedFormControl('', [Validators.required]),
        billerId: new UntypedFormControl('', [Validators.required]),
    });

    constructor(
        public activeModal: NgbActiveModal,
        private billersService: BillersService,
        private reporter: MessageReporter,
        private translate: TranslateService,
        // private statesService: StatesService,
        private userService: UserService,
    ) { }

    getFormValue(field: string): string|number {
        return this.form.get(field).value;
    }

    updateFormField($event: any): void {
        this.form.controls[$event.name] = $event;
        this.form.updateValueAndValidity();
    }

    closeAlert(): void {
        this.billerError = false;
    }

    // async getStates(): Promise<any> {
    //     try {
    //         const response = await this.statesService.getStates();
    //         this.states = response;
    //     } catch( error ) {
    //         this.reporter.showErrorMessage(error;
    //     }
    // }

    async getBillers(): Promise<any> {
        try {
            const data = await this.billersService.getBillers('1,3,7');
            this.billers = data;
        } catch (error) {
            console.log(error);
            this.billers = [];
        }
    }

    async addBillerAccount(): Promise<any> {
        try {
            this.addingBiller = true;

            const body = this.form.value;
            body.userId = this.userService.userId();
            body.billerId = Number(body.billerId);
            body.accountNumber = body.accountNumber.replace(/-/g, '').replace(' ', '');

            const account = await this.billersService.getBillerAccount(body.billerId, body.accountNumber);

            if (account.result && account.result.toLowerCase() === 'invalid') {
                this.billerError = true;
                this.addingBiller = false;
                this.billerErrorText = this.translate.instant('biller-add.account-not-found');
            } else {
                body.accountNumber = account.strAccount;
                
                const response = await this.billersService.addNewBillerAccount(body);
                
                this.addingBiller = false;
                this.billerError = false;
                this.reporter.showSuccessMessage('New Biller has been added successfully');
                this.parent.getMyBillers();
                this.activeModal.close();

            }

        } catch (error) {
            this.addingBiller = false;
            this.reporter.showErrorMessage(error);
        }
    }

    ngOnInit(): void {
        this.getBillers();
    }

}
