import { NgModule } from '@angular/core';
import { CanActivate, Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './pages/home/home.component'
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { OneTimePaymentComponent } from './pages/one-time-payment/one-time-payment.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { StyleGuideComponent } from './pages/styleguide/styleguide.component';
import { BillerPaymentComponent } from './pages/biller-payment/biller-payment.component';
import { FAQSComponent } from './pages/faqs/faqs.component';
import { ContactUsComponent } from './pages/contact-us/contact-us.component';
import { TermsConditionsComponent } from './pages/terms-conditions/terms-conditions.component';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';
import { PasswordResetComponent } from './pages/password-reset/password-reset.component';
import { ResetLinkComponent } from './pages/reset-link/reset-link.component';
import { KioskLocatorComponent } from './pages/kiosk-locator/kiosk-locator.component';
import { UpgradeLegacyAccountComponent } from './pages/upgrade-legacy-account/upgrade-legacy-account.component';
import { ContestComponent } from './pages/contest/contest.component';
import { SpanishComponent } from './pages/contest/spanish/spanish.component';
import { PINResetRequestComponent } from './pages/pin-reset/pin-reset-request.component';
import { PINResetComponent } from './pages/pin-reset/pin-reset.component';

class AuthGuardService implements CanActivate {
  userLoggedIn = false;
  canActivate() {
      if (this.userLoggedIn) {
        return true;
      } else {
        return false;
      }
    }
  }

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'sweepstakes', component: ContestComponent },
  { path: 'tulsa', component: SpanishComponent },
  { path: 'login', component: HomeComponent },
  { path: 'dashboard', component: DashboardComponent },
  { path: 'onetimepayment' , component: OneTimePaymentComponent },
  { path: 'profile' , component: ProfileComponent },
  { path: 'signup', component: HomeComponent },
  { path: 'styleguide', component: StyleGuideComponent }, // canActivate: [AuthGuardService] }
  { path: 'biller-payment', component: BillerPaymentComponent },
  { path: 'faqs', component: FAQSComponent },
  { path: 'faq', component: FAQSComponent },
  { path: 'contact-us', component: ContactUsComponent },
  { path: 'contactus', component: ContactUsComponent },
  { path: 'termsconditions', component: TermsConditionsComponent },
  { path: 'privacypolicy', component: PrivacyPolicyComponent },
  { path: 'passwordreset', component: PasswordResetComponent },
  { path: 'password-reset', component: PasswordResetComponent },
  { path: 'resetpassword', component: PasswordResetComponent },
  { path: 'reset-password', component: PasswordResetComponent },
  { path: 'reset-link', component: ResetLinkComponent },
  { path: 'kiosk', component: KioskLocatorComponent },
  { path: 'kiosks', component: KioskLocatorComponent },
  { path: 'kiosk-locator', component: KioskLocatorComponent },
  { path: 'upgrade', component: UpgradeLegacyAccountComponent },
  { path: 'pin-reset-request', component: PINResetRequestComponent },
  { path: 'pin-reset', component: PINResetComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
