import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  fullYear: string|number;
  currentUrl: string;

  constructor(
    private router: Router,
  ) {
    this.currentUrl = this.router.url;
    this.router.events.subscribe((val: any) => {
      if ( val.url ) {
        this.currentUrl = val.url;
      }
    });
  }

  ngOnInit(): void {
    const d = new Date();
    this.fullYear = d.getFullYear();
  }

}
