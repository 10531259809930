import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { MessageReporter } from '../../services/messages/message-reporter.service';
import { UserService } from '../../services/user/user-service.service';

@Component({
    selector: 'app-pin-reset-request',
    templateUrl: './pin-reset-request.component.html',
    styleUrls: ['./pin-reset-request.component.scss']
})
export class PINResetRequestComponent implements OnInit {
    form: UntypedFormGroup;
    running: boolean = false;

    constructor(
        private fb: UntypedFormBuilder,
        private userService: UserService,
        private reporter: MessageReporter,
        private route: ActivatedRoute,
        private router: Router,
        @Inject(DOCUMENT) private document: Document,
        private translate: TranslateService
    ) { }

    getFormValue(field: string): string|number {
        return this.form.get(field).value;
    }

    updateFormField($event: any): void {
        this.form.controls[$event.name] = $event;
        this.form.updateValueAndValidity();
    }

    goHome(): void {
        this.router.navigate(['/']);
    }

    public async onSubmit(): Promise<any> {
        if (this.running) return;

        if (this.form.invalid) {
            setTimeout(() => { (this.document.getElementsByClassName("is-invalid")[0] as HTMLElement).focus(); }, 100);
            return;
        }

        try {
            this.running = true;
            const body = this.form.value;
            const response = await this.userService.submitRequestPINReset(body);

            this.reporter.showInfoMessage(this.translate.instant("pin-reset.code-sent"));

            if ( response && response !== null && response.id !== null)
                this.router.navigate(['/pin-reset'], {fragment: response.id});
            else
                this.router.navigate(['/pin-reset']);

            return;
        } catch (error) {
            console.log(error);
        }

        this.reporter.showErrorMessage(this.translate.instant("pin-reset.request-failed"));
        this.running = false;
    }

    ngOnInit(): void {
        this.form = this.fb.group({
            lastName: new UntypedFormControl('', [Validators.required]),
            phoneNumber: new UntypedFormControl('', [Validators.required]),
            accountLastFour: new UntypedFormControl('', [Validators.required]),
        });
    }

    checkKey($event: any) {
        if ($event.key == "Enter") return true;
        return /\d/.test($event.key);
    }
}