import { Component, Input, OnInit, HostListener } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { UserService } from '../../services/user/user-service.service';

import { BarcodeComponent } from '../barcode/barcode.component';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
    @Input() title: string = '';

    innerWidth: any;
    windowSize: string;

    isNavbarCollapsed: boolean = false;
    isLoggedIn: boolean = false;

    constructor(
        private modal: NgbModal,
        private userService: UserService,
    ) { }

    logout() {
        this.userService.logout();
    }

    hideShowMenu() {
        const menu: any = document.getElementById('navLinks-menu');

        if (menu.classList.contains('active')) {
            menu.classList.remove('active');
        } else {
            menu.classList.add('active');
        }
    }

    openBarcode() {
        const modalRef = this.modal.open(BarcodeComponent, { size: 'lg', centered: true });
        modalRef.componentInstance.name = 'Barcode';

        // modalRef.result.then(
        //     () => this.openSignIn(true),
        //     (error) => { return; }
        // );
    }

    ngOnInit(): void {
        this.isLoggedIn = sessionStorage.getItem('userId') != null;
    }

}
