import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { BillerSearchComponent } from '../biller-search/biller-search.component';

import { SignUpComponent } from '../sign-up/sign-up.component';
import { SignInComponent } from '../sign-in/sign-in.component';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { AppService } from 'src/app/services/app/app.service';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
    model: any;

    form: UntypedFormGroup = new UntypedFormGroup({
        zip: new UntypedFormControl(''),
    });

    constructor(
        private modal: NgbModal,
        private router: Router,
        public appService: AppService,
    ) {}

    openSignUp() {
        const modalRef = this.modal.open(SignUpComponent, { size: 'lg', centered: true });
        modalRef.componentInstance.name = 'SignUp';

        modalRef.result.then(
            () => this.openSignIn(true),
            (error) => { return; }
        );
    }

    public openSignIn(fromSignUp: boolean = false): void {
        const modalRef = this.modal.open(SignInComponent, { size: 'md', centered: true });
        modalRef.componentInstance.fromSignUp = fromSignUp;
        modalRef.componentInstance.name = 'SignIn';
    }

    openBillerList(): void {
        const modalRef = this.modal.open(BillerSearchComponent, { size: 'md', centered: true, scrollable: true });
        modalRef.componentInstance.name = 'BillerSearch';
        modalRef.componentInstance.parent = this;
    }

    locateKiosks(): void {
        const zip = this.form.get('zip').value;

        this.router.navigate(['/kiosk'], { queryParams: { zip: zip } });
    }

    ngOnInit(): void {}

    ngAfterViewInit(): void {
        if ( this.router.url === '/signup') {
            this.openSignUp();
        } else if ( this.router.url === '/login' ) {
            this.openSignIn();
        }
    }

}
