<table class="table table-striped">
    <thead>
        <tr>
            <th scope="col">#</th>
            <th scope="col">Country</th>
            <th scope="col">Area</th>
            <th scope="col">Population</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let country of countries; index as i">
            <th scope="row">{{ i + 1 }}</th>
            <td>
                <img [src]="'https://upload.wikimedia.org/wikipedia/commons/' + country.flag" class="mr-2 country">
                {{ country.name }}
            </td>
            <td>{{ country.area | number }}</td>
            <td>{{ country.population | number }}</td>
        </tr>
    </tbody>
</table>